import React from 'react';
const SvgList = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M4.125 2.25c.623 0 1.125.504 1.125 1.125v2.25c0 .623-.502 1.125-1.125 1.125h-2.25A1.124 1.124 0 0 1 .75 5.625v-2.25c0-.621.504-1.125 1.125-1.125h2.25ZM22.5 3a1.5 1.5 0 1 1 0 3H9a1.5 1.5 0 1 1 0-3h13.5Zm0 7.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h13.5Zm0 7.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H9c-.83 0-1.5-.67-1.5-1.5S8.17 18 9 18h13.5ZM.75 10.875c0-.623.504-1.125 1.125-1.125h2.25c.623 0 1.125.502 1.125 1.125v2.25c0 .623-.502 1.125-1.125 1.125h-2.25A1.124 1.124 0 0 1 .75 13.125v-2.25Zm3.375 6.375c.623 0 1.125.502 1.125 1.125v2.25c0 .623-.502 1.125-1.125 1.125h-2.25A1.124 1.124 0 0 1 .75 20.625v-2.25c0-.623.504-1.125 1.125-1.125h2.25Z"
    />
  </svg>
);
export default SvgList;
