import './Box.scss';

import React, { forwardRef } from 'react';
import withCx from 'fe-core/util/withCx';

import { getProps } from './helpers';
import {
  BOX_ALLOWED_COMPONENTS,
  BoxComponent,
  FlexProps,
  Props,
} from './interface';

const Box = forwardRef(
  <T extends React.ElementType = 'div'>(
    // Destructuring cxEl here to fix: "React does not recognize the cxEl prop on a DOM Element"
    { component, dataTestId, children, cx, cxEl, ...props }: Props<unknown, T>,
    ref: React.ComponentPropsWithRef<T>['ref']
  ) => {
    const Component = BOX_ALLOWED_COMPONENTS.includes(component as BoxComponent)
      ? (component as T)
      : 'div';

    return (
      <Component
        ref={ref}
        data-testid={dataTestId}
        {...getProps(props as unknown as FlexProps, cx)}
      >
        {children}
      </Component>
    );
  }
);

export default withCx<Props>('FDBBox')(Box);
