import './Text.scss';

import React, { Component } from 'react';
import { kebabCase, keys, mapKeys, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import { colors } from 'theme/constants';

import cxHelpers from 'util/className';
import { toI18n } from 'util/i18n';
import { omit, pick } from 'util/objectMethods';

const COLOR_NAMES = keys(colors);
const COLOR_PROP_TYPES = mapValues(COLOR_NAMES, () => PropTypes.bool);

export const FONT_WEIGHT_PROP_TYPES = {
  fw300: PropTypes.bool,
  fw400: PropTypes.bool,
  fw600: PropTypes.bool,
  fw700: PropTypes.bool,
  fw800: PropTypes.bool,
};

export const FONT_SIZE_PROP_TYPES = {
  fs10: PropTypes.bool,
  fs12: PropTypes.bool,
  fs14: PropTypes.bool,
  fs16: PropTypes.bool,
  fs18: PropTypes.bool,
  fs24: PropTypes.bool,
  fs30: PropTypes.bool,
  fs32: PropTypes.bool,
  fs36: PropTypes.bool,
  fs40: PropTypes.bool,
  fs50: PropTypes.bool,
};

export const OTHER_MODIFIER_PROP_TYPES = {
  // font-family
  mono: PropTypes.bool,
  gotham: PropTypes.bool,

  // font-style:
  italic: PropTypes.bool,

  // text-decoration
  underline: PropTypes.bool,
  underlineHover: PropTypes.bool,
  strikethrough: PropTypes.bool,

  // text-transform
  capitalize: PropTypes.bool,
  uppercase: PropTypes.bool,
  capitalizeFirstLetter: PropTypes.bool,

  // other
  errorLink: PropTypes.bool,

  lh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const MODIFIER_PROP_TYPES = {
  ...FONT_WEIGHT_PROP_TYPES,
  ...FONT_SIZE_PROP_TYPES,
  ...OTHER_MODIFIER_PROP_TYPES,
};

const MODIFIER_PROP_KEYS = keys(MODIFIER_PROP_TYPES);

const EXPLICIT_PROP_KEYS = MODIFIER_PROP_KEYS.concat(COLOR_NAMES);

export const OTHER_PROP_TYPES = {
  text: PropTypes.string,
  i18n: PropTypes.string,
  i18nProps: PropTypes.object,
  children: PropTypes.node,
  asHTML: PropTypes.bool,
  tag: PropTypes.any,
  // Letter-Spacing
  lspacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Also support dynamically setting color
  color: PropTypes.string,
  // preserve whitespace
  withWhitespace: PropTypes.bool,
  whiteSpace: PropTypes.string,
  breakWord: PropTypes.bool,
  // pseudo elements
  colon: PropTypes.bool,
  fallback: PropTypes.string,
  preWrap: PropTypes.bool,
  fs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

@cxHelpers('Text')
export default class Text extends Component {
  static propTypes = {
    ...OTHER_PROP_TYPES,
    ...MODIFIER_PROP_TYPES,
    ...COLOR_PROP_TYPES,
  };
  // Adds any props it doesn't know as css classes

  static defaultProps = {
    tag: 'span',
    lh: 1.1,
  };

  render() {
    const {
      children,
      text,
      i18n,
      i18nProps,
      asHTML,
      tag,
      color,
      lspacing,
      style,
      withWhitespace,
      breakWord,
      lh,
      mr,
      colon,
      fallback,
      preWrap,
      fs,
      whiteSpace,
      ariaHidden,
      ...props
    } = this.props;

    const modifierProps = pick(props, MODIFIER_PROP_KEYS);
    const colorProps = mapKeys(pick(props, COLOR_NAMES), (_, key) =>
      kebabCase(key)
    );
    const customColor = color && !COLOR_NAMES.includes(color);

    const modifiers = {
      ...modifierProps,
      ...colorProps,
      [kebabCase(color)]: !customColor,
      'with-whitespace': withWhitespace,
      'break-word': breakWord,
      colon,
    };

    const implicitPropsAsClasses = keys(omit(props, EXPLICIT_PROP_KEYS)).join(
      ' '
    );
    const content =
      (i18n && toI18n(i18n, { props: i18nProps || {}, fallback })) ||
      text ||
      children;

    const finalProps = {
      className: this.cx(modifiers, implicitPropsAsClasses),
    };

    if (asHTML) {
      finalProps.dangerouslySetInnerHTML = { __html: content };
    } else {
      finalProps.children = content;
    }

    finalProps.style = style || {};
    if (lspacing) {
      finalProps.style.letterSpacing = lspacing;
    }
    if (customColor) {
      finalProps.style.color = color;
    }
    if (lh) {
      finalProps.style = { ...finalProps.style, lineHeight: lh };
    }
    if (mr) {
      finalProps.style = { ...finalProps.style, marginRight: mr };
    }
    if (preWrap) {
      finalProps.style.whiteSpace = 'pre-wrap';
    }

    if (fs) {
      finalProps.style.fontSize = fs;
    }

    if (whiteSpace) {
      finalProps.style.whiteSpace = whiteSpace;
    }

    if (ariaHidden) {
      finalProps['aria-hidden'] = true;
    }

    return React.createElement(tag, finalProps);
  }
}

export const HeroTitle = props => <Text navyLight fw600 {...props} />;
export const HeroSectionTitle = props => (
  <Text navyLight fw600 fs30 {...props} />
);
export const HeroDescription = props => <Text navyLight fs18 {...props} />;
export const PageTitle = props => <Text navy fs24 fw700 {...props} />;
export const BigPageTitle = props => <Text navy fs36 fw600 {...props} />;
export const PageSubTitle = props => <Text grayXdark fs18 {...props} />;
export const SectionTitle = props => (
  <Text navy fs18 fw700 lh={1.2} {...props} />
);
export const TeamSectionTitle = props => <Text navy fs16 fw600 {...props} />;
export const CapsText = props => (
  <Text uppercase fw700 lspacing={1.2} {...props} />
);
export const CapsLabel = props => <CapsText fs12 grayDark {...props} />;
export const Error = props => <Text fs12 red {...props} />;
export const Warning = props => <Text orangeDark {...props} />;
export const Label = props => <Text tag="label" fs14 {...props} />;
export const Detail = props => <Text grayDark fs14 {...props} />;
export const BigDetail = props => <Text grayDark fs14 {...props} />;
export const P = props => <Text tag="p" {...props} />;
export const H1 = props => <Text tag="h1" {...props} />;
export const H2 = props => <Text tag="h2" {...props} />;
export const H3 = props => <Text tag="h3" {...props} />;
export const H4 = props => <Text tag="h4" {...props} />;
export const H5 = props => <Text tag="h5" {...props} />;
export const H6 = props => <Text tag="h6" {...props} />;
export const Paragraph = P;
export const Header1 = H1;
export const Header2 = H2;
export const Header3 = H3;
export const Header4 = H4;
export const Header5 = H5;
export const Header6 = H6;
