import { combineReducers } from '@reduxjs/toolkit';

import { reducer as downgradeWorkflowData } from './DowngradeBottomDrawer/slice';
import { reducer as nextIntegration } from './NextIntegrationDialog/slice';
import stripeCheckoutDrawer from './StripeCheckoutDrawer/slice';

export const reducer = combineReducers({
  downgradeWorkflowData,
  nextIntegration,
  stripeCheckoutDrawer,
});
