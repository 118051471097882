import React, { memo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import HoorayGif from 'assets/hooray.gif';
import Box from 'fe-design-base/atoms/Box';
import Dialog from 'fe-design-base/organisms/Dialog';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';

import { showAddEmployeeModal } from 'actions/addTeam';

import {
  selectSuccessModalIsOpen,
  selectSuccessModalProps,
} from 'features/team/selectors';
import { closeSuccessModal, openTaxEntryDrawer } from 'features/team/slice';
import {
  BUTTON_TEXT,
  ELEMENT,
  EVENT_ACTIONS,
} from 'features/teamView/tracking';

import Image from 'components/Image';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import {
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUx, withUxRoot } from 'util/uxEvents';

import ActionButtons from './components/ActionButtons';
import Message from './components/Message';
import UserInfo from './components/UserInfo';
import { I18N_PATH } from './constants';

const { cx } = cxHelpers('AddEmployeeSuccessModal');

export const AddEmployeeSuccessModal = ({
  isOpen,
  onShowAddEmployeeModal,
  onClose,
  onOpenTaxEntryDrawer,
  modalProps: {
    firstName,
    title,
    userId,
    messageKey,
    isPayrollEnrolled,
    payrollOnboardingOptions,
    onCloseDrawerCallback,
    isPayrollSetupDone,
  },
}) => {
  const trackUx = useTrackUx();

  useEffect(() => {
    if (isOpen) {
      if (isPayrollSetupDone) {
        trackUx(
          EVENT_ACTIONS.ADD_TEAM_PAYROLL_SUCCESS_MODAL_SHOWN,
          TRACK_ACTION_TYPES.VIEW,
          {
            eventCategory: EVENT_CATEGORIES.ADD_TEAM_PAYROLL_FLOW,
          }
        );
      } else {
        trackUx(EVENT_ACTIONS.MODAL_VIEWED, TRACK_ACTION_TYPES.VIEW, {
          eventCategory: EVENT_CATEGORIES.ADD_TEAM_SUCCESS,
          payroll_onboarding_type: isPayrollEnrolled
            ? payrollOnboardingOptions
            : null,
        });
      }
    }
  }, [
    isOpen,
    isPayrollEnrolled,
    payrollOnboardingOptions,
    isPayrollSetupDone,
    trackUx,
  ]);

  const handleClickUser = useCallback(() => {
    trackUx(EVENT_ACTIONS.LINK_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_SUCCESS,
      element: ELEMENT.EMPLOYEE_NAME_IN_SUCCESS_MODAL,
    });
    onClose();
  }, [onClose, trackUx]);

  const handleAddAnotherTeamMember = useCallback(() => {
    trackUx(EVENT_ACTIONS.LINK_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_SUCCESS,
      element: ELEMENT.ADD_ANOTHER_TEAM_MEMBER,
    });

    onClose();
    /** Wait for modal to close before reopening AddEmployeeForm,
     * to prevent blur error on firstName */
    setTimeout(() => {
      onShowAddEmployeeModal();
    }, 300);
  }, [trackUx, onClose, onShowAddEmployeeModal]);

  const handleClickDone = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_SUCCESS,
      button_text: BUTTON_TEXT.DONE,
    });
    if (onCloseDrawerCallback) onCloseDrawerCallback();
    onClose();
  }, [onClose, trackUx, onCloseDrawerCallback]);

  const handleSetupPayroll = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_PAYROLL_FLOW,
      button_text: BUTTON_TEXT.ENTER_TAX_DETAILS,
    });
    onClose();
    onOpenTaxEntryDrawer(userId);
  }, [onClose, onOpenTaxEntryDrawer, trackUx, userId]);

  const handleLinkClicked = useCallback(() => {
    trackUx(EVENT_ACTIONS.LINK_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_PAYROLL_FLOW,
      button_text: BUTTON_TEXT.ILL_DO_THIS_LATER,
    });
    if (onCloseDrawerCallback) onCloseDrawerCallback();
    onClose();
  }, [onClose, onCloseDrawerCallback, trackUx]);

  return (
    <Dialog hideCloseIcon noPadding open={isOpen} width={403}>
      {() => (
        <Box className={cx()} column mv={18} mh={36}>
          <Box w={210} m="auto">
            <Image h={210} src={HoorayGif} />
          </Box>

          <UserInfo
            title={title}
            userId={userId}
            onClickUser={handleClickUser}
          />

          {messageKey && (
            <Message
              title={`${toI18n(`${I18N_PATH}.sent`, {
                props: { name: capitalize(firstName) },
              })}`}
              description={`${toI18n(`${I18N_PATH}.${messageKey}`)}`}
            />
          )}

          {isPayrollEnrolled && !isPayrollSetupDone && (
            <Box mt={12}>
              <Message
                iconName="Warning"
                iconColor="orange300"
                title={`${toI18n(`${I18N_PATH}.pending_action`)}`}
                description={`${toI18n(`${I18N_PATH}.enter_payroll_details`)}`}
              />
            </Box>
          )}

          <ActionButtons
            messageKey={messageKey}
            onClickDone={handleClickDone}
            onAddAnotherTeamMember={handleAddAnotherTeamMember}
            isPayrollEnrolled={isPayrollEnrolled}
            isPayrollSetupDone={isPayrollSetupDone}
            onLinkClicked={handleLinkClicked}
            onSetupPayroll={handleSetupPayroll}
          />
        </Box>
      )}
    </Dialog>
  );
};

AddEmployeeSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOpenTaxEntryDrawer: PropTypes.func.isRequired,
  onShowAddEmployeeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  modalProps: PropTypes.shape({
    firstName: PropTypes.string,
    userId: PropTypes.number,
    title: PropTypes.string,
    messageKey: PropTypes.string,
    isPayrollEnrolled: PropTypes.bool,
    payrollOnboardingOptions: PropTypes.string,
    onCloseDrawerCallback: PropTypes.func,
    isPayrollSetupDone: PropTypes.bool,
  }),
};

export default connect(
  state => ({
    modalProps: selectSuccessModalProps(state),
    isOpen: selectSuccessModalIsOpen(state),
  }),
  {
    onShowAddEmployeeModal: showAddEmployeeModal,
    onClose: closeSuccessModal,
    onOpenTaxEntryDrawer: openTaxEntryDrawer,
  }
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.TEAM,
  })(memo(AddEmployeeSuccessModal))
);
