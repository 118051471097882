import React from 'react';
const SvgDashboard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M2.571 0A2.572 2.572 0 0 0 0 2.571v8.572a2.572 2.572 0 0 0 2.571 2.571h5.143a2.572 2.572 0 0 0 2.572-2.571V2.57A2.572 2.572 0 0 0 7.714 0H2.571Zm13.715 10.286a2.572 2.572 0 0 0-2.572 2.571v8.572A2.572 2.572 0 0 0 16.286 24h5.143A2.572 2.572 0 0 0 24 21.429v-8.572a2.572 2.572 0 0 0-2.571-2.571h-5.143ZM0 19.714v1.715A2.572 2.572 0 0 0 2.571 24h5.143a2.572 2.572 0 0 0 2.572-2.571v-1.715a2.572 2.572 0 0 0-2.572-2.571H2.571A2.572 2.572 0 0 0 0 19.714ZM16.286 0a2.572 2.572 0 0 0-2.572 2.571v1.715a2.572 2.572 0 0 0 2.572 2.571h5.143A2.572 2.572 0 0 0 24 4.286V2.57A2.572 2.572 0 0 0 21.429 0h-5.143Z"
    />
  </svg>
);
export default SvgDashboard;
