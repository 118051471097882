import React from 'react';
const SvgPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M4.504 1.5c0-.83.67-1.5 1.5-1.5h12c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H16.62l.534 6.947a7.877 7.877 0 0 1 3.727 4.439l.047.14c.155.46.075.962-.206 1.35a1.508 1.508 0 0 1-1.22.624h-15a1.502 1.502 0 0 1-1.424-1.973l.047-.141a7.876 7.876 0 0 1 3.726-4.44L7.387 3H6.004c-.83 0-1.5-.67-1.5-1.5Zm6 16.5h3v4.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V18Z"
    />
  </svg>
);
export default SvgPin;
