import React from 'react';
const SvgPdf = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M6 0C4.345 0 3 1.345 3 3v18c0 1.655 1.345 3 3 3h12c1.655 0 3-1.345 3-3V7.5h-6c-.83 0-1.5-.67-1.5-1.5V0H6Zm9 0v6h6l-6-6ZM6 10.5h1.125a2.627 2.627 0 0 1 2.625 2.625 2.627 2.627 0 0 1-2.625 2.625H6.75v1.5c0 .413-.338.75-.75.75a.752.752 0 0 1-.75-.75v-6c0-.412.338-.75.75-.75Zm1.125 3.75c.623 0 1.125-.502 1.125-1.125S7.748 12 7.125 12H6.75v2.25h.375Zm3.375-3c0-.412.338-.75.75-.75h1.125a2.25 2.25 0 0 1 2.25 2.25v3a2.25 2.25 0 0 1-2.25 2.25H11.25a.752.752 0 0 1-.75-.75v-6ZM12 16.5h.375c.412 0 .75-.337.75-.75v-3a.752.752 0 0 0-.75-.75H12v4.5Zm4.5-6h2.25c.413 0 .75.338.75.75s-.337.75-.75.75h-1.5v1.5h1.5c.413 0 .75.338.75.75s-.337.75-.75.75h-1.5v2.25c0 .413-.337.75-.75.75a.752.752 0 0 1-.75-.75v-6c0-.412.337-.75.75-.75Z"
    />
  </svg>
);
export default SvgPdf;
