import React, { ForwardedRef, forwardRef } from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import MaskedTextInput from 'fe-design-base/atoms/MaskedTextInput';
import { TextInputProps } from 'fe-design-base/atoms/TextInput';

export interface PhoneInputProps extends TextInputProps {
  uxElement?: string;
}

const US_PHONE_MASK = [
  '(',
  /[2-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const STRIP_CHARS = [/[()-]/g, /\s/g];

const PhoneInput = forwardRef(
  (
    { uxElement, name, label, cx, ...otherProps }: PhoneInputProps & CxProps,
    ref: ForwardedRef<any>
  ) => {
    const placeholder = US_PHONE_MASK.map((maskChar: any) =>
      typeof maskChar === 'string' ? maskChar : '_'
    ).join('');

    return (
      <MaskedTextInput
        ref={ref}
        className={cx()}
        mask={US_PHONE_MASK}
        guide
        name={name}
        label={label}
        stripChars={STRIP_CHARS}
        uxElement={uxElement}
        placeholder={placeholder}
        type="tel"
        {...otherProps}
      />
    );
  }
);

export default withCx<PhoneInputProps>('FDBPhoneInput')(PhoneInput);
