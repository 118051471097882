import './Box.scss';

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { isNumber, isString, isUndefined, keys, mapValues } from 'lodash';
import PropTypes from 'prop-types';
import { colors, dimensions, spacing } from 'theme/constants';

import cxHelpers from 'util/className';

import { getModifierProps, getSpacingProps, getStyleProps } from './helpers';

// Check whether value was passed
const passed = value =>
  !isUndefined(value) && (value || isNumber(value) || isString(value));

const COLOR_NAMES = keys(colors);
const WORD_BREAK_NAMES = [
  'normal',
  'break-all',
  'keep-all',
  'break-word',
  'initial',
  'inherit',
];

const MODIFIER_PROP_TYPES = {
  // text-align
  tcenter: PropTypes.bool,
  tleft: PropTypes.bool,
  tright: PropTypes.bool,

  // scroll
  scrollX: PropTypes.bool,
  scrollY: PropTypes.bool,

  // flex
  row: PropTypes.bool,
  rowreverse: PropTypes.bool,
  column: PropTypes.bool,
  columnreverse: PropTypes.bool,
  wrap: PropTypes.bool,
  wrapreverse: PropTypes.bool,
  baseline: PropTypes.bool,
  stretch: PropTypes.bool,
  spacebetween: PropTypes.bool,
  spacearound: PropTypes.bool,
  spaceevenly: PropTypes.bool,
  evenwidth: PropTypes.bool,

  // position
  absolute: PropTypes.bool,
  absoluteCover: PropTypes.bool,
  relative: PropTypes.bool,
  fixed: PropTypes.bool,
  sticky: PropTypes.bool,

  // display
  none: PropTypes.bool,
  hide: PropTypes.bool, // same as `none`
  inline: PropTypes.bool,
  block: PropTypes.bool,
  inlineBlock: PropTypes.bool,

  // box-sizing
  borderbox: PropTypes.bool,

  // box-shadow
  shadow: PropTypes.bool,

  // material-ui box-shadow
  muiShadow: PropTypes.bool,

  // cursor
  pointer: PropTypes.bool,

  // pointer-events: none
  pointerEventsNone: PropTypes.bool,

  // truncate text w ellipsis
  ellipsis: PropTypes.bool,

  // lower opacity and prevent clicking
  disabled: PropTypes.bool,

  // transition: all $duration;
  transitionAll: PropTypes.bool,

  // whitespace
  noWrap: PropTypes.bool,
};

const STYLE_PROP_TYPES = {
  // width
  w: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxw: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minw: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  // height
  h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  // border
  br: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  bl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  bt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  bb: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  bv: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  bh: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  b: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  bwidth: PropTypes.number,
  bstyle: PropTypes.string,
  bradius: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),

  // background
  bg: PropTypes.oneOfType([
    PropTypes.oneOf(COLOR_NAMES),
    PropTypes.string,
    PropTypes.bool,
  ]),

  // margin
  m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mv: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mh: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ma: PropTypes.bool,

  // padding
  p: PropTypes.number,
  pl: PropTypes.number,
  pr: PropTypes.number,
  pt: PropTypes.number,
  pb: PropTypes.number,
  pv: PropTypes.number,
  ph: PropTypes.number,

  // positioning
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  // z-index
  z: PropTypes.number,
  zIndex: PropTypes.number,

  // overflow
  overflow: PropTypes.string,
  overflowY: PropTypes.string,
  overflowX: PropTypes.string,

  // word break
  wordBreak: PropTypes.oneOf(WORD_BREAK_NAMES),

  // misc
  opacity: PropTypes.number,
  cursor: PropTypes.string,
};

const FLEX_PROP_TYPES = {
  vtop: PropTypes.bool,
  vcenter: PropTypes.bool,
  vbottom: PropTypes.bool,
  hleft: PropTypes.bool,
  hcenter: PropTypes.bool,
  hright: PropTypes.bool,
  grow: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  shrink: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  basis: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  order: PropTypes.number,
};

const SPACING_PROP_KEYS = [...spacing];
const SPACING_PROP_TYPES = mapValues(SPACING_PROP_KEYS, () => PropTypes.bool);

@cxHelpers('Box')
export default class Box extends PureComponent {
  static propTypes = {
    ...MODIFIER_PROP_TYPES,
    ...STYLE_PROP_TYPES,
    ...SPACING_PROP_TYPES,
    ...FLEX_PROP_TYPES,
    tag: PropTypes.string,
    getRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  };
  // Accepts all margin helpers as booleans - mrxs, mrs, mrxl, etc.
  // These are treated as `implicitProps` and applied directly as
  // css classes in the render method

  static defaultProps = {
    tag: 'div',
  };

  setVerticalFlexModifier(props, direction) {
    props[
      `${this.props.column ? 'justify-content' : 'align-items'}-${direction}`
    ] = true;
  }

  setHorizontalFlexModifier(props, direction) {
    props[
      `${this.props.column ? 'align-items' : 'justify-content'}-${direction}`
    ] = true;
  }

  convertToPx(val) {
    const valInt = parseInt(val, 10);
    return `${val}${String(valInt) === val || valInt === val ? 'px' : ''}`;
  }

  // Mostly lifted from react-flexview:
  // https://github.com/buildo/react-flexview/blob/master/src/FlexView.js#L156
  setFlexStyle(style, grow, shrink, basis) {
    if (passed(grow)) {
      grow = isNumber(grow) ? grow : 1;

      style.WebkitBoxFlexGrow = grow;
      style.MozBoxFlexGrow = grow;
      style.msFlexGrow = grow;
      style.WebkitFlexGrow = grow;
      style.flexGrow = grow;
    }
    if (passed(shrink)) {
      shrink = isNumber(shrink) ? shrink : 1;

      style.WebkitBoxFlexShrink = shrink;
      style.MozBoxFlexShrink = shrink;
      style.msFlexShrink = shrink;
      style.WebkitFlexShrink = shrink;
      style.flexShrink = shrink;
    }

    if (passed(basis)) {
      basis = isNumber(basis) ? this.convertToPx(basis) : basis;

      style.WebkitBoxFlexBasis = basis;
      style.MozBoxFlexBasis = basis;
      style.msFlexBasis = basis;
      style.WebkitFlexBasis = basis;
      style.flexBasis = basis;
    }
  }

  setStyle(style, props, value) {
    value = dimensions[value] || value;
    props.forEach(prop => {
      style[prop] = value;
    });
  }

  setBorder(style, props, value) {
    if (!value) return this.setStyle(style, props, 'none');

    const valueIsString = isString(value);
    let borderColor;
    let borderWidth;
    let borderStyle;

    // Allow directly setting
    if (valueIsString && value.split(' ').length === 3) {
      const [width, bStyle, color] = value.split(' ');

      borderColor = colors[color];
      borderWidth = width;
      borderStyle = bStyle;
    } else {
      borderColor = valueIsString ? colors[value] || value : colors.borderColor;
      borderWidth = this.convertToPx(
        this.props.bwidth || dimensions.borderWidth
      );
      borderStyle = this.props.bstyle || 'solid';
    }

    const border = `${borderWidth} ${borderStyle} ${borderColor}`;
    this.setStyle(style, props, border);
  }

  setBackground(style, color) {
    style.background =
      typeof color === 'string' ? colors[color] || color : colors.white;
  }

  setBorderRadius(style, radius) {
    style.borderRadius =
      typeof radius === 'boolean' ? dimensions.borderRadius : radius;
  }

  addFlexProps(props, modifierProps, style) {
    const {
      grow,
      shrink,
      basis,
      vtop,
      vcenter,
      vbottom,
      hleft,
      hcenter,
      hright,
      order,
      ...newProps
    } = props;

    // Add flex positioning modifier classes
    if (vtop) {
      this.setVerticalFlexModifier(modifierProps, 'start');
    }
    if (vcenter) {
      this.setVerticalFlexModifier(modifierProps, 'center');
    }
    if (vbottom) {
      this.setVerticalFlexModifier(modifierProps, 'end');
    }
    if (hleft) {
      this.setHorizontalFlexModifier(modifierProps, 'start');
    }
    if (hcenter) {
      this.setHorizontalFlexModifier(modifierProps, 'center');
    }
    if (hright) {
      this.setHorizontalFlexModifier(modifierProps, 'end');
    }
    if (order) {
      this.setStyle(style, ['order'], order);
    }

    // Add grow/shrink/basis styles
    this.setFlexStyle(style, grow, shrink, basis);

    return newProps;
  }

  render() {
    const {
      children,
      style: passThroughStyles,
      tag,
      getRef,
      className,
      ...props
    } = this.props;

    const { props: props1, ...modifierProps } = getModifierProps(props);
    const { props: props2, ...styleProps } = getStyleProps(props1);
    const { props: props3, ...spacingProps } = getSpacingProps(props2);
    const spacingPropsAsClasses = classnames(spacingProps);

    const Component = tag;

    const style = {};
    if (passed(styleProps.w)) {
      style.width = styleProps.w;
    }
    if (passed(styleProps.maxw)) {
      style.maxWidth = styleProps.maxw;
    }
    if (passed(styleProps.minw)) {
      style.minWidth = styleProps.minw;
    }
    if (passed(styleProps.h)) {
      style.height = styleProps.h;
    }
    if (passed(styleProps.maxh)) {
      style.maxHeight = styleProps.maxh;
    }
    if (passed(styleProps.minh)) {
      style.minHeight = styleProps.minh;
    }

    if (passed(styleProps.b)) {
      this.setBorder(style, ['border'], styleProps.b);
    }
    if (passed(styleProps.br)) {
      this.setBorder(style, ['borderRight'], styleProps.br);
    }
    if (passed(styleProps.bl)) {
      this.setBorder(style, ['borderLeft'], styleProps.bl);
    }
    if (passed(styleProps.bt)) {
      this.setBorder(style, ['borderTop'], styleProps.bt);
    }
    if (passed(styleProps.bb)) {
      this.setBorder(style, ['borderBottom'], styleProps.bb);
    }
    if (passed(styleProps.bh)) {
      this.setBorder(style, ['borderTop', 'borderBottom'], styleProps.bh);
    }
    if (passed(styleProps.bv)) {
      this.setBorder(style, ['borderLeft', 'borderRight'], styleProps.bv);
    }
    if (passed(styleProps.bradius)) {
      this.setBorderRadius(style, styleProps.bradius);
    }

    if (passed(styleProps.m)) {
      this.setStyle(style, ['margin'], styleProps.m);
    }
    if (passed(styleProps.mr)) {
      this.setStyle(style, ['marginRight'], styleProps.mr);
    }
    if (passed(styleProps.ml)) {
      this.setStyle(style, ['marginLeft'], styleProps.ml);
    }
    if (passed(styleProps.mt)) {
      this.setStyle(style, ['marginTop'], styleProps.mt);
    }
    if (passed(styleProps.mb)) {
      this.setStyle(style, ['marginBottom'], styleProps.mb);
    }
    if (passed(styleProps.mv)) {
      this.setStyle(style, ['marginTop', 'marginBottom'], styleProps.mv);
    }
    if (passed(styleProps.mh)) {
      this.setStyle(style, ['marginLeft', 'marginRight'], styleProps.mh);
    }
    if (passed(styleProps.ma)) {
      this.setStyle(style, ['margin'], 'auto');
    }

    if (passed(styleProps.p)) {
      this.setStyle(style, ['padding'], styleProps.p);
    }
    if (passed(styleProps.pr)) {
      this.setStyle(style, ['paddingRight'], styleProps.pr);
    }
    if (passed(styleProps.pl)) {
      this.setStyle(style, ['paddingLeft'], styleProps.pl);
    }
    if (passed(styleProps.pt)) {
      this.setStyle(style, ['paddingTop'], styleProps.pt);
    }
    if (passed(styleProps.pb)) {
      this.setStyle(style, ['paddingBottom'], styleProps.pb);
    }
    if (passed(styleProps.pv)) {
      this.setStyle(style, ['paddingTop', 'paddingBottom'], styleProps.pv);
    }
    if (passed(styleProps.ph)) {
      this.setStyle(style, ['paddingLeft', 'paddingRight'], styleProps.ph);
    }

    if (passed(styleProps.top)) {
      this.setStyle(style, ['top'], styleProps.top);
    }
    if (passed(styleProps.right)) {
      this.setStyle(style, ['right'], styleProps.right);
    }
    if (passed(styleProps.bottom)) {
      this.setStyle(style, ['bottom'], styleProps.bottom);
    }
    if (passed(styleProps.left)) {
      this.setStyle(style, ['left'], styleProps.left);
    }

    if (passed(styleProps.bg)) {
      this.setBackground(style, styleProps.bg);
    }

    if (passed(styleProps.z) || passed(styleProps.zIndex)) {
      this.setStyle(style, ['zIndex'], styleProps.z || styleProps.zIndex);
    }
    if (passed(styleProps.overflow)) {
      this.setStyle(style, ['overflow'], styleProps.overflow);
    }
    if (passed(styleProps.overflowY)) {
      this.setStyle(style, ['overflowY'], styleProps.overflowY);
    }
    if (passed(styleProps.overflowX)) {
      this.setStyle(style, ['overflowX'], styleProps.overflowX);
    }
    if (passed(styleProps.wordBreak)) {
      this.setStyle(style, ['wordBreak'], styleProps.wordBreak);
    }
    if (passed(styleProps.opacity)) {
      this.setStyle(style, ['opacity'], styleProps.opacity);
    }
    if (passed(styleProps.cursor)) {
      this.setStyle(style, ['cursor'], styleProps.cursor);
    }

    const passThroughProps = this.addFlexProps(props3, modifierProps, style);

    return (
      <Component
        {...passThroughProps}
        ref={getRef}
        className={this.cx(modifierProps, spacingPropsAsClasses)}
        style={{ ...style, ...passThroughStyles }}
      >
        {children}
      </Component>
    );
  }
}
