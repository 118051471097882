import './Features.scss';

import React from 'react';
import Text from 'fe-design-base/atoms/Text';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('Features');

export const Features = ({ features }) => (
  <ul className={cx()}>
    {features.map(feature => (
      <li key={feature}>
        <Text variant="body" color="mono900">
          {feature}
        </Text>
      </li>
    ))}
  </ul>
);

export default Features;
