import React from 'react';
const SvgTimeClockOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M10.875 5.625c0-.623.502-1.125 1.125-1.125s1.125.502 1.125 1.125V11.4l3.998 2.662c.516.347.657 1.046.272 1.561-.304.516-1.003.657-1.518.272l-4.5-3c-.315-.168-.502-.52-.502-.937V5.625ZM12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0ZM2.25 12A9.749 9.749 0 0 0 12 21.75 9.749 9.749 0 0 0 21.75 12 9.749 9.749 0 0 0 12 2.25 9.749 9.749 0 0 0 2.25 12Z"
    />
  </svg>
);
export default SvgTimeClockOutline;
