import React from 'react';
const SvgReportsOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M2.25 19.125c0 .623.504 1.125 1.125 1.125h19.5c.623 0 1.125.502 1.125 1.125s-.502 1.125-1.125 1.125h-19.5A3.374 3.374 0 0 1 0 19.125v-16.5a1.125 1.125 0 0 1 2.25 0v16.5ZM16.125 6h5.25c.623 0 1.125.502 1.125 1.125v5.25c0 .623-.502 1.125-1.125 1.125a1.122 1.122 0 0 1-1.125-1.125V9.839l-5.245 5.166c-.399.478-1.111.478-1.552 0l-3.745-3.666-2.953 2.916c-.399.478-1.111.478-1.552 0-.436-.399-.436-1.111 0-1.552l3.75-3.75c.44-.436 1.153-.436 1.552 0l3.745 3.708 4.41-4.411h-2.535A1.122 1.122 0 0 1 15 7.125C15 6.502 15.502 6 16.125 6Z"
    />
  </svg>
);
export default SvgReportsOutline;
