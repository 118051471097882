import React from 'react';
const SvgScheduleOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M5.296 1.3c.591 0 1 .448 1 1v1.666h6.037V2.3a.999.999 0 1 1 2 0v1.666H16a2.668 2.668 0 0 1 2.667 2.667V9.3H1.962v10.666c0 .367.336.667.667.667h9.225a7.344 7.344 0 0 0 1.93 2H2.666A2.668 2.668 0 0 1 0 19.967V6.633a2.666 2.666 0 0 1 2.667-2.667h1.666V2.3a1 1 0 0 1 1-1h-.037ZM24 16.633c0 3.313-2.688 6-6 6-3.313 0-6.037-2.687-6.037-6 0-3.312 2.725-6 6.037-6 3.313 0 6 2.688 6 6Zm-6.704-2.666v2.666c0 .367.337.667.666.667H20c.367 0 .667-.3.667-.667 0-.366-.3-.666-.667-.666h-1.37v-2a.646.646 0 0 0-.668-.667c-.329 0-.666.3-.666.666Z"
    />
  </svg>
);
export default SvgScheduleOutline;
