import React from 'react';
const SvgMonoSpotify = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 2.667C8.636 2.667 2.666 8.636 2.666 16S8.636 29.333 16 29.333c7.363 0 13.333-5.969 13.333-13.333S23.363 2.667 16 2.667Zm6.505 19.22a1.163 1.163 0 0 1-1.58.446 13.809 13.809 0 0 0-5.396-1.7 13.88 13.88 0 0 0-5.64.585 1.16 1.16 0 0 1-.705-2.209 16.144 16.144 0 0 1 6.58-.682c2.234.227 4.351.894 6.294 1.983.56.312.76 1.018.447 1.578Zm1.596-4.05a1.158 1.158 0 0 1-1.564.493 18.122 18.122 0 0 0-6.573-1.96 18.068 18.068 0 0 0-6.88.612 1.16 1.16 0 0 1-.642-2.229 20.427 20.427 0 0 1 7.756-.691c2.61.266 5.103 1.009 7.412 2.212.566.296.788.996.49 1.564Zm.578-3.369c-.174 0-.35-.039-.517-.121a22.405 22.405 0 0 0-7.766-2.242 22.356 22.356 0 0 0-8.073.638 1.16 1.16 0 0 1-.596-2.24 24.713 24.713 0 0 1 8.905-.705 24.686 24.686 0 0 1 8.564 2.473 1.16 1.16 0 0 1-.517 2.197Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMonoSpotify;
