import React from 'react';
const SvgNotification = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M13.5 1.5v.9c3.422.695 6 3.722 6 7.35v.881c0 2.208.81 4.332 2.273 5.982l.347.389c.394.445.492 1.073.249 1.612A1.503 1.503 0 0 1 21 19.5H3a1.5 1.5 0 0 1-1.121-2.498l.348-.39a9.003 9.003 0 0 0 2.273-5.98V9.75a7.463 7.463 0 0 1 6-7.35v-.9a1.5 1.5 0 1 1 3 0ZM12 24c-.797 0-1.56-.314-2.123-.877A3.076 3.076 0 0 1 9 21h6c0 .755-.314 1.56-.877 2.123A3.076 3.076 0 0 1 12 24Z"
    />
  </svg>
);
export default SvgNotification;
