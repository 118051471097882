import React from 'react';
const SvgCollapseRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M24 3.414c0-.948-.766-1.714-1.714-1.714-.949 0-1.715.766-1.715 1.714v17.143c0 .949.766 1.715 1.715 1.715.948 0 1.714-.766 1.714-1.715V3.414Zm-7.36 9.783a1.718 1.718 0 0 0 0-2.427L9.781 3.913a1.717 1.717 0 0 0-2.427 2.426l3.932 3.933H1.714c-.948 0-1.714.766-1.714 1.714S.766 13.7 1.714 13.7h9.573l-3.932 3.932a1.717 1.717 0 0 0 2.427 2.427l6.857-6.857v-.005Z"
    />
  </svg>
);
export default SvgCollapseRight;
