import { batch } from 'react-redux';
import * as routes from 'api';
import { pollWorkerStatus } from 'api/workerStatus';

import {
  hideModal,
  showDowngradeManagersModal,
  showModal,
} from 'actions/modals';
import * as teamViewActions from 'actions/teamView';

import { getCompanyRoles, getOpenedAddTeamModal } from 'selectors/addTeam';
import { getChangedRowsData } from 'selectors/bulkAdd';
import {
  getCpAddTeamMemberFlowEnabled,
  getCurrentLocationId,
  getCurrentLocationPinLength,
} from 'selectors/session';

import { bulkAddActions } from 'features/addTeam/bulkAdd/slices';
import * as constants from 'features/addTeam/constants';
import {
  ADD_TEAM_DRAWER_KEY,
  ADD_TEAM_INDIVIDUAL_DRAWER,
  CHOOSE_HOW_DRAWER_KEY,
} from 'features/drawers/keys';

import * as flashNotice from 'util/flashNotice';
import { getProperties } from 'util/formatter';
import { toI18n } from 'util/i18n';
import {
  createAsyncGetAction,
  createAsyncPostAction,
  withAlerts,
} from 'util/redux';

import { openAddTeamDrawer } from '../features/team/slice';
import {
  EVENT_CATEGORIES,
  LOCATION_PROPERTIES,
  PRODUCT_AREAS,
  trackErrorMessageShown,
} from '../features/teamView/tracking';

export const actionTypes = {
  SHOW_DRAWER: 'ADD_TEAM/SHOW_DRAWER',
  CLOSE_DRAWER: 'ADD_TEAM/CLOSE_DRAWER',
  CHOOSE_HOW_DRAWER: 'ADD_TEAM/CHOOSE_HOW_DRAWER',
  CREATE_EMPLOYEES_REQUEST: 'ADD_TEAM/CREATE_EMPLOYEES_REQUEST',
  CREATE_EMPLOYEES_SUCCESS: 'ADD_TEAM/CREATE_EMPLOYEES_SUCCESS',
  CREATE_EMPLOYEES_FAILURE: 'ADD_TEAM/CREATE_EMPLOYEES_FAILURE',
  UPDATE_EMPLOYEES_REQUEST: 'ADD_TEAM/UPDATE_EMPLOYEES_REQUEST',
  UPDATE_EMPLOYEES_SUCCESS: 'ADD_TEAM/UPDATE_EMPLOYEES_SUCCESS',
  UPDATE_EMPLOYEES_FAILURE: 'ADD_TEAM/UPDATE_EMPLOYEES_FAILURE',
  ADD_MORE_EMPLOYEES: 'ADD_TEAM/ADD_MORE_EMPLOYEES',
  UPDATE_EMPLOYEE: 'ADD_TEAM/UPDATE_EMPLOYEE',
  TOGGLE_UPDATE_TEAM_DRAWER: 'ADD_TEAM/TOGGLE_UPDATE_TEAM_DRAWER',
  SHOW_ADD_EMPLOYEE_MODAL: 'ADD_TEAM/SHOW_ADD_EMPLOYEE_MODAL',
  SHOW_CONNECT_POS_MODAL: 'ADD_TEAM/SHOW_CONNECT_POS_MODAL',
  TOGGLE_ADD_EMPLOYEE_WARNING_MODAL_OPEN:
    'ADD_TEAM/TOGGLE_ADD_EMPLOYEE_WARNING_MODAL_OPEN',
  SYNC_EMPLOYEES_REQUEST: 'ADD_TEAM/SYNC_EMPLOYEES_REQUEST',
  SYNC_EMPLOYEES_SUCCESS: 'ADD_TEAM/SYNC_EMPLOYEES_SUCCESS',
  SYNC_EMPLOYEES_FAILURE: 'ADD_TEAM/SYNC_EMPLOYEES_FAILURE',

  CREATE_EMPLOYEE_REQUEST: 'ADD_TEAM/CREATE_EMPLOYEE_REQUEST',
  CREATE_EMPLOYEE_SUCCESS: 'ADD_TEAM/CREATE_EMPLOYEE_SUCCESS',
  CREATE_EMPLOYEE_FAILURE: 'ADD_TEAM/CREATE_EMPLOYEE_FAILURE',

  FETCH_COMPANY_ROLES_REQUEST: 'ADD_TEAM/FETCH_COMPANY_ROLES_REQUEST',
  FETCH_COMPANY_ROLES_SUCCESS: 'ADD_TEAM/FETCH_COMPANY_ROLES_SUCCESS',
  FETCH_COMPANY_ROLES_FAILURE: 'ADD_TEAM/FETCH_COMPANY_ROLES_FAILURE',

  BULK_ADD_EMPLOYEE_REQUEST: 'ADD_TEAM/BULK_ADD_EMPLOYEE_REQUEST',
  BULK_ADD_EMPLOYEE_SUCCESS: 'ADD_TEAM/BULK_ADD_EMPLOYEE_SUCCESS',
  BULK_ADD_EMPLOYEE_FAILURE: 'ADD_TEAM/BULK_ADD_EMPLOYEE_FAILURE',
};

export const toggleChooseHowDrawer = show => ({
  type: actionTypes.CHOOSE_HOW_DRAWER,
  payload: {
    drawerKey: CHOOSE_HOW_DRAWER_KEY,
    drawerOpen: !!show,
  },
});

export const fetchCompanyRoles = () =>
  createAsyncGetAction(
    routes.rolesRoute(),
    [
      { type: actionTypes.FETCH_COMPANY_ROLES_REQUEST },
      { type: actionTypes.FETCH_COMPANY_ROLES_SUCCESS },
      { type: actionTypes.FETCH_COMPANY_ROLES_FAILURE },
    ],
    { bailout: state => getCompanyRoles(state).size > 0 }
  );

export const showIndividualDrawer =
  (drawerProps = {}) =>
  dispatch => {
    dispatch(fetchCompanyRoles());

    dispatch({
      type: actionTypes.SHOW_DRAWER,
      payload: {
        drawerKey: ADD_TEAM_INDIVIDUAL_DRAWER,
        drawerOpen: true,
        drawerProps,
      },
    });
  };

export const closeIndividualDrawer = () => ({
  type: actionTypes.CLOSE_DRAWER,
  payload: {
    drawerKey: ADD_TEAM_INDIVIDUAL_DRAWER,
    drawerOpen: false,
  },
});

export const closeDrawer = () => ({
  type: actionTypes.CLOSE_DRAWER,
  payload: {
    drawerKey: ADD_TEAM_DRAWER_KEY,
    drawerOpen: false,
  },
});

export const createEmployee = (data, onSuccess, onError) =>
  withAlerts(
    createAsyncPostAction(
      routes.employeesRoute(),
      [
        { type: actionTypes.CREATE_EMPLOYEE_REQUEST },
        { type: actionTypes.CREATE_EMPLOYEE_SUCCESS },
        { type: actionTypes.CREATE_EMPLOYEE_FAILURE },
      ],
      {
        body: {
          ...data,
          team_show: true,
          json_errors: true,
        },
      }
    ),
    {
      onSuccess: response => {
        window.Backbone.Mediator.pub('employees:created');
        if (onSuccess) onSuccess(response.payload?.id);
      },
      onError: response => {
        const { errors } = response.payload.response;
        const error_msg = getProperties(errors[0]);
        const location =
          error_msg ===
          toI18n('team.employee_profile.add_existing_user.same_company_error')
            ? null
            : LOCATION_PROPERTIES.ROSTER_TOP;

        trackErrorMessageShown({
          eventCategory: EVENT_CATEGORIES.TEAM_DRAWER,
          productArea: PRODUCT_AREAS.TEAM,
          properties: {
            error_msg,
            location,
          },
        });
        onError();
      },
    }
  );

export const insertEmployees = users => dispatch => {
  batch(() =>
    users.forEach(user =>
      dispatch({ type: actionTypes.CREATE_EMPLOYEE_SUCCESS, payload: user })
    )
  );
};

export const showAddEmployeeModal =
  ({ chooseHowDrawer = true } = {}) =>
  (dispatch, getState) => {
    const state = getState();

    const cpAddTeamMemberFlowEnabled = getCpAddTeamMemberFlowEnabled(state);

    if (cpAddTeamMemberFlowEnabled) {
      dispatch(openAddTeamDrawer());
    } else if (!chooseHowDrawer || getOpenedAddTeamModal(state)) {
      dispatch(showIndividualDrawer());
    } else {
      dispatch(toggleChooseHowDrawer(true));
    }
  };

export const showConnectPosModal = () =>
  showModal(constants.CONNECT_POS_MODAL_TYPE, { deprecatedModal: true });

export const showSyncEmployeesModal = (source, isPOS) =>
  showModal(constants.SYNC_EMPLOYEES_MODAL_TYPE, {
    deprecatedModal: true,
    source,
    isPOS,
  });

export const showBulkAddModal = () => (dispatch, getState) => {
  const state = getState();

  dispatch(fetchCompanyRoles());

  dispatch(
    bulkAddActions.setDefaults({
      count: 12,
      locationId: getCurrentLocationId(state),
      pinLength: getCurrentLocationPinLength(state),
    })
  );

  dispatch(
    showModal(constants.BULK_ADD_TEAM_MODAL, {
      deprecatedModal: true,
      fullScreen: true,
      hideCloseIcon: true,
    })
  );
};

// This should be here to avoid Circular dependency
export const bulkAddEmployees =
  (
    { existingUsersFlag, inviteUsers, skipFlashMessage = false },
    { onSuccess, onError }
  ) =>
  (dispatch, getState) => {
    const usersData = getChangedRowsData(getState());
    return dispatch(
      withAlerts(
        createAsyncPostAction(
          routes.employeesBulkAdd(),
          [
            actionTypes.BULK_ADD_EMPLOYEE_REQUEST,
            actionTypes.BULK_ADD_EMPLOYEE_SUCCESS,
            actionTypes.BULK_ADD_EMPLOYEE_FAILURE,
          ],
          {
            body: {
              users: usersData,
              existing_users: existingUsersFlag,
              invite: inviteUsers,
            },
          }
        ),
        {
          onSuccess: res => {
            // Populate employees created event for backbone pages
            window.Backbone.Mediator.pub('employees:created');
            // Hard-refresh employee data for react pages
            dispatch(insertEmployees(res.payload.users));

            if (onSuccess) onSuccess(res.payload);
          },
          ...(!skipFlashMessage && {
            success: res =>
              toI18n('add_team.success', {
                props: { num: res.payload.users.length },
              }),
          }),
          error: response => {
            if (typeof response.payload.response.errors === 'string') {
              return response.payload.response.errors;
            }

            return toI18n('errors.generic');
          },
          onError: response => {
            if (typeof response.payload.response.errors !== 'string') {
              dispatch(
                bulkAddActions.mergeRows(response.payload.response.errors)
              );
            }
            onError();
          },
        }
      )
    );
  };

export const showSyncWarningsModal =
  (warnings, managersDowngraded) => dispatch =>
    dispatch(
      showModal(constants.SYNC_WARNINGS_MODAL_TYPE, {
        deprecatedModal: true,
        warnings,
        onClose: closeModal => {
          if (managersDowngraded.length) {
            dispatch(showDowngradeManagersModal(managersDowngraded));
          }
          closeModal();
        },
      })
    );

export const toggleAddEmployeeWarningModalOpen = modalOpen => ({
  type: actionTypes.TOGGLE_ADD_EMPLOYEE_WARNING_MODAL_OPEN,
  payload: { modalOpen },
});

const checkEmployeeSyncJobDone = (dispatch, getState, jobId) => {
  pollWorkerStatus(jobId)
    .then(resp => {
      const warnings = JSON.parse(resp.skipped_import_warnings);
      const managersDowngraded = JSON.parse(resp.managers_downgraded);

      if (warnings.length) {
        dispatch(showSyncWarningsModal(warnings, managersDowngraded));
      } else if (managersDowngraded.length) {
        dispatch(showDowngradeManagersModal(managersDowngraded));
      } else {
        dispatch(hideModal());
        flashNotice.show(
          'notice',
          toI18n('employees_roster.sync_employees_modal.success')
        );
      }

      dispatch(teamViewActions.fetchRosterData(true));
    })
    .catch(() => {
      dispatch(hideModal());
      flashNotice.show('error', toI18n('common.error'));
    });
};

export const syncEmployees =
  ({ locationId, sendInvite, pos }) =>
  (dispatch, getState) => {
    const companySync = locationId === 'all';
    const route = companySync
      ? routes.companySyncRoute()
      : routes.employeesSyncRoute();

    return dispatch(
      withAlerts(
        createAsyncPostAction(
          route,
          [
            { type: actionTypes.SYNC_EMPLOYEES_REQUEST },
            { type: actionTypes.SYNC_EMPLOYEES_SUCCESS },
            { type: actionTypes.SYNC_EMPLOYEES_FAILURE },
          ],
          {
            body: {
              location_id: companySync ? null : locationId,
              send_invite: sendInvite,
              pos,
            },
          }
        ),
        {
          onSuccess: response => {
            const jobId = response.payload.job_id;
            if (jobId) {
              checkEmployeeSyncJobDone(dispatch, getState, jobId);
            }
          },
        }
      )
    );
  };
