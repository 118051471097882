import colors from 'fe-design-base/styles/colors';
import { typography } from 'fe-design-base/styles/typography';
import { borderRadius } from 'fe-design-base/styles/utils';

interface SelectInputStyleProps {
  showClearIcon: boolean;
  disabled?: boolean;
}

export const getSelectInputStyles = ({
  showClearIcon,
  disabled,
}: SelectInputStyleProps) => ({
  minHeight: '40px',
  marginRight: '4px',
  width: '100%',
  cursor: disabled ? 'not-allowed' : 'pointer',
  borderRadius: borderRadius.small,
  backgroundColor: disabled ? `${colors.mono300} !important` : undefined,
  ...typography.body,
  '&.FDBSelect:hover .MuiSelect-iconOutlined': {
    display: showClearIcon ? 'none' : '',
  },
  '&.FDBSelect': {
    '&.FDBSelect:not(:hover) .FDBSelect__clear-icon': {
      display: showClearIcon ? 'none' : '',
    },
    '&.FDBSelect:hover .FDBSelect__clear-icon': {
      display: showClearIcon ? 'unset' : '',
      marginRight: '12px',
    },
  },
});
