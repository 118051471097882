import React from 'react';
const SvgLock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M12 3a3.749 3.749 0 0 1 3.75 3.75V9h-7.5V6.75A3.749 3.749 0 0 1 12 3ZM5.25 6.75V9H4.5c-1.655 0-3 1.345-3 3v9c0 1.655 1.345 3 3 3h15c1.655 0 3-1.345 3-3v-9c0-1.655-1.345-3-3-3h-.75V6.75A6.752 6.752 0 0 0 12 0a6.752 6.752 0 0 0-6.75 6.75ZM13.5 15v3c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5v-3c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5Z"
    />
  </svg>
);
export default SvgLock;
