import React, { useCallback } from 'react';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PastDueDialogBox from '../components/PastDueDialogBox';

const ctaText = toI18n(
  'past_due_subscription.dialog.non_billing_permission.secondary_cta'
);

export const AfterGracePeriodNonBillingPermissionsView = ({
  closePastDueDialog,
}) => {
  const handleOnClick = useCallback(() => {
    closePastDueDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PastDueDialogBox>
      <Header
        titleI18n="past_due_subscription.dialog.non_billing_permission.after_grace_period.title"
        iconColor="orange300"
        iconName="Warning"
      />
      <Text
        variant="body"
        color="mono900"
        i18n="past_due_subscription.dialog.non_billing_permission.after_grace_period.description"
        asHTML
      />
      <Text
        variant="bodySm"
        color="mono700"
        i18n="past_due_subscription.dialog.non_billing_permission.after_grace_period.contact_information"
        asHTML
      />
      <Footer
        secondaryCtaText={ctaText}
        secondaryUxElement="sign-out-cta"
        onClickSecondary={handleOnClick}
        buttonProps={{
          dataMethod: 'delete',
          target: '_self',
          href: '/accounts/sign_out',
        }}
      />
    </PastDueDialogBox>
  );
};

export default AfterGracePeriodNonBillingPermissionsView;
