import React from 'react';
const SvgLike = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M14.69 1.542a2.252 2.252 0 0 1 1.768 2.649l-.108.534A11.174 11.174 0 0 1 15 8.25h6.75a2.25 2.25 0 0 1 .173 4.495 2.25 2.25 0 0 1-1.247 3.712c.207.338.324.741.324 1.168 0 .998-.652 1.847-1.552 2.137A2.25 2.25 0 0 1 17.25 22.5h-3.445a4.51 4.51 0 0 1-2.499-.755l-1.804-1.204A4.5 4.5 0 0 1 7.5 16.795v-5.212c0-1.369.623-2.658 1.688-3.516l.346-.276a6.734 6.734 0 0 0 2.4-3.947l.108-.535a2.252 2.252 0 0 1 2.649-1.767ZM1.5 9h3c.83 0 1.5.67 1.5 1.5V21c0 .83-.67 1.5-1.5 1.5h-3C.67 22.5 0 21.83 0 21V10.5C0 9.67.67 9 1.5 9Z"
    />
  </svg>
);
export default SvgLike;
