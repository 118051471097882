import React from 'react';
const SvgImport = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M16.5 4.5h3c.83 0 1.5.67 1.5 1.5v12c0 .83-.67 1.5-1.5 1.5h-3c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h3c2.484 0 4.5-2.016 4.5-4.5V6c0-2.484-2.016-4.5-4.5-4.5h-3c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5Zm-.44 8.56a1.502 1.502 0 0 0 0-2.124l-6-6a1.502 1.502 0 0 0-2.124 2.123l3.44 3.441H1.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h9.877l-3.441 3.44a1.502 1.502 0 0 0 2.123 2.124l6-6v-.005Z"
    />
  </svg>
);
export default SvgImport;
