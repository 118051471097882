import React from 'react';
import Box from 'fe-design-base/atoms/Box';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('PastDueDialogBox');

export const PastDueDialogBox = ({ children, className }) => (
  <Box
    column
    maxw={576}
    ph={32}
    pb={32}
    spacebetween
    gap={32}
    className={`${cx()} ${className}`}
  >
    {children}
  </Box>
);

export default PastDueDialogBox;
