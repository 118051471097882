import React from 'react';
const SvgGrip = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M4.5 2.77C4.5 1.24 5.76 0 7.313 0c1.553 0 2.812 1.24 2.812 2.77 0 1.528-1.26 2.768-2.813 2.768C5.76 5.538 4.5 4.298 4.5 2.77Zm0 9.23c0-1.529 1.26-2.77 2.813-2.77 1.553 0 2.812 1.241 2.812 2.77s-1.26 2.77-2.813 2.77C5.76 14.77 4.5 13.528 4.5 12Zm5.625 9.23c0 1.53-1.26 2.77-2.813 2.77C5.76 24 4.5 22.76 4.5 21.23c0-1.528 1.26-2.768 2.813-2.768 1.553 0 2.812 1.24 2.812 2.769Zm3.75-18.46c0-1.53 1.26-2.77 2.813-2.77C18.24 0 19.5 1.24 19.5 2.77c0 1.528-1.26 2.768-2.813 2.768-1.552 0-2.812-1.24-2.812-2.769ZM19.5 12c0 1.529-1.26 2.77-2.813 2.77-1.552 0-2.812-1.241-2.812-2.77s1.26-2.77 2.813-2.77c1.552 0 2.812 1.241 2.812 2.77Zm-5.625 9.23c0-1.528 1.26-2.768 2.813-2.768 1.552 0 2.812 1.24 2.812 2.769C19.5 22.76 18.24 24 16.687 24c-1.552 0-2.812-1.24-2.812-2.77Z"
    />
  </svg>
);
export default SvgGrip;
