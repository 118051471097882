import React from 'react';
const SvgFolders = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M21.333 17.317A2.67 2.67 0 0 0 24 14.65V7.984a2.67 2.67 0 0 0-2.667-2.667h-4.895c-.709 0-1.388-.28-1.888-.78L13.446 3.43c-.5-.5-1.18-.78-1.888-.78H6.667A2.67 2.67 0 0 0 4 5.317v9.333a2.67 2.67 0 0 0 2.667 2.667h14.666ZM2 6.317c0-.554-.446-1-1-1s-1 .446-1 1v9.333a5.667 5.667 0 0 0 5.667 5.667H19c.554 0 1-.446 1-1s-.446-1-1-1H5.667A3.667 3.667 0 0 1 2 15.65V6.317Z"
    />
  </svg>
);
export default SvgFolders;
