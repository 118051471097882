import React from 'react';
const SvgMinusWithCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M12 24c6.628 0 12-5.372 12-12S18.628 0 12 0 0 5.372 0 12s5.372 12 12 12ZM8.625 10.875h6.75c.623 0 1.125.502 1.125 1.125s-.502 1.125-1.125 1.125h-6.75A1.122 1.122 0 0 1 7.5 12c0-.623.502-1.125 1.125-1.125Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMinusWithCircle;
