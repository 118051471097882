import defaultColors from '!!sass-variable-loader!./_colors.scss';
import cloverColors from '!!sass-variable-loader!./clover/_colors.scss';

import getIsEmbedded from 'util/embedded';

const isEmbedded = getIsEmbedded();
const colors = isEmbedded ? cloverColors : defaultColors;

// Primary
export const purple = {
  100: colors.purple100,
  300: colors.purple300,
  400: colors.purple400,
  500: colors.purple500,
  700: colors.purple700,
  900: colors.purple900,
};

// Secondary (Deprecated as of 2024 Rebrand)
export const teal = {
  100: colors.teal100,
  200: colors.teal200,
  300: colors.teal300,
  500: colors.teal500,
};

export const accent = {
  100: colors.accent100,
  500: colors.accent500,
  900: colors.accent900,
};

export const highlight = {
  500: colors.highlight500,
};

// Mono
export const mono = {
  0: colors.mono0,
  100: colors.mono100,
  300: colors.mono300,
  400: colors.mono400,
  500: colors.mono500,
  700: colors.mono700,
  900: colors.mono900,
};

// Success
export const green = {
  100: colors.green100,
  300: colors.green300,
  500: colors.green500,
};

// Info
export const blue = {
  100: colors.blue100,
  300: colors.blue300,
  500: colors.blue500,
};

// Warning
export const orange = {
  100: colors.orange100,
  300: colors.orange300,
  500: colors.orange500,
};

// Alert
export const red = {
  100: colors.red100,
  300: colors.red300,
  500: colors.red500,
  900: colors.red900,
};

export default colors as Record<string, string>;
