import React from 'react';
const SvgMoreHorizontal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M6.222 12c0 1.655-1.393 3-3.11 3C1.391 15 0 13.655 0 12s1.393-3 3.111-3c1.718 0 3.111 1.345 3.111 3Zm8.89 0c0 1.655-1.395 3-3.112 3s-3.111-1.345-3.111-3S10.283 9 12 9c1.717 0 3.111 1.345 3.111 3Zm2.666 0c0-1.655 1.394-3 3.11-3C22.607 9 24 10.345 24 12s-1.394 3-3.111 3c-1.717 0-3.111-1.345-3.111-3Z"
    />
  </svg>
);
export default SvgMoreHorizontal;
