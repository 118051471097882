import React from 'react';
const SvgTimeOffApproved = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M7.5 0C8.33 0 9 .67 9 1.5V3h6V1.5c0-.83.67-1.5 1.5-1.5S18 .67 18 1.5V3h2.25a2.25 2.25 0 0 1 2.25 2.25V7.5h-21V5.25A2.25 2.25 0 0 1 3.75 3H6V1.5C6 .67 6.67 0 7.5 0Zm-6 9h21v12.75A2.25 2.25 0 0 1 20.25 24H3.75a2.25 2.25 0 0 1-2.25-2.25V9Zm15.422 5.297a1.125 1.125 0 0 0-1.59-1.59l-4.452 4.454-2.203-2.203a1.125 1.125 0 0 0-1.59 1.59l3 2.999c.441.44 1.154.44 1.59 0l5.245-5.25Z"
    />
  </svg>
);
export default SvgTimeOffApproved;
