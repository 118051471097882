import React from 'react';
const SvgChart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M3 25.5c0 .831.672 1.5 1.5 1.5h26c.831 0 1.5.669 1.5 1.5s-.669 1.5-1.5 1.5h-26A4.499 4.499 0 0 1 0 25.5v-22a1.5 1.5 0 0 1 3 0v22Zm18.006-6.494c-.531.638-1.481.638-2.069 0l-4.993-4.887-4.938 4.887c-.531.638-1.481.638-2.069 0-.58-.531-.58-1.481 0-2.069l6-6a1.47 1.47 0 0 1 1.007-.437c.456 0 .837.156 1.062.438L20 15.88l7.438-7.444c.587-.58 1.537-.58 2.068 0 .638.588.638 1.538 0 2.07l-8.5 8.5Z"
    />
  </svg>
);
export default SvgChart;
