import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCurrentCompanyPayrollPaymentAttemptsInfo } from 'selectors/session';

import { useHandleNsfModal } from 'features/payroll/hooks/useHandleNsfModal';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';
const { cx, cxEl } = cxHelpers('PayrollNsfBanner');

const PayrollNsfBanner = ({ payrollPaymentAttemptsInfoMap }) => {
  const payrollPaymentAttemptsInfo =
    payrollPaymentAttemptsInfoMap?.toJS() || {};

  const handleOnClick = useHandleNsfModal();

  if (payrollPaymentAttemptsInfo.show_nsf_banner) {
    return (
      <Box className={cx()}>
        <Box
          row
          h={44}
          tcenter
          vcenter
          hcenter
          bg="red"
          className={cxEl('text')}
        >
          <Text
            white
            i18n="payroll.nsf.dashboard.title"
            style={{
              fontWeight: 'bold',
              paddingRight: 20,
            }}
          />
          &nbsp;
          <Text
            white
            i18n="payroll.nsf.dashboard.text"
            i18nProps={{
              bankAccountMask: payrollPaymentAttemptsInfo.bank_account_mask,
              payday: payrollPaymentAttemptsInfo.payroll_run.payday,
            }}
            style={{
              paddingRight: 20,
            }}
          />
          &nbsp;
          <Button
            theme="link-white"
            onClick={handleOnClick}
            className={cxEl('button')}
            i18n={`payroll.nsf.dashboard.cta.${payrollPaymentAttemptsInfo.button_text}`}
          />
        </Box>
      </Box>
    );
  }

  return null;
};

PayrollNsfBanner.propTypes = {
  payrollPaymentAttemptsInfoMap: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    payrollPaymentAttemptsInfoMap:
      getCurrentCompanyPayrollPaymentAttemptsInfo(state),
  }),
  {}
)(PayrollNsfBanner);
