import { createSlice } from '@reduxjs/toolkit';

import { fetchTipManagerFee } from './thunk';

const slice = createSlice({
  name: 'stripeCheckoutDrawer',
  initialState: {
    isOpen: false,
    tipManagerFee: '',
    initialViewKey: 'Checkout',
  },
  reducers: {
    openStripeCheckoutDrawer: (state, { payload }) => {
      state.isOpen = true;
      state.initialViewKey = payload?.initialViewKey || 'Checkout';
    },
    closeStripeCheckoutDrawer: state => {
      state.isOpen = false;
    },
  },
  extraReducers: {
    [fetchTipManagerFee.fulfilled]: (state, { payload }) => {
      state.tipManagerFee = payload.prices[0]?.monthly_base_amount;
    },
  },
});
export const { openStripeCheckoutDrawer, closeStripeCheckoutDrawer } =
  slice.actions;
export default slice.reducer;
