import React from 'react';
const SvgMoney = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M0 12C0 5.372 5.372 0 12 0s12 5.372 12 12-5.372 12-12 12S0 18.628 0 12Zm12.975-5.737c0-.558-.455-1.013-1.012-1.013-.558 0-1.013.455-1.013 1.013v.754c-.286.066-.563.113-.82.267-.844.361-1.64 1.041-1.828 2.063-.197 1.083.271 2.058 1.186 2.644.665.426 1.546.675 2.254.876.103.028.169.056.319.09.558.15 1.144.266 1.617.632.549.375.286 1.04-.276 1.228-.418.211-1.055.281-1.852.164-.544-.084-1.088-.272-1.617-.45-.117-.037-.235-.08-.352-.117a1.01 1.01 0 0 0-1.28.642 1.01 1.01 0 0 0 .643 1.28c.66.216 1.326.436 2.006.586v.816c0 .557.455 1.012 1.013 1.012.557 0 1.012-.455 1.012-1.012v-.713c.417-.047.816-.14 1.181-.29.881-.352 1.608-1.041 1.805-2.091.197-1.088-.23-2.105-1.14-2.728-.698-.479-1.63-.741-2.362-.952a1.073 1.073 0 0 1-.328-.131c-.534-.103-1.092-.253-1.556-.549-.586-.375-.16-.918.356-1.143.582-.249 1.299-.305 1.917-.188.333.066.661.16.99.249.07.023.145.042.215.06.54.15 1.097-.164 1.247-.703.15-.539-.169-1.096-.708-1.289-.084.02-.173-.01-.258-.032-.45-.127-.904-.254-1.359-.31v-.665Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMoney;
