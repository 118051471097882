import React, { useCallback, useEffect, useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeElements } from '@stripe/stripe-js';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import RadioButton from 'fe-design-base/atoms/RadioButton';
import Text from 'fe-design-base/atoms/Text';
import Alert from 'fe-design-base/molecules/Alert';
import { List } from 'immutable';

import { cxHelpers } from 'util/className';

import { PaymentMethodModuleCreditCardPicker } from '../PaymentMethodModule/PaymentMethodModuleCreditCardPicker';

import { SUCCESS_QUERY_PARAM } from './constants';
import {
  subscribeToOnConfirmAndPay,
  unsubscribeFromOnConfirmAndPay,
} from './events';
import PriceSummary from './PriceSummary';
const { cx } = cxHelpers('PurchaseMethodModuleView');
const PaymentMethodOption = {
  newCard: 'newCard',
  existingCard: 'existingCard',
};

interface ConfirmPaymentParams {
  clientSecret: string;
  redirect: 'if_required' | 'always';
  elements?: StripeElements;
  confirmParams?: Record<string, unknown>;
}
export interface Price {
  amount: number;
  priceSystemId: string;
}
export interface PurchaseMethodModuleViewProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creditCards: List<any>;
  onSuccess: () => void;
  onLoading: (isLoading: boolean) => void;
  price: Price;
  salesTax: number;
}

export const PurchaseMethodModuleView = ({
  creditCards,
  onSuccess,
  onLoading,
  price,
  salesTax,
}: PurchaseMethodModuleViewProps) => {
  const hasCreditCards = creditCards.size > 0;
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    hasCreditCards ? creditCards.first().get('payment_method_id') : ''
  );
  const [currentPaymentMethodOption, setCurrentPaymentMethodOption] = useState(
    hasCreditCards
      ? PaymentMethodOption.existingCard
      : PaymentMethodOption.newCard
  );

  const handleOnChangePaymentMethodOption = useCallback(e => {
    setCurrentPaymentMethodOption(e.target.name);
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string>();
  const createProductSubscription = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }
    onLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { error: submitError } = await elements.submit();
    if (submitError) {
      onLoading(false);
      setErrorMessage(submitError.message);
      return;
    }

    try {
      const subscribeResults = await postJSON(
        '/biller/product_subscriptions/subscribe',
        {
          payment_method_id:
            currentPaymentMethodOption === PaymentMethodOption.existingCard
              ? selectedPaymentMethodId
              : null,
          price_system_id: price.priceSystemId,
        }
      );

      let params: ConfirmPaymentParams = {
        clientSecret: subscribeResults.client_secret,
        confirmParams: {
          return_url:
            window.location.href +
            `?${SUCCESS_QUERY_PARAM}=true&priceSystemId=${price.priceSystemId}` +
            `&subscriptionId=${subscribeResults.subscription_id}`,
        },
        redirect: 'if_required',
      };

      if (currentPaymentMethodOption === PaymentMethodOption.newCard) {
        params = { ...params, elements };
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { error } = await stripe.confirmPayment(params);

      if (error) {
        onLoading(false);
        setErrorMessage(error.message);
        return;
      }

      await postJSON('/biller/product_subscriptions/confirm', {
        price_system_id: price.priceSystemId,
        subscription_id: subscribeResults.subscription_id,
      });

      onSuccess();
    } catch {
      // TODO: Add the correct phone number
      const message =
        'Something went wrong on our side. Please contact xxx-xxx-xxx for help';
      setErrorMessage(message);
    } finally {
      onLoading(false);
    }
  }, [
    currentPaymentMethodOption,
    elements,
    onLoading,
    onSuccess,
    price.priceSystemId,
    selectedPaymentMethodId,
    stripe,
  ]);

  useEffect(() => {
    if (stripe && elements) {
      subscribeToOnConfirmAndPay(createProductSubscription);
    }

    return () => {
      unsubscribeFromOnConfirmAndPay(createProductSubscription);
    };
  }, [stripe, elements, createProductSubscription]);

  return (
    <Box className={cx()}>
      <Box mt={32} gap={24} column>
        {errorMessage && <Alert variant="error">{errorMessage}</Alert>}
        <Text
          variant="heading3"
          i18n="biller.purchase_method_module.payment_method"
        />
      </Box>
      {hasCreditCards && (
        <Box mt={24}>
          <Box row vtop maxw={340} gap={8}>
            <RadioButton
              uxElement="biller.payment_method_module.existing_card_radio_button"
              name={PaymentMethodOption.existingCard}
              onChange={handleOnChangePaymentMethodOption}
              checked={
                currentPaymentMethodOption === PaymentMethodOption.existingCard
              }
            />
            <Box pt={4} gap={18} column grow={1}>
              <Text
                variant="body"
                color="mono900"
                i18n="biller.change_payment_method.label_select_card"
              />
              {currentPaymentMethodOption ===
                PaymentMethodOption.existingCard && (
                <PaymentMethodModuleCreditCardPicker
                  creditCards={creditCards}
                  value={selectedPaymentMethodId}
                  onChange={setSelectedPaymentMethodId}
                />
              )}
            </Box>
          </Box>

          <Box row vcenter mt={18} gap={8}>
            <RadioButton
              uxElement="biller.payment_method_module.enter_new_credit_card_radio_button"
              name={PaymentMethodOption.newCard}
              onChange={handleOnChangePaymentMethodOption}
              checked={
                currentPaymentMethodOption === PaymentMethodOption.newCard
              }
            />
            <Text
              variant="body"
              color="mono900"
              i18n="biller.change_payment_method.link_enter_new"
            />
          </Box>
        </Box>
      )}
      {(!hasCreditCards ||
        currentPaymentMethodOption === PaymentMethodOption.newCard) && (
        <Box mt={24} className={cx()}>
          <PaymentElement />
        </Box>
      )}
      <PriceSummary salesTax={salesTax} price={price.amount} />
    </Box>
  );
};
