import { combineReducers } from '@reduxjs/toolkit';

import { payrollHistoryReducer as payrollHistory } from 'features/payroll/components/PayrollHistory/payrollHistorySlice';
import { payrollDashboardReducer as payrollDashboard } from 'features/payroll/DashboardView/payrollDashboardSlice';
import { payrollRunSummaryReducer as payrollRunSummary } from 'features/payroll/drawers/PayrollRunSummary/payrollRunSummarySlice';
import { payrollNsfReducer as payrollNsf } from 'features/payroll/NsfViews/PayrollNsfSlice';
import { payrollInfoReducer as payrollInfo } from 'features/payroll/PayrollInfo/PayrollInfoSlice';
import { payrollRunReducer as payrollRun } from 'features/payroll/PayrollRunView/payrollRunSlice';
import { reducer as linearFlow } from 'features/payroll/SelfServeSetupView/LinearFlow/slice';
import { payrollTeamSetupReducer as payrollTeamSetup } from 'features/payroll/SelfServeSetupView/TasksDashboard/Components/TeamSetup/teamSetupSlice';
import { payrollTaxReturnsReducer as payrollTaxReturns } from 'features/payroll/TaxReturnsView/payrollTaxReturnsSlice';

export const payrollReducer = combineReducers({
  payrollHistory,
  payrollTaxReturns,
  payrollDashboard,
  payrollRunSummary,
  payrollRun,
  payrollTeamSetup,
  payrollInfo,
  payrollNsf,
  linearFlow,
});
