import React from 'react';
const SvgEmail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M21.75 3a2.25 2.25 0 0 1 1.35 4.05L12.9 14.7a1.505 1.505 0 0 1-1.8 0L.9 7.05A2.25 2.25 0 0 1 2.25 3h19.5ZM10.2 15.9a2.995 2.995 0 0 0 3.6 0L24 8.25V18c0 1.655-1.345 3-3 3H3c-1.657 0-3-1.345-3-3V8.25l10.2 7.65Z"
    />
  </svg>
);
export default SvgEmail;
