import React from 'react';
const SvgHandshake = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="m12.127 5.595-3.63 2.94a1.457 1.457 0 0 0 1.812 2.284l3.724-2.895a.602.602 0 0 1 .738.948l-.784.608 6.646 6.12H22.2a1.8 1.8 0 0 0 1.8-1.8V9a1.8 1.8 0 0 0-1.8-1.8h-3.026l-.146-.094-2.723-1.744a3.579 3.579 0 0 0-4.178.233Zm.855 4.665-1.938 1.507a2.656 2.656 0 0 1-3.78-.532 2.659 2.659 0 0 1 .476-3.63l3.12-2.524a3.543 3.543 0 0 0-1.38-.277A3.549 3.549 0 0 0 7.5 5.4L4.8 7.2h-3A1.8 1.8 0 0 0 0 9v4.8a1.8 1.8 0 0 0 1.8 1.8h4.058l3.427 3.127a1.798 1.798 0 0 0 2.959-.888l.637.585a1.8 1.8 0 0 0 2.914-.728 1.801 1.801 0 0 0 2.22-2.824l-5.033-4.612Z"
    />
  </svg>
);
export default SvgHandshake;
