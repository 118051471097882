import { compact } from 'lodash';
import { createSelector } from 'reselect';

import { addDays, df, momentDate } from 'util/dateTime';

import { MODULE_NAME, SLICE_NAME, TIER_NAMES } from './constants';

export const selectBillerLocations = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'billerLocations']);

export const selectIsBillingModalSourceMle = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'isBillingModalSourceMle']);

export const selectIsBillingModalSourcePastDue = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'isBillingModalSourcePastDue']);

export const selectDecidedToStayOnBasic = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'decidedToStayOnBasic']);

export const selectPutSubscriptionFailingLocations = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'putSubscriptionFailingLocations']);

export const selectBillerLocationsForChangeTier = createSelector(
  selectBillerLocations,
  selectPutSubscriptionFailingLocations,
  (billerLocations, failingLocations) =>
    failingLocations?.size > 0
      ? billerLocations.filter(location =>
          failingLocations.includes(location.get('id'))
        )
      : billerLocations
);

export const selectUpdateTierPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'updateTierPending']);

export const selectPutSubscriptionErrors = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'putSubscriptionErrors']);

export const selectRequestPaymentMethodErrors = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'requestPaymentMethodErrors']);

export const selectChangeTierPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'changeTierPending']);

export const selectAdminAvailableTiers = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'adminTiers', 'availableTiers']);

export const selectAdminProducts = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'adminProducts']);

export const selectAvailableTiers = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'availableTiers']);

export const selectTierBasic = createSelector(selectAvailableTiers, tiers =>
  tiers.find(t => t.get('name') === TIER_NAMES.BASIC)
);

export const selectTierPlus = createSelector(selectAvailableTiers, tiers =>
  tiers.find(t => t.get('name') === TIER_NAMES.PLUS)
);

export const selectTierEssentials = createSelector(
  selectAvailableTiers,
  tiers => tiers.find(t => t.get('name') === TIER_NAMES.ESSENTIALS)
);

export const selectTierEnterprise = createSelector(
  selectAvailableTiers,
  tiers => tiers.find(t => t.get('name') === TIER_NAMES.ENTERPRISE)
);

export const selectAppliedDiscount = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'appliedDiscount']);

export const selectAppliedDiscountError = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'appliedDiscountError']);

export const selectAppliedDiscountPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'appliedDiscountPending']);

export const selectAddLocationsPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'addLocationsPending']);

export const selectUpdateTierError = createSelector(
  selectRequestPaymentMethodErrors,
  selectPutSubscriptionErrors,
  (requestPaymentMethodErrors, putSubscriptionErrors) =>
    compact([requestPaymentMethodErrors, putSubscriptionErrors]).join(', ')
);

export const selectCompanyId = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'companyId']);

export const selectLocationId = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'locationId']);

export const selectLocationBiller = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'locationBiller']);

export const selectNewLocationsPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'newLocationsPending']);

export const selectSalesTaxPending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'salesTaxPending']);

export const selectSalesTaxTotals = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'salesTax']);

export const selectSalesTaxErrors = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'salesTaxErrors']);

export const selectShowSelfLocationCreate = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'showSelfLocationCreate']);

export const selectShowUpgradeToAddLocation = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'showUpgradeToAddLocation']);

export const selectCompanyBillerName = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'companyBiller']);

export const selectHasMultipleLocations = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'multipleLocations']);

export const isBilledViaInvoicing = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'billedViaInvoicing']);

export const selectIsSubscribedAnnually = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'subscribedAnnually']);

export const selectCurrentLocationTier = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'locationCurrentTier']);

export const selectDecoupledDowngradePending = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'decoupledDowngradePending']);

export const selectSubmissionInProgress = createSelector(
  selectUpdateTierPending,
  selectDecoupledDowngradePending,
  (updateTierPending, downgradeTierPending) =>
    updateTierPending || downgradeTierPending
);

export const selectShowSubscriptionPastDueBanner = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'showSubscriptionPastDueBanner']);

export const selectShowSubscriptionPastDueWithoutBillingPermissionBanner =
  state =>
    state.getIn([
      MODULE_NAME,
      SLICE_NAME,
      'showSubscriptionPastDueWithoutBillingPermissionBanner',
    ]);

export const selectDaysLeftBeforeDowngrade = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'daysLeftBeforeDowngrade']);

// This is the tier name that the location had before being downgraded
export const selectPreviousTierName = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'previousTierName']);

export const selectPastDueGracePeriodDeadline = createSelector(
  selectDaysLeftBeforeDowngrade,
  daysLeftBeforeDowngrade => {
    const FORMAT = df('pretty_no_wday');

    const today = new Date();

    if (daysLeftBeforeDowngrade === 0) return momentDate(today, FORMAT);

    if (!daysLeftBeforeDowngrade) return null;

    return addDays(today, daysLeftBeforeDowngrade, FORMAT);
  }
);

export const selectShouldShowPastDueSubscriptionDialog = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'shouldShowPastDueSubscriptionDialog']);

export const selectDowngradedDueToGracePeriod = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'downgradedDueToGracePeriod']);

export const selectPreSetPastDueDialogInitialViewKey = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'preSetPastDueDialogInitialViewKey']);

export const selectShowCreditCardExpiryBanner = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'showCreditCardExpiryBanner']);

export const selectRemainingDaysOnCreditCard = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'remainingDaysOnCreditCard']);

export const selectLocationInTrial = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'locationInTrial']);

export const selectPastDueDeadline = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'pastDueDeadline']);

export const selectIsPastDueAfterDeadline = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'isPastDueAfterDeadline']);

export const selectCanViewPaymentMethodModule = state =>
  state.getIn([MODULE_NAME, SLICE_NAME, 'canViewPaymentMethodModule']);
