import React from 'react';
const SvgChat = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M12 1.5c-6.628 0-12 4.364-12 9.75 0 2.326 1.001 4.452 2.67 6.127-.585 2.36-2.543 4.465-2.567 4.489a.364.364 0 0 0-.07.41c.059.14.189.224.34.224 3.106 0 5.396-1.489 6.592-2.409C8.494 20.667 10.2 21 12 21c6.628 0 11.958-4.366 11.958-9.75 0-5.385-5.33-9.75-11.958-9.75Z"
    />
  </svg>
);
export default SvgChat;
