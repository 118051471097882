import React from 'react';
const SvgWatch = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M6 2.25h12A2.25 2.25 0 0 0 15.75 0h-7.5A2.25 2.25 0 0 0 6 2.25Zm.75 1.5A3.749 3.749 0 0 0 3 7.5v9a3.749 3.749 0 0 0 3.75 3.75h10.5A3.749 3.749 0 0 0 21 16.5v-9a3.749 3.749 0 0 0-3.75-3.75H6.75Zm6.375 3.75v4.036l1.922 1.922a1.125 1.125 0 0 1-1.59 1.59l-2.25-2.251A1.124 1.124 0 0 1 10.88 12V7.5c0-.623.501-1.125 1.125-1.125.623 0 1.125.502 1.125 1.125h-.005ZM8.25 24h7.5A2.25 2.25 0 0 0 18 21.75H6A2.25 2.25 0 0 0 8.25 24Z"
    />
  </svg>
);
export default SvgWatch;
