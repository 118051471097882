const getIsEmbedded = () => {
  const storybookElement = document.getElementById('storybook-preview-iframe');
  const storybookEnv =
    (typeof process !== 'undefined' && process.env.STORYBOOK) ?? false;

  if (storybookElement !== null || storybookEnv) {
    return false;
  }

  return window.self !== window.top;
};

export default getIsEmbedded;
