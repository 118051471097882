import React from 'react';
const SvgExternalLink = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M15 0c-.83 0-1.5.67-1.5 1.5S14.17 3 15 3h3.877L9.44 12.44a1.502 1.502 0 0 0 2.123 2.124L21 5.124V9c0 .83.67 1.5 1.5 1.5S24 9.83 24 9V1.5c0-.83-.67-1.5-1.5-1.5H15ZM3.75 1.5A3.749 3.749 0 0 0 0 5.25v15A3.749 3.749 0 0 0 3.75 24h15a3.749 3.749 0 0 0 3.75-3.75V15c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v5.25c0 .413-.337.75-.75.75h-15a.752.752 0 0 1-.75-.75v-15c0-.412.337-.75.75-.75H9c.83 0 1.5-.67 1.5-1.5S9.83 1.5 9 1.5H3.75Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgExternalLink;
