import './BottomDrawer.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { animated } from '@react-spring/web';
import PropTypes from 'prop-types';

import { actions } from 'features/bottomDrawers/slice';

import AnimatedBox from 'components/AnimatedBox';
import Box from 'components/Box';
import ProgressBar from 'components/ProgressBar';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUxHandler } from 'util/uxEvents';

import ActionsBar from './ActionsBar';
import FooterBar from './FooterBar';

const { cx, cxEl } = cxHelpers('BottomDrawer');
const { BOTTOM_DRAWER_VIEWED } = EVENT_ACTIONS;
const { VIEW } = TRACK_ACTION_TYPES;

export const BottomDrawer = ({
  children,
  multiStep,
  hideProgressBar,
  progressBarMode,
  actionsBarType,
  animations,
  onCancelClick,
  nextStepCallback,
  closeStepCallback,
  userCurrentStep,
  setUserCurrentStep,
  ctaTracking,
  headerTitle,
  footerButtonText,
  footerButtonType,
  footerSecondaryActionText,
  onClickFooterAction,
  onClickFooterSecondaryAction,
  footerActionIsLoading,
  headerComponent,
  headerComponentProps,
  footerComponent,
  footerComponentProps,
  top,
  noStep,
  headerContent,
  maxw,
  trackViewUx,
}) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);

  const Header = headerComponent || ActionsBar;
  const Footer = footerComponent || FooterBar;
  const userStepToUse = userCurrentStep || currentStep;
  const setUserStepToUse = setUserCurrentStep || setCurrentStep;

  // Only used when progressBarMode is set to 'dynamic',
  // so steps with branching paths can dynamically control the progress bar
  const [progress, setProgress] = useState('0%');

  const [nextStepButtonText, setNextStepButtonText] = useState(
    toI18n('bottom_drawer.next_step_cta')
  );
  const [previousStepButtonText, setPreviousStepButtonText] = useState(
    toI18n('bottom_drawer.previous_step_cta')
  );
  const [closeButtonText, setCloseButtonText] = useState(
    toI18n('bottom_drawer.close_drawer_cta')
  );

  const [onCloseClick, setOnCloseClick] = useState(() => undefined);
  const [onNextStepClick, setOnNextStepClick] = useState(() => undefined);
  const [onPreviousStepClick, setOnPreviousStepClick] = useState(
    () => undefined
  );

  const closeBottomDrawer = useCallback(() => {
    if (closeStepCallback) closeStepCallback();
    dispatch(actions.removeBottomDrawer());
  }, [closeStepCallback, dispatch]);

  const nextStep = useCallback(() => {
    setUserStepToUse(prevCount => prevCount + 1);
    if (nextStepCallback) nextStepCallback();
  }, [nextStepCallback, setUserStepToUse]);

  const previousStep = useCallback(() => {
    setUserStepToUse(prevCount => (prevCount > 0 ? prevCount - 1 : 0));
  }, [setUserStepToUse]);

  const goToStep = useCallback(
    step => () => {
      setUserStepToUse(step);
    },
    [setUserStepToUse]
  );

  // Callbacks accessible to child components
  const bottomDrawerHelpers = {
    currentStep: userStepToUse,
    closeBottomDrawer,
    nextStep,
    previousStep,
    goToStep,
    setProgress,
    setOnCloseClick,
    setOnNextStepClick,
    setOnPreviousStepClick,
    setNextStepButtonText,
    setPreviousStepButtonText,
    setCloseButtonText,
  };

  // Used for progress bar calculation
  const maxStep =
    !noStep && multiStep ? children(bottomDrawerHelpers).length : 1;

  const autoProgress = () => {
    let currentProgress = ((userStepToUse + 1) / maxStep) * 100;
    if (currentProgress >= 100) currentProgress = 95;

    return `${currentProgress}%`;
  };

  const progressValue = progressBarMode === 'auto' ? autoProgress() : progress;

  const handleBottomDrawerViewTracking = useTrackUxHandler(
    BOTTOM_DRAWER_VIEWED,
    VIEW
  );

  const wrapperStyle = {
    boxSizing: 'border-box',
    pointerEvents: animations.bgPointerEvents,
  };

  let content = children;

  if (!noStep) {
    content = multiStep
      ? children(bottomDrawerHelpers)[userStepToUse]
      : children(bottomDrawerHelpers);
  }

  useEffect(() => {
    if (!multiStep && trackViewUx) handleBottomDrawerViewTracking();
  }, [handleBottomDrawerViewTracking, multiStep, trackViewUx]);

  const cardStyles = useMemo(
    () => ({
      top: top ? `${top}px` : '10%',
      height: top ? `calc(100% - ${top}px)` : '90%',
    }),
    [top]
  );

  return (
    <animated.div
      className={cx()}
      style={wrapperStyle}
      data-testid="BottomDrawer"
    >
      <animated.div
        className={cxEl('BG')}
        style={{ opacity: animations.bgOpacity }}
      />

      <AnimatedBox
        column
        id="bottom_drawer_container"
        className={cxEl('Card')}
        style={{
          ...cardStyles,
          transform: animations.cardTranslateY.to(v => `translateY(${v})`),
        }}
        maxw={maxw}
      >
        {headerContent ? (
          <Box className={cxEl('HeaderContent')}>{headerContent}</Box>
        ) : (
          <Header
            type={actionsBarType}
            bottomDrawerHelpers={bottomDrawerHelpers}
            nextStepButtonText={nextStepButtonText}
            previousStepButtonText={previousStepButtonText}
            closeButtonText={closeButtonText}
            onNextStepClick={onNextStepClick}
            onPreviousStepClick={onPreviousStepClick}
            onCloseClick={onCloseClick}
            onCancelClick={onCancelClick}
            ctaTracking={ctaTracking}
            headerTitle={headerTitle}
            {...headerComponentProps}
          />
        )}

        {!noStep && multiStep && progressValue !== '0%' && !hideProgressBar && (
          <ProgressBar value={progressValue} height={4} variant="teal300" />
        )}
        <Box id="content_wrapper" className={cxEl('content_wrapper')}>
          {content}
        </Box>

        <Footer
          type={footerButtonType}
          buttonText={footerButtonText}
          secondaryActionText={footerSecondaryActionText}
          onClickSecondaryAction={onClickFooterSecondaryAction}
          onClickAction={onClickFooterAction}
          actionIsLoading={footerActionIsLoading}
          {...footerComponentProps}
        />
      </AnimatedBox>
    </animated.div>
  );
};

BottomDrawer.propTypes = {
  noStep: PropTypes.bool,
  trackViewUx: PropTypes.bool,
  multiStep: PropTypes.bool,
  hideProgressBar: PropTypes.bool,
  progressBarMode: PropTypes.oneOf(['auto', 'dynamic']),
  actionsBarType: PropTypes.oneOf(['nav', 'close']),
  animations: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onCancelClick: PropTypes.func,
  nextStepCallback: PropTypes.func,
  closeStepCallback: PropTypes.func,
  userCurrentStep: PropTypes.number,
  setUserCurrentStep: PropTypes.func,
  headerTitle: PropTypes.string,
  footerButtonText: PropTypes.string,
  footerButtonType: PropTypes.string,
  onClickFooterAction: PropTypes.func,
  footerActionIsLoading: PropTypes.bool,
  headerComponent: PropTypes.func,
  footerComponent: PropTypes.func,
  top: PropTypes.number,
  headerContent: PropTypes.node,
  maxw: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  footerSecondaryActionText: PropTypes.string,
  onClickFooterSecondaryAction: PropTypes.func,
};

BottomDrawer.defaultProps = {
  multiStep: false,
  actionsBarType: 'close',
  trackViewUx: true,

  // If you set this to 'dynamic' you must call setProgress() in each step to update
  progressBarMode: 'auto',

  // These are provided by Root.jsx to the contained drawer component
  animations: {
    cardTranslateY: { to: () => '0%' },
    bgOpacity: 1,
    bgPointerEvents: 'all',
  },
  maxw: '100%',
};

export default BottomDrawer;
