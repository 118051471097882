import { addDays, getNow } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import { SLICE_NAME } from './constants';

export const getSubmitPending = state =>
  state.getIn([SLICE_NAME, 'submitPending']);

export const getIsSuccessful = state => state.getIn([SLICE_NAME, 'success']);

export const getRetrialPaywallFooterCopy = feature => {
  const date = addDays(getNow(), 7, 'MMMM Do, YYYY');

  if (feature === 'document_management_retrial') {
    return toI18n('js.features_access.document_management_retrial_footer', {
      props: { date },
    });
  } else if (feature === 'threshold_shift_late_retrial') {
    return toI18n('js.features_access.threshold_shift_late_retrial_footer', {
      props: { date },
    });
  } else if (feature === 'manager_log_retrial') {
    return toI18n('js.features_access.manager_log_retrial_footer', {
      props: { date },
    });
  }
};
