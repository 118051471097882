import React from 'react';
const SvgUndo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M2.273 10.5h-.398A1.122 1.122 0 0 1 .75 9.375v-6a1.127 1.127 0 0 1 1.922-.797l1.95 1.95c4.106-4.055 10.72-4.04 14.803.047 4.102 4.101 4.102 10.748 0 14.85-4.102 4.101-10.748 4.101-14.85 0A1.502 1.502 0 0 1 6.698 17.3a7.5 7.5 0 0 0 10.608 0 7.5 7.5 0 0 0 0-10.607 7.5 7.5 0 0 0-10.56-.047l1.926 1.931a1.127 1.127 0 0 1-.797 1.922H2.273Z"
    />
  </svg>
);
export default SvgUndo;
