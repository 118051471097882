import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postJSON } from 'api/fetch';

export const updatePaymentResolution = createAsyncThunk(
  'updatePaymentResolution',
  (payload, { rejectWithValue }) =>
    postJSON('/payroll/nsf/update_resolution', payload).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const getPaymentWireDetails = createAsyncThunk(
  'getPaymentWireDetails',
  (payload, { rejectWithValue }) =>
    postJSON('/payroll/nsf/wire_details', payload).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

const payrollNsfSlice = createSlice({
  name: 'payrollNsf',
  initialState: {
    isUpdatingPaymentResolution: false,
    isFetchingWireDetails: false,
    wireDetails: {},
  },
  reducers: {
    setWireDetails: (state, action) => {
      state.wireDetails = action.payload;
    },
  },
  extraReducers: {
    [updatePaymentResolution.pending]: state => {
      state.isUpdatingPaymentResolution = true;
    },
    [updatePaymentResolution.fulfilled]: state => {
      state.isUpdatingPaymentResolution = false;
    },
    [updatePaymentResolution.rejected]: state => {
      state.isUpdatingPaymentResolution = false;
    },
    [getPaymentWireDetails.pending]: state => {
      state.isFetchingWireDetails = true;
    },
    [getPaymentWireDetails.fulfilled]: state => {
      state.isFetchingWireDetails = false;
    },
    [getPaymentWireDetails.rejected]: state => {
      state.isFetchingWireDetails = false;
    },
  },
});

// selectors
export const getWireDetails = state =>
  state.get('payroll').payrollNsf.wireDetails;
export const getIsUpdatingPaymentResolution = state =>
  state.get('payroll').payrollNsf.isUpdatingPaymentResolution;
export const getIsFetchingWireDetails = state =>
  state.get('payroll').payrollNsf.isFetchingWireDetails;

export const payrollNsfReducer = payrollNsfSlice.reducer;
export const payrollNsfActions = payrollNsfSlice.actions;
