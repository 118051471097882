import React from 'react';
const SvgMerge = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M0 4.5A1.5 1.5 0 0 1 1.5 3h5.281c.915 0 1.773.414 2.345 1.126L14.23 10.5h3.78V8.25c0-.455.272-.867.694-1.04.422-.174.905-.076 1.186.243l3.752 3.75c.479.44.479 1.153 0 1.552l-3.752 3.75c-.281.36-.764.46-1.186.286a1.123 1.123 0 0 1-.694-1.041V13.5h-3.78l-5.103 6.333A2.91 2.91 0 0 1 6.781 21h-5.28a1.5 1.5 0 1 1 0-3h5.28l4.803-6L6.78 6H1.5A1.5 1.5 0 0 1 0 4.5Z"
    />
  </svg>
);
export default SvgMerge;
