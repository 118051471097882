import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';

export const Header = ({ titleI18n, titleI18nProps, iconName, iconColor }) => (
  <Box row mt={56} vcenter>
    <Icon color={iconColor} iconName={iconName} size="xlarge" mr={24} />
    <Text
      variant="heading2"
      color="mono900"
      i18n={titleI18n}
      i18nProps={titleI18nProps}
    />
  </Box>
);

export default Header;
