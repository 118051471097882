import React from 'react';
const SvgBreak = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#Break_svg__a)">
      <path
        fill="currentColor"
        d="M4.125 0C3.502 0 3 .502 3 1.125c0 1.823 1.097 2.784 1.833 3.427l.051.046C5.648 5.264 6 5.62 6 6.375 6 6.998 6.502 7.5 7.125 7.5S8.25 6.998 8.25 6.375c0-1.823-1.097-2.784-1.833-3.427l-.051-.046C5.602 2.236 5.25 1.88 5.25 1.125 5.25.502 4.748 0 4.125 0ZM1.5 9C.67 9 0 9.67 0 10.5v9C0 21.984 2.016 24 4.5 24h9c2.484 0 4.5-2.016 4.5-4.5h.75A5.247 5.247 0 0 0 24 14.25 5.247 5.247 0 0 0 18.75 9H1.5ZM18 12h.75a2.25 2.25 0 0 1 0 4.5H18V12ZM10.5 1.125C10.5.502 9.998 0 9.375 0S8.25.502 8.25 1.125c0 1.823 1.097 2.784 1.833 3.427l.051.046c.764.666 1.116 1.022 1.116 1.777 0 .623.502 1.125 1.125 1.125S13.5 6.998 13.5 6.375c0-1.823-1.097-2.784-1.833-3.427l-.051-.046C10.852 2.236 10.5 1.88 10.5 1.125Z"
      />
    </g>
    <defs>
      <clipPath id="Break_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBreak;
