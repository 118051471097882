import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import MultiValueTag from 'fe-design-base/atoms/Select/MultiValueTag';
import Text from 'fe-design-base/atoms/Text';

import { isOptionType, shouldRenderPlaceholder } from './helpers';
import { OptionType, SelectOptionsType } from './Select';

interface SelectInputValueProps {
  selected: string | number | readonly (string | number)[];
  placeholder?: string;
  options: SelectOptionsType;
  startIcon?: string;
  multiple?: boolean;
  displayAsText?: boolean;
  maxWidth?: number;
  disabled?: boolean;
  onMultiValueRemove: (option: string | number) => void;
  cxEl: (className: string) => string;
}

export const SelectInputValue = ({
  selected,
  placeholder,
  startIcon,
  options,
  multiple,
  displayAsText,
  maxWidth,
  disabled,
  onMultiValueRemove,
  cxEl,
}: SelectInputValueProps) => {
  if (shouldRenderPlaceholder({ placeholder, selected })) {
    return (
      <Box vcenter>
        {startIcon && (
          <Icon
            iconName={startIcon}
            color="mono500"
            className={cxEl('StartIcon')}
            mr={8}
          />
        )}
        <Text color="mono500" variant="body">
          {placeholder}
        </Text>
      </Box>
    );
  }

  const renderSelectedTags = (selectedArray: readonly (string | number)[]) =>
    selectedArray.map(v => {
      const option = options.find(o => isOptionType(o) && o.value === v);
      if (!option || !isOptionType(option)) return null;

      return (
        <Box mr={4} pv={2} key={`${option.label}-${v}`}>
          <MultiValueTag
            onTagClick={onMultiValueRemove}
            disabled={disabled || option.disabled}
            label={option.label}
            value={v}
          />
        </Box>
      );
    });

  let selectedLabels = '';
  if (multiple && displayAsText && Array.isArray(selected)) {
    const filteredOptions = options.filter(
      o => isOptionType(o) && selected.includes(o.value)
    ) as OptionType[];
    selectedLabels = filteredOptions.map(o => o.label || '').join(',  ');
  }

  if (multiple && displayAsText) {
    return (
      <Box vcenter maxw={maxWidth || undefined}>
        {startIcon && (
          <Icon
            iconName={startIcon}
            color="mono500"
            className={cxEl('StartIcon')}
          />
        )}
        <Box ml={startIcon ? '8px' : null} ellipsis>
          {selectedLabels}
        </Box>
      </Box>
    );
  }

  const selectedOption = options.find(
    option => isOptionType(option) && option.value === selected
  );

  return (
    <Box
      vcenter
      maxw={displayAsText && maxWidth ? maxWidth : undefined}
      wrap
      p={multiple ? '4px' : undefined}
    >
      {Array.isArray(selected) ? (
        renderSelectedTags(selected)
      ) : (
        <Box vcenter pl={0}>
          {startIcon && <Icon iconName={startIcon} color="mono500" mr={8} />}
          <Box>{(selectedOption as OptionType)?.label}</Box>
        </Box>
      )}
    </Box>
  );
};
