import {
  DEFAULT_SHIFT_COLOR_V1,
  DEFAULT_SHIFT_COLOR_V2,
} from 'features/scheduleBuilder/constants';
import { DEFAULT_OPTION_STATE } from 'features/scheduleBuilder/ScheduleBuilderView/constants';

import { df, moment, monthFromDate } from 'util/dateTime';
import { getObjectFromLocalStorage } from 'util/localStorageWrapper';

export const getDefaultShiftColor = isColorMappingV1 =>
  isColorMappingV1 ? DEFAULT_SHIFT_COLOR_V1 : DEFAULT_SHIFT_COLOR_V2;
export const formatTimeOffHours = hours =>
  `${parseFloat(Number(hours).toFixed(2))} hrs`;

// ShiftNoteQuota is ID'd by the month
export const getShiftNoteQuotaId = date => monthFromDate(date).toString();

export const getInitialStateFromLS = localStorageKey =>
  getObjectFromLocalStorage(localStorageKey) === null
    ? DEFAULT_OPTION_STATE
    : getObjectFromLocalStorage(localStorageKey);

export const formatSelectedCommonShift = (options, selection) => {
  const selected = options.filter(option => option.id === selection)[0];
  const attributes = selected.attributes;

  const start = moment(attributes.startAt).format(df('time_long'));
  const end = moment(attributes.endAt).format(df('time_long'));

  return {
    start,
    end,
    ...attributes,
  };
};
