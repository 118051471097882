import './constants.scss';
import animations from '!!sass-variable-loader!./constants/_animations.scss';
import breakpoints from '!!sass-variable-loader!./constants/_breakpoints.scss';
import colors from '!!sass-variable-loader!./constants/_colors.scss';
import dimensions from '!!sass-variable-loader!./constants/_dimensions.scss';
import rails from '!!sass-variable-loader!./constants/_rails.scss';
import typography from '!!sass-variable-loader!./constants/_typography.scss';

// Can't import composites successfully, since they depend on other imports
const composites = {
  defaultBorder: `${dimensions.borderWidth} solid ${colors.grayMedium}`,
  lightMediumBorder: `${dimensions.borderWidth} solid ${colors.grayMediumLight}`,
  whiteBorder: `${dimensions.borderWidth} solid ${colors.white}`,
};

// Must be kept in sync with theme/base/_spacing.scss
const sizes = [
  'n',
  'xxxxl',
  'xxxl',
  'xxl',
  'xl',
  'l',
  'm',
  's',
  'xs',
  'xs',
  'xxs',
  'xxxs',
  'tiny',
  'a',
  '0',
  '1',
  '2',
  '4',
  '8',
  '12',
  '16',
  '20',
  '24',
  '32',
  '36',
  '48',
  '64',
];

const spacing = [
  'ma',
  'mt',
  'mr',
  'mb',
  'mv',
  'mh',
  'ml',
  'pa',
  'pv',
  'ph',
  'pt',
  'pr',
  'pb',
  'pl',
].reduce((acc, pre) => {
  sizes.forEach(size => acc.push(`${pre}${size}`));
  return acc;
}, []);

export {
  animations,
  breakpoints,
  colors,
  composites,
  dimensions,
  rails,
  typography,
  spacing,
};
