import React from 'react';
const SvgAddUser = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M8.4 12a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6Zm1.901 1.8H6.5A6.5 6.5 0 0 0 0 20.3a1.3 1.3 0 0 0 1.3 1.3h14.2a1.3 1.3 0 0 0 1.299-1.3 6.5 6.5 0 0 0-6.499-6.5ZM23.1 9.9h-1.8V8.1a.9.9 0 0 0-1.8 0v1.8h-1.8c-.495 0-.9.405-.9.9s.403.9.9.9h1.8v1.8a.9.9 0 0 0 1.8 0v-1.8h1.8c.499 0 .9-.4.9-.9 0-.498-.401-.9-.9-.9Z"
    />
  </svg>
);
export default SvgAddUser;
