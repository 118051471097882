import React from 'react';
const SvgClose = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M23.648 23.648a1.202 1.202 0 0 1-1.697 0L12 13.728l-9.953 9.92a1.202 1.202 0 0 1-1.697 0 1.193 1.193 0 0 1 0-1.691l9.955-9.92L.351 2.116a1.193 1.193 0 0 1 0-1.692 1.202 1.202 0 0 1 1.698 0L12 10.348 21.953.426a1.202 1.202 0 0 1 1.697 0 1.193 1.193 0 0 1 0 1.692l-9.954 9.92 9.952 9.92a1.184 1.184 0 0 1 0 1.69Z"
    />
  </svg>
);
export default SvgClose;
