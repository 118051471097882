import './ViewContainer.scss';

import React, { PureComponent } from 'react';

import cxHelpers from 'util/className';

@cxHelpers('ViewContainer')
export default class ViewContainer extends PureComponent {
  render() {
    return (
      <div className={this.cx()}>
        <div className={this.cxEl('inner')}>{this.props.children}</div>
      </div>
    );
  }
}
