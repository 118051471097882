import React from 'react';
const SvgRepair = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M16.5 15a7.498 7.498 0 0 0 7.21-9.572c-.146-.506-.77-.619-1.14-.248l-3.6 3.6a.753.753 0 0 1-.53.22h-2.69a.752.752 0 0 1-.75-.75V5.56c0-.197.08-.39.22-.53l3.6-3.6c.37-.37.253-.994-.248-1.14A7.498 7.498 0 0 0 9 7.5c0 .895.16 1.758.445 2.555L.933 18.567a3.183 3.183 0 0 0 4.5 4.5l8.512-8.512A7.45 7.45 0 0 0 16.5 15ZM3.75 21.375a1.122 1.122 0 0 1-1.125-1.125c0-.623.502-1.125 1.125-1.125s1.125.502 1.125 1.125-.502 1.125-1.125 1.125Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRepair;
