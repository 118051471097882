import React from 'react';
import Text from 'fe-design-base/atoms/Text';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PastDueDialogBox from '../components/PastDueDialogBox';

export const EnforcedLocationView = ({
  onClickPrimary,
  primaryCtaText,
  primaryUxElement,
  previousTierName,
}) => (
  <PastDueDialogBox>
    <Header
      titleI18n="past_due_subscription.dialog.enforced_location.title"
      iconColor="orange300"
      iconName="Warning"
    />
    <Text
      variant="body"
      color="mono900"
      i18n="past_due_subscription.dialog.enforced_location.description"
      i18nProps={{
        previousPlanName: previousTierName,
      }}
      asHTML
    />
    <Footer
      onClickPrimary={onClickPrimary}
      primaryCtaText={primaryCtaText}
      primaryUxElement={primaryUxElement}
    />
  </PastDueDialogBox>
);

export default EnforcedLocationView;
