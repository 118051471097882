import React from 'react';
const SvgRedo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M21.727 10.5h.398c.624 0 1.125-.502 1.125-1.125v-6a1.127 1.127 0 0 0-1.922-.797l-1.95 1.95C15.272.473 8.658.488 4.575 4.575c-4.101 4.101-4.101 10.748 0 14.85 4.102 4.101 10.749 4.101 14.85 0a1.502 1.502 0 0 0-2.123-2.124 7.5 7.5 0 0 1-10.608 0 7.5 7.5 0 0 1 0-10.607 7.499 7.499 0 0 1 10.56-.047l-1.926 1.931a1.127 1.127 0 0 0 .797 1.922h5.602Z"
    />
  </svg>
);
export default SvgRedo;
