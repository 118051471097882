import 'theme/base.scss';

import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { useIsEmbedded } from 'hooks/useIsEmbedded';
import PropTypes from 'prop-types';

import { getCurrentUserIsManagerial } from 'selectors/session';

import HeaderNav from 'features/nav/HeaderNav';
import NavView from 'features/nav/NavView';

import { Mobile, TabletAndBelow } from 'components/MediaQuery';

import { browserHistory } from 'util/router';

const Nav = ({ store }) => {
  const state = store.getState();
  const isEmbedded = useIsEmbedded();

  if (isEmbedded) {
    return null;
  }

  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <LastLocationProvider>
          {getCurrentUserIsManagerial(state) ? (
            <TabletAndBelow>
              {tablet => (
                <Mobile>
                  {mobile => <HeaderNav mobile={mobile} tablet={tablet} />}
                </Mobile>
              )}
            </TabletAndBelow>
          ) : (
            <NavView />
          )}
        </LastLocationProvider>
      </Router>
    </Provider>
  );
};

Nav.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Nav;
