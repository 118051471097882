import React from 'react';
const SvgSort = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M0 3.25C0 2.282.766 1.5 1.714 1.5h20.572c.948 0 1.714.782 1.714 1.75S23.234 5 22.286 5H1.714C.766 5 0 4.218 0 3.25ZM0 12c0-.968.766-1.75 1.714-1.75H15.43c.948 0 1.714.782 1.714 1.75s-.766 1.75-1.714 1.75H1.714C.766 13.75 0 12.968 0 12Zm10.286 8.75c0 .968-.766 1.75-1.715 1.75H1.714C.766 22.5 0 21.718 0 20.75S.766 19 1.714 19h6.857c.949 0 1.715.782 1.715 1.75Z"
    />
  </svg>
);
export default SvgSort;
