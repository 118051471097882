import React from 'react';
const SvgDashboardOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M0 2.571A2.571 2.571 0 0 1 2.571 0h5.143a2.572 2.572 0 0 1 2.572 2.571v8.572a2.572 2.572 0 0 1-2.572 2.571H2.571A2.572 2.572 0 0 1 0 11.143V2.57Zm2.571 8.572h5.143V2.57H2.571v8.572Zm11.143 1.714a2.572 2.572 0 0 1 2.572-2.571h5.143A2.572 2.572 0 0 1 24 12.857v8.572A2.572 2.572 0 0 1 21.429 24h-5.143a2.572 2.572 0 0 1-2.572-2.571v-8.572Zm2.572 8.572h5.143v-8.572h-5.143v8.572Zm-8.572-6A2.572 2.572 0 0 1 10.286 18v3.429A2.572 2.572 0 0 1 7.714 24H2.571A2.572 2.572 0 0 1 0 21.429V18a2.572 2.572 0 0 1 2.571-2.571h5.143Zm0 2.571H2.571v3.429h5.143V18Zm6-15.429A2.572 2.572 0 0 1 16.286 0h5.143A2.572 2.572 0 0 1 24 2.571V6a2.572 2.572 0 0 1-2.571 2.571h-5.143A2.572 2.572 0 0 1 13.714 6V2.571ZM16.286 6h5.143V2.571h-5.143V6Z"
    />
  </svg>
);
export default SvgDashboardOutline;
