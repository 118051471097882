import { createTheme, ThemeOptions } from '@mui/material';
import { autocompleteOverrides } from 'fe-design-base/styles/overrides/autocomplete';

import { alertOverrides } from './overrides/alert';
import { avatarOverrides } from './overrides/avatar';
import { badgeOverrides } from './overrides/badge';
import { breakpoints } from './overrides/breakpoints';
import { buttonOverrides } from './overrides/button';
import { checkboxOverrides } from './overrides/checkbox';
import { chipOverrides } from './overrides/chip';
import { dialogOverrides } from './overrides/dialog';
import { dropdownOverrides } from './overrides/dropdown';
import { essentials } from './overrides/essentials';
import { formControlLabelOverrides } from './overrides/formControlLabel';
import { formHelperTextOverrides } from './overrides/formHelperText';
import { inputFieldOverrides } from './overrides/inputField';
import { linkOverrides } from './overrides/link';
import { listOverrides } from './overrides/list';
import { loadersOverrides } from './overrides/loaders';
import { paginationItemOverrides } from './overrides/paginationItem';
import { radioOverrides } from './overrides/radio';
import { switchOverrides } from './overrides/switch';
import { tabsOverrides } from './overrides/tabs';
import { toastOverrides } from './overrides/toast';
import { palette } from './palette';
import { typography } from './typography';

const baseTheme: ThemeOptions = {
  palette,
  typography,
  breakpoints,
  components: {
    ...alertOverrides,
    ...avatarOverrides,
    ...autocompleteOverrides,
    ...badgeOverrides,
    ...buttonOverrides,
    ...checkboxOverrides,
    ...chipOverrides,
    ...dialogOverrides,
    ...dropdownOverrides,
    ...essentials,
    ...formControlLabelOverrides,
    ...formHelperTextOverrides,
    ...inputFieldOverrides,
    ...linkOverrides,
    ...listOverrides,
    ...loadersOverrides,
    ...paginationItemOverrides,
    ...radioOverrides,
    ...toastOverrides,
    ...switchOverrides,
    ...tabsOverrides,
  } as Record<string, any>,
};

const theme = createTheme(baseTheme, {});

export default theme;
