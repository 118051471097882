import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

export const getPaymentMethod = () => {
  if (window.stripeClient.selectedPaymentMethod) {
    return Promise.resolve({
      paymentMethodId: window.stripeClient.selectedPaymentMethod,
    });
  }

  if (!window.stripeClient.cardNumberElement || !window.stripeClient.instance) {
    throw new Error(
      'Attempted to access Stripe card element before it is ready.'
    );
  }

  if (window.Homebase && window.Homebase.env === 'test') {
    // this should allow feature specs to pass
    return Promise.resolve({
      paymentMethodId: 'pm_123',
      newPaymentMethod: true,
    });
  }

  return window.stripeClient.instance
    .createPaymentMethod({
      type: 'card',
      card: window.stripeClient.cardNumberElement,
      billing_details: window.stripeClient.billingDetails,
    })
    .then(({ paymentMethod, error }) => {
      // a payment method will only not be present if stripe returns a 4xx
      // this means the card was blocked and the user must try another one
      if (!paymentMethod) {
        trackUxEvent({
          productArea: PRODUCT_AREAS.BILLING_PAGE,
          eventCategory: EVENT_CATEGORIES.BILLING_MODAL,
          eventAction: EVENT_ACTIONS.ERROR_MESSAGE_SHOWN,
          properties: {
            error_msg: error.message,
          },
        });

        // this message is not final
        throw new Error(
          'This card could not be processed. Please try a different card.'
        );
      }
      return {
        paymentMethodId: paymentMethod.id,
        newPaymentMethod: true,
      };
    });
};
