import { createAsyncThunk } from '@reduxjs/toolkit';
import * as routes from 'api';
import { fetchJSON, postJSON } from 'api/fetch';

import { getActiveTipPolicyId } from 'selectors/timesheets';
export const fetchTipInsData = createAsyncThunk(
  'tipIns/fetchTipInsData',
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const tipPolicyId = getActiveTipPolicyId(state);
    const { startDate, endDate } = payload;

    if (!tipPolicyId || !startDate || !endDate) {
      return;
    }

    try {
      const response = await fetchJSON(
        routes.fetchTipInsData({ tipPolicyId, startDate, endDate })
      );
      return response;
    } catch (error) {
      const data = await error.response.json();
      return rejectWithValue(data);
    }
  }
);
export const fetchTipOutsData = createAsyncThunk(
  'tipOuts/fetchTipOutsData',
  async (payload, { getState, rejectWithValue }) => {
    const state = getState();
    const tipPolicyId = getActiveTipPolicyId(state);
    const { startDate, endDate } = payload;

    if (!tipPolicyId || !startDate || !endDate) {
      return;
    }

    try {
      const response = await postJSON(
        routes.fetchTipOutsData({ tipPolicyId, startDate, endDate })
      );
      return response;
    } catch (error) {
      const data = await error.response.json();
      return rejectWithValue(data);
    }
  }
);
