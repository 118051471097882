import React from 'react';
const SvgSmile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M12 24c6.628 0 12-5.372 12-12S18.628 0 12 0 0 5.372 0 12s5.372 12 12 12Zm-4.308-8.742c.84.97 2.274 1.992 4.308 1.992s3.469-1.022 4.308-1.992a.75.75 0 1 1 1.134.984c-1.045 1.2-2.86 2.508-5.442 2.508-2.583 0-4.397-1.308-5.442-2.508a.75.75 0 1 1 1.134-.984ZM9.77 9.75c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5Zm6 1.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSmile;
