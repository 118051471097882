import React from 'react';
const SvgMoneyOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M12.975 6.928c.455.056.91.183 1.36.31.084.023.173.051.257.032.54.193.858.75.708 1.29-.15.538-.708.852-1.247.702-.07-.018-.145-.037-.216-.06-.328-.09-.656-.183-.989-.249-.618-.117-1.336-.06-1.917.188-.515.225-.942.768-.356 1.143.464.296 1.022.446 1.556.549.108.07.22.103.328.131.732.211 1.664.473 2.363.952.91.623 1.336 1.64 1.139 2.728-.197 1.05-.924 1.739-1.805 2.09-.365.15-.764.244-1.181.291v.713c0 .557-.455 1.012-1.012 1.012a1.014 1.014 0 0 1-1.013-1.012v-.816c-.68-.15-1.345-.37-2.006-.586a1.01 1.01 0 0 1-.642-1.28c.173-.53.75-.82 1.28-.642.116.038.234.08.35.117.53.178 1.074.366 1.618.45.797.117 1.392.047 1.852-.164.562-.187.825-.853.276-1.228-.473-.366-1.06-.483-1.617-.633-.15-.033-.216-.06-.319-.089-.708-.201-1.589-.45-2.255-.876-.914-.586-1.382-1.561-1.185-2.644.187-1.022.984-1.702 1.828-2.063.257-.154.534-.201.82-.267v-.755c0-.557.455-1.054 1.013-1.054.557 0 1.012.497 1.012 1.054v.666ZM24 12c0 6.628-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0s12 5.372 12 12ZM12 2.25A9.749 9.749 0 0 0 2.25 12 9.749 9.749 0 0 0 12 21.75 9.749 9.749 0 0 0 21.75 12 9.749 9.749 0 0 0 12 2.25Z"
    />
  </svg>
);
export default SvgMoneyOutline;
