import React from 'react';
const SvgFilter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M.183 2.573A1.867 1.867 0 0 1 1.875 1.5h20.25a1.878 1.878 0 0 1 1.448 3.066L15 15.042V21c0 .567-.319 1.087-.83 1.34a1.498 1.498 0 0 1-1.57-.14l-3-2.25a1.49 1.49 0 0 1-.6-1.2v-3.708L.422 4.561a1.87 1.87 0 0 1-.24-1.988Z"
    />
  </svg>
);
export default SvgFilter;
