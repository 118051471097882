import React from 'react';
const SvgColorApple = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.325 23.448c-.404.932-.881 1.79-1.434 2.578-.754 1.075-1.371 1.819-1.847 2.232-.737.678-1.527 1.025-2.374 1.045-.607 0-1.34-.173-2.192-.523-.856-.35-1.642-.522-2.36-.522-.754 0-1.563.173-2.428.522-.866.35-1.564.533-2.097.551-.812.035-1.62-.322-2.427-1.073-.516-.45-1.16-1.22-1.932-2.311-.828-1.165-1.51-2.517-2.043-4.058-.571-1.664-.857-3.275-.857-4.835 0-1.787.386-3.329 1.16-4.62a6.802 6.802 0 0 1 2.428-2.457 6.533 6.533 0 0 1 3.283-.927c.645 0 1.49.2 2.54.591 1.047.393 1.72.593 2.014.593.22 0 .968-.233 2.234-.698 1.198-.43 2.208-.61 3.036-.54 2.244.182 3.93 1.066 5.05 2.66-2.006 1.215-2.999 2.918-2.979 5.103.018 1.701.635 3.117 1.848 4.241.55.522 1.164.926 1.847 1.212-.148.43-.304.841-.47 1.236ZM22.179 3.2c0 1.334-.487 2.58-1.458 3.732-1.172 1.37-2.59 2.162-4.127 2.037a4.15 4.15 0 0 1-.031-.506c0-1.28.557-2.65 1.547-3.77.494-.568 1.123-1.04 1.885-1.416.76-.37 1.48-.575 2.156-.61.02.178.028.356.028.533Z"
      fill="#C4C4C4"
    />
  </svg>
);
export default SvgColorApple;
