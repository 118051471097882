import React, { useCallback } from 'react';
import { IconButton } from '@mui/material';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import Icon, { IconName } from 'fe-design-base/atoms/Icon';
import Badge from 'fe-design-base/molecules/Badge';
import { BadgeProps } from 'fe-design-base/molecules/Badge/Badge';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

export const ICON_BUTTON_SIZES = ['small', 'medium'] as const;
type Size = (typeof ICON_BUTTON_SIZES)[number];

interface IconButtonProps {
  children?: React.ReactNode;
  dataTestId?: string;
  destructive?: boolean;
  disabled?: boolean;
  icon: IconName;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: Size;
  uxElement?: string;
  badgeProps?: Omit<BadgeProps, 'children'>;
}

const ButtonWithIcon = ({
  cx,
  dataTestId = 'FDBIconButton',
  destructive,
  disabled,
  id,
  onClick,
  size = 'medium',
  icon,
  uxElement,
  badgeProps,
}: IconButtonProps & CxProps) => {
  const trackUx = useTrackUx({
    element: uxElement,
    icon,
    size,
  } as any);
  const isSmall = size === 'small';

  const handleClick = useCallback(
    e => {
      if (uxElement) {
        trackUx(EVENT_ACTIONS.ICON_BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK);
      }
      onClick?.(e);
    },
    [onClick, trackUx, uxElement]
  );

  return (
    <IconButton
      id={id}
      className={cx({ destructive })}
      data-testid={dataTestId}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      size={size}
    >
      <Box
        w={isSmall ? '32px' : '36px'}
        h={isSmall ? '32px' : '36px'}
        relative
        flex
        alignItemsCenter
        justifyContentCenter
      >
        {badgeProps ? (
          <Badge
            text={badgeProps.text}
            disabled={disabled}
            variant={badgeProps.variant}
            borderColor={badgeProps.borderColor}
          >
            <Icon size={isSmall ? 'small' : 'medium'} iconName={icon} />
          </Badge>
        ) : (
          <Icon size={isSmall ? 'small' : 'medium'} iconName={icon} />
        )}
      </Box>
    </IconButton>
  );
};

export default withCx<IconButtonProps>('FDBIconButton')(ButtonWithIcon);
