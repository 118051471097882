import React from 'react';
const SvgUsersOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M8.4 12a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6Zm0-7.8c1.654 0 3 1.346 3 3 0 1.655-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3Zm1.901 9.6H6.5A6.499 6.499 0 0 0 0 20.3a1.3 1.3 0 0 0 1.3 1.3h14.199a1.3 1.3 0 0 0 1.301-1.3 6.5 6.5 0 0 0-6.499-6.5Zm-8.474 6a4.704 4.704 0 0 1 4.672-4.2H10.3c2.423 0 4.392 1.843 4.673 4.2H1.827Zm16.14-5.4h-2.77A7.596 7.596 0 0 1 18 20.3c0 .48-.142.922-.375 1.3H22.8c.664 0 1.2-.54 1.2-1.233 0-3.282-2.685-5.967-6.034-5.967ZM16.2 12c2.321 0 4.2-1.878 4.2-4.2 0-2.32-1.879-4.2-4.2-4.2a4.16 4.16 0 0 0-2.502.845c.432.829.702 1.758.702 2.755a5.95 5.95 0 0 1-1.185 3.552A4.186 4.186 0 0 0 16.2 12Z"
    />
  </svg>
);
export default SvgUsersOutline;
