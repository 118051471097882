import React from 'react';
const SvgReports = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M3 3c0-.83-.67-1.5-1.5-1.5S0 2.17 0 3v15.75a3.749 3.749 0 0 0 3.75 3.75H22.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H3.75a.752.752 0 0 1-.75-.75V3Zm17.578 10.078c.708.708 1.922.206 1.922-.797V7.125C22.5 6.502 21.998 6 21.375 6h-5.16c-1.004 0-1.506 1.214-.798 1.922l1.519 1.519-2.686 2.686-3.44-3.441a1.502 1.502 0 0 0-2.124 0l-3.75 3.75a1.502 1.502 0 0 0 2.123 2.123l2.691-2.686 3.44 3.441a1.502 1.502 0 0 0 2.124 0l3.75-3.75 1.514 1.514Z"
    />
  </svg>
);
export default SvgReports;
