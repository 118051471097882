import React from 'react';
const SvgUsers = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M13.2 7.2a4.8 4.8 0 1 1-9.6.001 4.8 4.8 0 0 1 9.6 0ZM0 20.487A6.685 6.685 0 0 1 6.686 13.8h3.428a6.685 6.685 0 0 1 6.686 6.687c0 .615-.499 1.113-1.114 1.113H1.114A1.114 1.114 0 0 1 0 20.487ZM22.849 21.6h-5.172c.203-.352.323-.76.323-1.2v-.3a7.482 7.482 0 0 0-2.617-5.692c.09-.004.176-.008.266-.008h2.302A6.048 6.048 0 0 1 24 20.45a1.15 1.15 0 0 1-1.151 1.15ZM16.2 12a4.191 4.191 0 0 1-2.974-1.233A5.97 5.97 0 0 0 14.4 7.2a5.96 5.96 0 0 0-.686-2.786A4.198 4.198 0 0 1 16.2 3.6c2.321 0 4.2 1.88 4.2 4.2 0 2.322-1.879 4.2-4.2 4.2Z"
    />
  </svg>
);
export default SvgUsers;
