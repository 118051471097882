import React from 'react';
const SvgColorPinterest = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.016 2.67C8.646 2.67 2.67 8.636 2.67 15.994c0 5.648 3.515 10.473 8.48 12.414-.121-1.053-.22-2.676.044-3.827.241-1.042 1.56-6.624 1.56-6.624s-.396-.8-.396-1.974c0-1.853 1.077-3.235 2.417-3.235 1.142 0 1.691.855 1.691 1.875 0 1.14-.725 2.851-1.11 4.441-.318 1.327.67 2.413 1.978 2.413 2.373 0 4.196-2.5 4.196-6.097 0-3.191-2.296-5.418-5.58-5.418-3.8 0-6.03 2.84-6.03 5.78 0 1.14.439 2.368.988 3.037.11.132.121.253.088.384-.099.417-.33 1.327-.373 1.514-.055.24-.198.296-.45.175-1.67-.779-2.714-3.202-2.714-5.165 0-4.2 3.054-8.06 8.82-8.06 4.625 0 8.228 3.29 8.228 7.698 0 4.595-2.9 8.29-6.92 8.29-1.351 0-2.626-.701-3.054-1.535l-.835 3.17c-.296 1.162-1.11 2.61-1.658 3.498 1.252.384 2.57.592 3.954.592 7.37 0 13.346-5.966 13.346-13.324.022-7.38-5.954-13.346-13.324-13.346Z"
      fill="#E60019"
    />
  </svg>
);
export default SvgColorPinterest;
