import React from 'react';
const SvgGift = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M7.125 0h.103c1.495 0 2.883.793 3.642 2.084L12 4.006l1.13-1.922A4.228 4.228 0 0 1 16.772 0h.103A4.125 4.125 0 0 1 21 4.125c0 .675-.164 1.27-.45 1.875h1.95c.83 0 1.5.67 1.5 1.5v3c0 .83-.67 1.5-1.5 1.5h-21A1.5 1.5 0 0 1 0 10.5v-3C0 6.67.672 6 1.5 6h1.95C3.162 5.395 3 4.8 3 4.125A4.125 4.125 0 0 1 7.125 0ZM8.93 3.224a1.978 1.978 0 0 0-1.702-.974h-.103a1.875 1.875 0 1 0 0 3.75h3.436L8.93 3.224Zm7.945-.974h-.103c-.699 0-1.345.37-1.702.974L13.44 6h3.435a1.875 1.875 0 1 0 0-3.75ZM1.5 13.5h9V24H3.75a2.25 2.25 0 0 1-2.25-2.25V13.5Zm12 10.5V13.5h9v8.25A2.25 2.25 0 0 1 20.25 24H13.5Z"
    />
  </svg>
);
export default SvgGift;
