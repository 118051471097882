import React from 'react';
const SvgDocument = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M3 3c0-1.655 1.345-3 3-3h7.5v6c0 .83.67 1.5 1.5 1.5h6V21c0 1.655-1.345 3-3 3H6c-1.655 0-3-1.345-3-3V3Zm18 3h-6V0l6 6Z"
    />
  </svg>
);
export default SvgDocument;
