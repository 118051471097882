import React from 'react';
const SvgPhone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="m23.962 18.14-1.09 4.726A1.457 1.457 0 0 1 21.446 24C9.619 24.001 0 14.381 0 2.555c0-.687.467-1.275 1.135-1.427L5.86.038a1.471 1.471 0 0 1 1.677.85l2.181 5.085c.255.6.083 1.297-.42 1.709L6.772 9.708a16.39 16.39 0 0 0 7.477 7.477l2.066-2.522a1.458 1.458 0 0 1 1.71-.42l5.086 2.18c.611.321 1.01 1.034.85 1.718Z"
    />
  </svg>
);
export default SvgPhone;
