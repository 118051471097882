import React from 'react';
const SvgOvertime = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M3.516 3.516 1.922 1.922C1.214 1.214 0 1.716 0 2.714v5.161C0 8.498.502 9 1.125 9h5.16c1.004 0 1.506-1.214.798-1.922L5.639 5.634A8.97 8.97 0 0 1 12 3c4.969 0 9 4.031 9 9s-4.031 9-9 9a8.953 8.953 0 0 1-5.142-1.613 1.503 1.503 0 0 0-2.09.37c-.479.68-.31 1.613.37 2.091A11.995 11.995 0 0 0 12 24c6.628 0 12-5.372 12-12S18.628 0 12 0a11.964 11.964 0 0 0-8.484 3.516ZM12 6c-.623 0-1.125.502-1.125 1.125V12c0 .3.117.586.328.797l3.375 3.375a1.125 1.125 0 0 0 1.59-1.59l-3.048-3.046V7.125c0-.623-.5-1.125-1.125-1.125H12Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgOvertime;
