import React from 'react';
const SvgAnnouncement = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M22.667 3C23.404 3 24 3.596 24 4.333v16a1.332 1.332 0 1 1-2.667 0v-16c0-.737.596-1.333 1.334-1.333Zm-20 6.596L20 4.333v16l-5.463-1.658a4.001 4.001 0 1 1-7.65-2.32l-4.22-1.284A1.335 1.335 0 0 1 0 15V9.667a1.335 1.335 0 0 1 2.667-.07Zm9.958 8.496-3.817-1.159a2 2 0 1 0 3.817 1.158Z"
    />
  </svg>
);
export default SvgAnnouncement;
