import { InitialViewKeyType } from './constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectStripeCheckoutDrawerIsOpen = (state: any): boolean =>
  state.get('monetization').stripeCheckoutDrawer.isOpen;

export const selectTipManagerFee = (state: any): number =>
  state.get('monetization').stripeCheckoutDrawer.tipManagerFee;

export const selectInitialViewKey = (state: any): InitialViewKeyType =>
  state.get('monetization').stripeCheckoutDrawer.initialViewKey;
