import React, { useCallback } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import { TabletAndBelow } from 'components/MediaQuery';
import Pill from 'components/Pill';
import Text from 'components/Text';

import { setOriginContextByUrl } from 'util/tracking';
import { TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

const SubMenu = ({ item, cxEl, sizes, collapseLeftNav, onClickFunction }) => {
  const active = item.get('active');
  const newBadge = item.get('new_badge');
  const url = item.get('url');
  const trackUxEvent = useTrackUx();

  const handleClick = useCallback(
    (mobile, e) => {
      e.preventDefault();

      setOriginContextByUrl(url);

      trackUxEvent(item.get('event_action'), TRACK_ACTION_TYPES.CLICK);

      window.Homebase.RailsReactBridge.navigateToReactView(
        e.currentTarget.getAttribute('href')
      );

      if (mobile) {
        collapseLeftNav();
      }
    },
    [collapseLeftNav, item, url, trackUxEvent]
  );

  return (
    <TabletAndBelow>
      {mobile => (
        <a
          href={item.get('url')}
          onClick={onClickFunction || partial(handleClick, mobile)}
          data-method={item.get('dataMethod')}
          rel={item.get('rel')}
        >
          <Box
            pointer
            tag="span"
            block
            pv={14}
            pl={sizes.leftPadding + (active ? 26 : 40)}
            pr={active ? 8 : 0}
            bg={active ? 'lilac' : null}
            bl={active ? '12px solid purple' : null}
            borderbox
            style={{ borderRadius: '0 20px 20px 0' }}
            className={cxEl('sub-menu', { active })}
            noWrap
          >
            <Text
              fs={sizes.subMenuFontSize}
              fw600={active}
              color={active ? 'purple' : 'neutral700'}
            >
              {item.get('name')}
            </Text>
            {newBadge && (
              <Pill
                color="blue"
                i18n="left_nav.new_badge"
                size="xs"
                inline
                ml8
                bradius="3px"
                pa4
                style={{ fontSize: '10px' }}
              />
            )}
          </Box>
        </a>
      )}
    </TabletAndBelow>
  );
};

SubMenu.propTypes = {
  item: ImmutablePropTypes.map.isRequired,
  cxEl: PropTypes.func.isRequired,
  sizes: PropTypes.shape({
    leftPadding: PropTypes.number,
    subMenuFontSize: PropTypes.number,
  }),
  collapseLeftNav: PropTypes.func,
  onClickFunction: PropTypes.func,
};

export default SubMenu;
