import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';

import { toI18n } from 'util/i18n';

import success_src from './confirmation_success.png';

interface SuccessProps {
  payPeriodStartDate: string;
  closeDrawer: () => void;
}

const Success: React.FC<SuccessProps> = ({
  payPeriodStartDate,
  closeDrawer,
}) => {
  const handleOnClick = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <Box mt={122}>
      <Box hcenter>
        <img src={success_src} style={{ width: 164, height: 164 }} />
      </Box>
      <Box gap={24} column mt={40}>
        <Text variant="heading2" i18n="checkout_drawer.success.title" />
        <Text
          variant="body"
          i18n="checkout_drawer.success.subtitle"
          i18nProps={{ payPeriodStartDate }}
          asHTML
        />
      </Box>
      <Box gap={16} mt={56} column>
        <Button
          variant="primary"
          linkTo="/settings/tip_manager"
          uxElement="checkout_drawer.link_to.tip_manager"
          onClick={handleOnClick}
        >
          {toI18n('checkout_drawer.success.cta.primary')}
        </Button>
        <Button
          variant="secondary"
          linkTo="/timesheets"
          uxElement="checkout_drawer_success_link_to_timesheets"
          onClick={handleOnClick}
        >
          {toI18n('checkout_drawer.success.cta.secondary')}
        </Button>
      </Box>
    </Box>
  );
};

export default Success;
