import React from 'react';
const SvgStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M15.45 8.25h7.425a1.125 1.125 0 0 1 .69 2.01l-6.132 4.773 2.39 7.5a1.123 1.123 0 0 1-1.762 1.228L12 19.05l-6.06 4.71a1.12 1.12 0 0 1-1.334.038 1.128 1.128 0 0 1-.428-1.265l2.39-7.5L.433 10.26a1.123 1.123 0 0 1 .691-2.011h7.43L10.927.784a1.126 1.126 0 0 1 2.147 0L15.45 8.25Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgStar;
