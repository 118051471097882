import { Map, Set } from 'immutable';
import { createSelector } from 'reselect';

import * as constants from 'features/teamView/constants';
import * as util from 'features/teamView/util';

import { filterUsersBySearchTerm, fullName } from 'util/user';

import * as entitiesSelectors from './entities';
import * as routeSelectors from './route';
import * as sessionSelectors from './session';

// # PURE SELECTORS
export const getTeamDataError = state =>
  state.getIn(['teamView', 'rosterData', 'error']);
export const getTeamDataIsLoading = state =>
  state.getIn(['teamView', 'rosterData', 'isLoading']);
export const getTeamDataPossibleArchiveReasons = state =>
  state.getIn(['teamView', 'rosterData', 'possibleArchiveReasons']);
export const getDepartments = state =>
  state.getIn(['teamView', 'rosterData', 'departments']);
export const getRoleNames = state =>
  state.getIn(['teamView', 'rosterData', 'roleNames']);

export const getRolesWages = state =>
  state.getIn(['teamView', 'rosterData', 'rolesWages']);
export const getShowSendPacketButton = state =>
  state.getIn(['teamView', 'rosterData', 'showSendPacketButton']);
export const getTeamDataHasRolesAccess = state =>
  state.getIn(['teamView', 'rosterData', 'hasRolesAccess']);
export const getNextEffectivePayday = state =>
  state.getIn(['teamView', 'rosterData', 'nextEffectivePayday']);
export const getInviteTeamIsLoading = state =>
  state.getIn(['teamView', 'inviteTeam', 'isLoading']);
export const getInviteTeamCurrentView = state =>
  state.getIn(['teamView', 'inviteTeam', 'currentView']);
export const getInviteTeamAudience = state =>
  state.getIn(['teamView', 'inviteTeam', 'audience']);

export const getTeamSearchFilter = (_, props) =>
  routeSelectors.getUrlQuery(_, props).q;
export const getTeamAccessLevelFilter = (_, props) =>
  routeSelectors.getUrlQuery(_, props).accessLevel;
export const getTeamRoleFilter = (_, props) =>
  routeSelectors.getUrlQuery(_, props).role;
export const getTeamStatusFilter = (_, props) =>
  routeSelectors.getUrlQuery(_, props).status;
export const getClassificationFilter = (_, props) =>
  routeSelectors.getUrlQuery(_, props).classification;
export const getTeamSortBy = (_, props) =>
  routeSelectors.getUrlQuery(_, props).sortBy;

export const getTeamLocationFilter = (_, props) =>
  parseInt(routeSelectors.getUrlQuery(_, props).location, 10) || undefined;

export const getResendEmployeeInviteIsLoading = (state, props) =>
  state
    .getIn(['teamView', 'resendEmployeeInvites', 'isLoadingIds'])
    .has(props.id);

export const getPendingJobIsLoading = (state, props) =>
  state
    .getIn(['teamView', 'resolvePendingJobs', 'isLoadingIds'])
    .has(props.jobId);

export const getShowRemoved = state =>
  state.getIn(['teamView', 'ui', 'showRemoved']);
export const getQueryParams = state =>
  state.getIn(['teamView', 'ui', 'queryParams']);

// # COMPOSITE SELECTORS
export const getLocations =
  entitiesSelectors.createLocationsForViewSelector('teamView');
export const getUsers = entitiesSelectors.createEntitiesForViewSelector(
  'users',
  'teamView'
);
export const getJobs = entitiesSelectors.createEntitiesForViewSelector(
  'jobs',
  'teamView'
);

export const getExistingUserForPendingJob = createSelector(
  getUsers,
  entitiesSelectors.getJobFromProps,
  (users, job) => {
    if (!job) {
      return false;
    }

    const existingUserId = job.get('existing_user_id');
    if (!existingUserId) {
      return false;
    }

    return users.get(existingUserId);
  }
);

export const getRosterUsers = createSelector(
  getUsers,
  getJobs,
  // Build list of all users including filter- and roster-specific data.
  // This is the base for all TeamRoster filtering.
  (users, jobs) =>
    users
      .map(user => {
        const data = {
          pending_jobs: Set(),
          statuses: Set(),
          archived: true,
        };

        data.jobs =
          user.get('jobs') &&
          user
            .get('jobs')
            .map(jobId => {
              const job = jobs.get(jobId.toString());

              if (!job.get('archived_at')) {
                data.archived = false;

                // Only trigger filters for unarchived jobs
                if (!job.get('wage_rate')) {
                  data.statuses = data.statuses.add(
                    constants.STATUS_OPTION_VALUES[0]
                  );
                }
                if (!job.get('role_name')) {
                  data.statuses = data.statuses.add(
                    constants.STATUS_OPTION_VALUES[2]
                  );
                }
              }

              if (job.get('pending')) {
                data.pending_jobs = data.pending_jobs.add(jobId);
              }

              return job;
            })
            .sortBy(job => job.get('location_name'));

        const email = user.get('email');
        const phone = user.get('phone');

        if (!email && !phone) {
          data.statuses = data.statuses.add(constants.STATUS_OPTION_VALUES[1]);
        }

        const inviteStatus = user.get('invite_status');
        const acceptedInvite = inviteStatus === 'accepted';

        data.account_status_data = Map({
          inviteStatus,
          acceptedInvite,
          canResendInvite:
            (phone || email) && !acceptedInvite && !data.archived,
          inviteSent: user.get('got_invite_recently'),
        });

        data.full_name = fullName(user);

        return user.merge(data);
      })
      .sortBy(user =>
        // Sort alphabetically for default list
        user.get('full_name').toLowerCase()
      )
      .toList()
);

const jobPluck = (user, key, showRemoved) => {
  let jobs = user.get('jobs');

  if (!showRemoved) {
    jobs = jobs.filter(j => !j.get('archived_at'));
  }

  return Set(jobs.map(j => j.get(key)));
};

const filterMatches = (userVals, filterVal, whitelist) => {
  // If no filter is applied, everything matches
  if (!filterVal) {
    return true;
  }

  // If an invalid filter is applied, everything matches
  if (whitelist && !whitelist.includes(filterVal)) {
    return true;
  }

  return typeof userVals !== 'object'
    ? userVals === filterVal
    : userVals.has(filterVal);
};

export const getFilterableRosterUsers = createSelector(
  getRosterUsers,
  getShowRemoved,
  (users, showRemoved) => {
    if (!showRemoved) {
      users = users.filter(user => !user.get('archived'));
    }

    return users.map(user =>
      user.set('rowHeight', util.calculateRowHeight(user, showRemoved))
    );
  }
);

export const getSearchableRosterUsers = createSelector(
  getFilterableRosterUsers, // risk because it returns an array
  getRoleNames,
  getRolesWages,
  getTeamAccessLevelFilter,
  getTeamRoleFilter,
  getTeamStatusFilter,
  getTeamLocationFilter,
  getShowRemoved,
  getClassificationFilter,
  (
    users,
    roleNames,
    rolesWages,
    accessLevel,
    role,
    status,
    location,
    showRemoved,
    classification
  ) =>
    users.filter(
      user =>
        filterMatches(
          jobPluck(user, 'level', showRemoved),
          accessLevel,
          constants.ACCESS_LEVEL_OPTION_VALUES
        ) &&
        filterMatches(
          jobPluck(user, 'role_name', showRemoved),
          role,
          roleNames
        ) &&
        filterMatches(
          user.get('statuses'),
          status,
          constants.STATUS_OPTION_VALUES
        ) &&
        filterMatches(jobPluck(user, 'location_id', showRemoved), location) &&
        filterMatches(user.get('tax_classification_text'), classification)
    )
);

export const getFilteredRosterUsers = createSelector(
  getSearchableRosterUsers,
  getTeamSearchFilter,
  filterUsersBySearchTerm
);

/** New Roster */
export const getSearchableNewRosterUsers = createSelector(
  getFilterableRosterUsers,
  getRoleNames,
  getRolesWages,
  getTeamAccessLevelFilter,
  getTeamRoleFilter,
  getTeamStatusFilter,
  getTeamLocationFilter,
  getShowRemoved,
  getClassificationFilter,
  (
    users,
    roleNames,
    rolesWages,
    accessLevel,
    role,
    status,
    location,
    showRemoved,
    classification
  ) =>
    users.filter(
      user =>
        filterMatches(
          jobPluckNewRoster(user, 'level', showRemoved, rolesWages),
          accessLevel,
          constants.ACCESS_LEVEL_OPTION_VALUES
        ) &&
        filterMatches(
          jobPluckNewRoster(user, 'role_name', showRemoved, rolesWages),
          role,
          roleNames
        ) &&
        filterMatches(
          user.get('statuses'),
          status,
          constants.STATUS_OPTION_VALUES
        ) &&
        filterMatches(
          jobPluckNewRoster(user, 'location_id', showRemoved, rolesWages),
          location
        ) &&
        filterMatches(user.get('tax_classification_text'), classification)
    )
);

export const getFilteredNewRosterUsers = createSelector(
  getSearchableNewRosterUsers,
  getTeamSearchFilter,
  getShowRemoved,
  getTeamSortBy,
  (users, searchTerm, showRemoved, sortBy) =>
    filterUsersBySearchTerm(users, searchTerm)
      ?.toJS()
      .map(user => {
        if (!showRemoved) {
          user.jobs = user.jobs.filter(job => !job.archived_at);
        }

        return user;
      })
      .sort((userA, userB) => {
        const nameA = userA[sortBy]?.toLowerCase();
        const nameB = userB[sortBy]?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
);

export const getFilteredRosterUsersCount = createSelector(
  getFilteredNewRosterUsers,
  users => users.length
);

export const getClassifications = createSelector(
  getFilterableRosterUsers,
  users =>
    [...new Set(users.map(user => user.get('tax_classification_text')))].sort()
);

const jobPluckNewRoster = (user, key, showRemoved, rolesWages) => {
  let jobs = user.get('jobs');
  let role_wages;

  if (!showRemoved) {
    jobs = jobs.filter(j => !j.get('archived_at'));
  }

  if (key === 'role_name' && rolesWages) {
    role_wages = rolesWages.filter(
      role_wage => role_wage.get('user_id') === user.get('id')
    );
    const combine_jobs = jobs.concat(role_wages);
    return Set(combine_jobs.map(j => j.get(key)));
  }

  return Set(jobs.map(j => j.get(key)));
};

export const getTeamDataLoaded = createSelector(
  entitiesSelectors.createLoadedSelector('users', 'TEAM_VIEW'),
  entitiesSelectors.createLoadedSelector('jobs', 'TEAM_VIEW'),
  entitiesSelectors.createLoadedSelector('locations', 'TEAM_VIEW'),
  (usersLoaded, jobsLoaded, locationsLoaded) =>
    usersLoaded && jobsLoaded && locationsLoaded
);

export const getTeamDataShowLoading = createSelector(
  getTeamDataLoaded,
  getTeamDataIsLoading,
  getTeamDataError,
  (loaded, isLoading, error) => (!loaded && !error) || isLoading
);

export const getShowEmployeeView = createSelector(
  sessionSelectors.getCurrentUserIsOwner,
  sessionSelectors.getCanManageJobs,
  (isOwner, canManageJobs) => !isOwner && !canManageJobs
);

const buildImportEmployeesSelector = key => state =>
  state.getIn(['teamView', 'importEmployees', key]);
const getEmployeesForImportIsFetching =
  buildImportEmployeesSelector('isFetching');

export const getEmployeesForImport = buildImportEmployeesSelector('employees');
export const getEmployeesForImportPartnerName =
  buildImportEmployeesSelector('partnerName');
export const getEmployeesForImportJobOptions =
  buildImportEmployeesSelector('jobOptions');
export const getEmployeesForImportMultiWage =
  buildImportEmployeesSelector('multiWage');
export const getEmployeesForImportIsImporting =
  buildImportEmployeesSelector('isImporting');
export const getEmployeesForImportJobs = buildImportEmployeesSelector('jobs');
export const getEmployeesForImportIsLoading = createSelector(
  getEmployeesForImport,
  getEmployeesForImportIsFetching,
  (employees, isFetching) => employees.size === 0 || isFetching
);

export const getEmployeesForImportApplyAllWages = (state, props) => {
  const employees = getEmployeesForImport(state, props).filter(
    e => e.get('existing_match') === props.existingMatch
  );

  return !employees.some(e =>
    e.get('wages').some(wage => !wage.get('apply_wage'))
  );
};
