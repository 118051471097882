import React from 'react';
const SvgMinus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M24 11.996a1.844 1.844 0 0 1-1.846 1.847H1.846A1.844 1.844 0 0 1 0 11.996c0-1.02.825-1.846 1.846-1.846h20.308c1.021 0 1.846.825 1.846 1.846Z"
    />
  </svg>
);
export default SvgMinus;
