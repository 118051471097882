import React from 'react';
import { IllustrationProps } from 'fe-design-base/assets/types';
import Box from 'fe-design-base/atoms/Box';

import shifts_calendar from './imageFiles/shifts_calendar.png';

const ShiftsCalendar = (props: IllustrationProps) => (
  <Box
    w={72}
    {...props}
    minw={72}
    className={`FDBIllustration${
      props?.className ? ` ${props.className}` : ''
    }`}
  >
    <img src={shifts_calendar} style={{ width: '100%' }} />
  </Box>
);
export default ShiftsCalendar;
