import React from 'react';
const SvgColorInstagram = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.01 5.068c3.566 0 3.988.016 5.39.078 1.303.058 2.007.277 2.476.459.62.24 1.068.531 1.532.995.47.469.756.911.996 1.531.182.47.401 1.178.459 2.475.062 1.406.078 1.828.078 5.386 0 3.563-.016 3.985-.078 5.387-.058 1.302-.277 2.005-.46 2.474a4.124 4.124 0 0 1-.995 1.532c-.469.469-.912.755-1.532.995-.47.182-1.178.4-2.476.458-1.407.063-1.83.078-5.39.078-3.565 0-3.987-.015-5.39-.078-1.302-.057-2.006-.276-2.475-.458a4.126 4.126 0 0 1-1.533-.995 4.101 4.101 0 0 1-.995-1.532c-.183-.469-.402-1.177-.459-2.474-.063-1.407-.078-1.829-.078-5.387 0-3.563.015-3.985.078-5.386.057-1.303.276-2.006.459-2.475.24-.62.531-1.068.995-1.531.47-.469.912-.756 1.533-.995.469-.183 1.178-.401 2.476-.459 1.402-.062 1.824-.078 5.39-.078Zm0-2.401c-3.622 0-4.076.015-5.499.078-1.418.062-2.392.292-3.237.62a6.513 6.513 0 0 0-2.366 1.542 6.534 6.534 0 0 0-1.543 2.36c-.328.849-.558 1.818-.62 3.235-.063 1.427-.079 1.88-.079 5.5 0 3.621.016 4.074.079 5.497.062 1.417.292 2.39.62 3.235.344.88.797 1.625 1.543 2.365a6.523 6.523 0 0 0 2.361 1.536c.85.329 1.82.558 3.237.62 1.423.063 1.876.078 5.499.078 3.623 0 4.076-.015 5.5-.078 1.417-.062 2.392-.291 3.236-.62a6.524 6.524 0 0 0 2.361-1.536 6.52 6.52 0 0 0 1.538-2.36c.328-.85.558-1.818.62-3.235.063-1.422.078-1.876.078-5.496 0-3.62-.015-4.074-.078-5.496-.062-1.417-.292-2.391-.62-3.235a6.246 6.246 0 0 0-1.527-2.37 6.524 6.524 0 0 0-2.361-1.537c-.85-.328-1.82-.557-3.237-.62-1.429-.068-1.882-.083-5.505-.083Z"
      fill="url(#ColorInstagram_svg__a)"
    />
    <path
      d="M16.01 9.152a6.854 6.854 0 0 0-6.854 6.85 6.854 6.854 0 0 0 13.709 0c0-3.781-3.07-6.85-6.855-6.85Zm0 11.294a4.446 4.446 0 0 1-4.446-4.443 4.446 4.446 0 0 1 4.446-4.444 4.446 4.446 0 0 1 4.446 4.444 4.446 4.446 0 0 1-4.446 4.443Z"
      fill="url(#ColorInstagram_svg__b)"
    />
    <path
      d="M24.736 8.882a1.6 1.6 0 1 1-1.6-1.6c.88 0 1.6.72 1.6 1.6Z"
      fill="url(#ColorInstagram_svg__c)"
    />
    <defs>
      <linearGradient
        id="ColorInstagram_svg__a"
        x1={16.002}
        y1={29.256}
        x2={16.002}
        y2={2.874}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9AC44" />
        <stop offset={0.3} stopColor="#EA595A" />
        <stop offset={0.6} stopColor="#E11D88" />
        <stop offset={1} stopColor="#822AE4" />
      </linearGradient>
      <linearGradient
        id="ColorInstagram_svg__b"
        x1={16.002}
        y1={41.626}
        x2={16.002}
        y2={-9.376}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3A945" />
        <stop offset={0.3} stopColor="#E4595B" />
        <stop offset={0.6} stopColor="#E41E8A" />
        <stop offset={1} stopColor="#8329E7" />
      </linearGradient>
      <linearGradient
        id="ColorInstagram_svg__c"
        x1={23.136}
        y1={28.333}
        x2={23.136}
        y2={2.803}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2A742" />
        <stop offset={0.3} stopColor="#E45657" />
        <stop offset={0.6} stopColor="#E21E89" />
        <stop offset={1} stopColor="#8129E3" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgColorInstagram;
