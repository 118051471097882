export const PAYMENT_METHODS = {
  direct_deposit: 'direct_deposit',
  manual: 'manual',
  skipped: 'skipped',
};
export const INPUT_HOURS_TYPES = { manual: 'manual', import: 'import' };

export const COLUMN_TYPE = {
  employee: 'employee',
};

export const COLUMNS = {
  EMPLOYEE: 'employee',
  COST_OF_PAYROLLS: 'cost_of_payroll',
  PAYMENT_METHOD: 'payment_method',
  GROSS: 'gross',
  WAGE_RATE: 'wage_rate',
};

export const PAYMENT_TYPES = {
  employee: 'items',
  contractor: 'contractor_payments',
};

export const HORIZONTAL_LEFT_HEADER_COLUMNS = [
  COLUMNS.EMPLOYEE,
  COLUMNS.WAGE_RATE,
];

export const CELL_PROPS = {
  employee: { minw: '10%', maxw: '10%' },
  wage_rate: { minw: '8%', maxw: '8%' },
  regular: { minw: '5%', maxw: '5%' },
  overtime: { minw: '5%', maxw: '5%' },
  double_overtime: { minw: '5%', maxw: '5%' },
  holiday_pay: { minw: '5%', maxw: '5%' },
  pto: { minw: '5%', maxw: '5%' },
  sick: { minw: '5%', maxw: '5%' },
  paid_holiday: { minw: '5%', maxw: '5%' },
  cash_tips: { basis: 0, grow: 2 },
  paycheck_tips: { basis: 0, grow: 2 },
  bonus: { basis: 0, grow: 2 },
  gross: { basis: 0, grow: 2 },
  payment_method: { basis: 0, grow: 3 },
};
