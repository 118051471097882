import * as schemas from 'api/schemas';
import { fromJS, List, Map, Set } from 'immutable';
import { snakeCase } from 'lodash';
import { normalize } from 'normalizr';

import { actionTypes as addTeamActionTypes } from 'actions/addTeam';
import { actionTypes as applicantMessengerActionTypes } from 'actions/applicantMessenger';
import { actionTypes as departmentsActionTypes } from 'actions/departments';
import { actionTypes as employeeViewActionTypes } from 'actions/employeeView';
import { updateLocation } from 'actions/entities';
import { actionTypes as hiringActionTypes } from 'actions/hiring';
import { actionTypes as addApplicantManuallyActionTypes } from 'actions/hiring/addApplicantManually';
import { actionTypes as dashboardWidgetActionTypes } from 'actions/hiring/dashboardWidget';
import { actionTypes as ptoPolicyActionTypes } from 'actions/ptoPolicy';
import { actionTypes as reportsActionTypes } from 'actions/reports';
import { actionTypes as sessionActionTypes } from 'actions/session';
import { actionTypes as settingsActionTypes } from 'actions/settings';
import { actionTypes as teamViewActionTypes } from 'actions/teamView';
import { actionTypes as tiersActionTypes } from 'actions/tiers';
import { actionTypes as timeOffActionTypes } from 'actions/timeOff';

import { actions as billerActions } from 'features/biller';

import { timestamp, unixTimestamp } from 'util/dateTime';
import { toAssociationId, toEntityId } from 'util/entities';
import { pick } from 'util/objectMethods';

const INITIAL_ENTITIES_STATE = Map({
  users: Map(),
  jobs: Map(),
  locations: Map(),
  jobRequests: Map(),
  jobRequestBoosts: Map(),
  applications: Map(),
  applicationAnswers: Map(),
  applicationConversations: Map(),
  applicationMessages: Map(),
  applicationMessagesWidget: Map(),
  applicants: Map(),
  companyProfiles: Map(),
  locationProfiles: Map(),
  channels: Map(),
  states: List(),
  messages: Map(),
  departments: Map(),
  timeOffs: Map(),
  ptoPolicies: Map(),
  standardizedRoles: List(),
  gmUserNames: List(),
  managerialUserNames: List(),
  loaded: Map({
    users: Map(),
    jobs: Map(),
    locations: Map(),
    states: Map(),
    jobRequests: Map(),
    applications: Map(),
    applicants: Map(),
    companyProfiles: Map(),
    locationProfiles: Map(),
    channels: Map(),
    departments: Map(),
    timeOffs: Map(),
    ptoPolicies: Map(),
    standardizedRoles: Map(),
  }),
});

const ARCHIVED_STATE = 'archived';

// Marks for which views a given entity may be shown. Applicable views are stored in a Set.
// For example, if a user was applicable only to the messenger and hiring views:
//
//  forViews: Set(['messenger', 'hiring'])
//
const markEntityForViews = (views, entity) =>
  entity.update('forViews', Set(), forViews => forViews.union(views));

const markEntitiesForViews =
  (...views) =>
  entities =>
    (entities || []).map(entity => markEntityForViews(views, entity));

// Removes associations between each entity in entities and the removed id. For example, if a Job
// was deleted, removeAssociation could be called on entities.users to remove all references to the
// the deleted Job's id by examining the 'jobs' association key.
const removeAssociation = (entities, associationKey, removedId) => {
  const removedAssociationId = toAssociationId(removedId);

  return entities.map(entity =>
    entity.update(associationKey, value =>
      value ? value.filter(id => id !== removedAssociationId) : value
    )
  );
};

const removeEntity = (state, entityType, id) =>
  state.get(entityType).delete(toEntityId(id));

// Updates entity data from a normalized server payload. Meant to be used when the server response
// doesn't represent the entirety of an updated entity's data.
const updateEntities = (state, entityType, payload) =>
  state.mergeDeepIn([entityType], fromJS(payload[entityType]));

const updateEntity = (state, entityType, entityId, attributes, forViews) => {
  let attrs = fromJS(attributes);

  if (forViews) {
    attrs = markEntityForViews(forViews, attrs);
  }

  return state.mergeDeepIn([entityType, toEntityId(entityId)], attrs);
};

const updateJobAttr = (state, jobId, attrKey, updater) =>
  state.updateIn(['jobs', toEntityId(jobId), attrKey], updater);

/**
 * Takes state, scope like 'teamView', keys like ['users', 'jobs']
 * Transforms 'teamView' + ['users', 'jobs'] into updates for merge with existing loaded state
 *  {
 *    users: Map({ teamView: <timestamp> }),
 *    jobs: Map({ teamView: <timestamp> }),
 *  }
 * @param {Map} state
 * @param {string} scope - Name of the scope, e.g. 'teamView'
 * @param {string[]} keys - List of entity keys, e.g. ['users', 'jobs']
 * @returns {Map} new state with new loaded state deeply merged into existing
 */
const updateLoaded = (state, scope, keys) =>
  state.update('loaded', loaded =>
    loaded.mergeDeep(
      keys.reduce(
        (memo, key) => memo.set(key, Map({ [scope]: timestamp() })),
        Map()
      )
    )
  );

/**
 * Resets the 'loaded' status for all members in `entities` to invalidate these collections
 * @param {Map} state
 * @param {string[]} entities - List of entity keys, e.g. ['users', 'jobs']
 * @returns {Map} new state with empty loaded state for given `entities`
 */
const resetLoaded = (state, entities) =>
  state.update('loaded', loaded =>
    loaded.merge(
      entities.reduce(
        (memo, entity) =>
          memo.set(entity, INITIAL_ENTITIES_STATE.getIn(['loaded', entity])),
        Map()
      )
    )
  );

const userActiveLocationIds = (state, userId) =>
  state.getIn(['users', toEntityId(userId), 'active_location_ids']) || List();

const markEntitiesForTeamPage = markEntitiesForViews(
  'teamView',
  'employeeView'
);
const markEntitiesForApplicationConversations = markEntitiesForViews(
  'drawerApplicationConversations'
);
const markEntitiesForSettings = markEntitiesForViews('settings');
const markEntitiesForHiring = markEntitiesForViews('hiring');
const markEntitiesForManageApplicants =
  markEntitiesForViews('manageApplicants');
const markEntityForApplicantPanel = entity =>
  markEntityForViews(['manageApplicants', 'applicantPanel'], entity);
const markEntitiesForReports = markEntitiesForViews('reports');
const markEntitiesForTiers = markEntitiesForViews('tiers');
const markEntitiesForDepartments = markEntitiesForViews('departments');
const markEntitiesForTimeOff = markEntitiesForViews('timeOff');
const markEntityForTimeOff = entity => markEntityForViews(['timeOff'], entity);
const markEntitiesForPTOPolicy = markEntitiesForViews('ptoPolicy');
const markEntityForPTOPolicy = entity =>
  markEntityForViews(['ptoPolicy'], entity);
const markEntitiesForHiringWidget = markEntitiesForViews('hiringWidget');

function updateUserInfo(state, action) {
  const { entities } = normalize(action.payload, schemas.userSchema);

  const users = markEntitiesForTeamPage(fromJS(entities.users));
  const jobs = markEntitiesForTeamPage(fromJS(entities.jobs));

  const newState = state.mergeIn(
    ['users', action.payload.id.toString()],
    users.get(action.payload.id.toString())
  );

  if (entities.jobs) {
    return Object.keys(entities.jobs).reduce(
      (memo, id) =>
        memo.mergeIn(['jobs', id.toString()], jobs.get(id.toString())),
      newState
    );
  }

  return newState;
}

export default (state = INITIAL_ENTITIES_STATE, action) => {
  switch (action.type) {
    case sessionActionTypes.UPDATE_SESSION: {
      const newState = state.merge({
        standardizedRoles: fromJS(action.payload.standardizedRoles),
      });

      if (!action.payload.currentLocation) {
        return newState;
      }

      return newState.setIn(
        ['locations', toEntityId(action.payload.currentLocation.id)],
        fromJS(action.payload.currentLocation)
      );
    }

    case teamViewActionTypes.ROSTER_DATA_SUCCESS: {
      const { entities } = normalize(action.payload, {
        users: [schemas.userSchema],
        locations: [schemas.locationSchema],
      });

      const newState = updateLoaded(state, 'TEAM_VIEW', [
        'users',
        'jobs',
        'locations',
      ]);

      return newState.mergeDeep({
        users: markEntitiesForTeamPage(fromJS(entities.users)),
        jobs: markEntitiesForTeamPage(fromJS(entities.jobs)),
        locations: markEntitiesForTeamPage(fromJS(entities.locations)),
      });
    }

    case employeeViewActionTypes.UPDATE_USER_INFO: {
      return updateUserInfo(state, action);
    }

    case employeeViewActionTypes.FETCH_EMPLOYEE_SUCCESS:
    case employeeViewActionTypes.FETCH_EMPLOYEE_TAB_SUCCESS:
    case addTeamActionTypes.CREATE_EMPLOYEE_SUCCESS: {
      if (action.meta && action.meta.reset) {
        return updateUserInfo(state, action);
      }

      const { entities } = normalize(action.payload, schemas.userSchema);

      return state.mergeDeep({
        users: markEntitiesForTeamPage(fromJS(entities.users)),
        jobs: markEntitiesForTeamPage(fromJS(entities.jobs)),
      });
    }

    case settingsActionTypes.IMPORT_PARTNER_LOCATIONS_SUCCESS:
    case settingsActionTypes.FETCH_LOCATIONS_DATA_SUCCESS:
    case settingsActionTypes.FETCH_USER_SETTINGS_DATA_SUCCESS: {
      let newState = updateLoaded(state, 'SETTINGS', ['locations']);

      if (
        action.type !== settingsActionTypes.FETCH_USER_SETTINGS_DATA_SUCCESS
      ) {
        const { entities } = normalize(action.payload, [
          schemas.locationSchema,
        ]);
        newState = newState.mergeDeep({
          locations: markEntitiesForSettings(fromJS(entities.locations)),
        });

        if (action.type === settingsActionTypes.FETCH_LOCATIONS_DATA_SUCCESS) {
          const payload = action.payload[0];

          newState = state.setIn(['gmUserNames'], payload.gm_user_names);
          newState = newState.setIn(
            ['managerialUserNames'],
            payload.managerial_user_names
          );
          newState = newState.mergeDeep({
            locations: markEntitiesForSettings(fromJS(entities.locations)),
          });
        }
      }

      return newState;
    }

    case settingsActionTypes.FETCH_SETTINGS_PAYROLL_ADMINS_DATA_SUCCESS: {
      const { location_id: locationId, payroll_admins: payrollAdmins } =
        action.payload;

      return state.setIn(
        ['locations', toEntityId(locationId), 'payroll_admins'],
        fromJS(payrollAdmins)
      );
    }

    case settingsActionTypes.UPDATE_LOCATION_SETTINGS_SUCCESS: {
      let newState = updateLoaded(state, 'SETTINGS', ['locations']);

      if (
        action.type !== settingsActionTypes.FETCH_USER_SETTINGS_DATA_SUCCESS
      ) {
        const { entities } = normalize(
          [action.payload],
          [schemas.locationSchema]
        );

        newState = newState.mergeDeep({
          locations: markEntitiesForSettings(fromJS(entities.locations)),
        });
      }

      return newState;
    }

    case settingsActionTypes.UPDATE_POS_PARTNER: {
      const { locationId, partner } = action.payload;

      return updateEntity(state, 'locations', locationId, {
        partner_key: partner,
      });
    }

    case settingsActionTypes.UPDATE_PAYROLL_PARTNER: {
      const { locationId, partner } = action.payload;

      return updateEntity(state, 'locations', locationId, {
        payroll_partner_name: partner.get('name'),
        payroll_provider: {
          id: partner.get('id'),
          key: partner.get('key'),
          name: partner.get('name'),
        },
      });
    }

    case teamViewActionTypes.RESEND_EMPLOYEE_INVITE_SUCCESS: {
      const { entities } = normalize(action.payload, {
        user: schemas.userSchema,
      });
      return updateEntities(state, 'users', entities);
    }

    case teamViewActionTypes.APPROVE_PENDING_JOB_SUCCESS: {
      const {
        meta: { jobId, existingUserId },
      } = action;
      return state.merge({
        jobs: state
          .get('jobs')
          .update(toEntityId(jobId), job => job?.set?.('pending', false)),
        users: existingUserId
          ? removeEntity(state, 'users', existingUserId)
          : state.get('users'),
      });
    }

    case teamViewActionTypes.REMOVE_MATCHED_EMPLOYEE: {
      const { id } = action.payload;

      return state.merge({
        users: removeEntity(state, 'users', id),
      });
    }

    case teamViewActionTypes.DECLINE_PENDING_JOB_SUCCESS:
      return state.merge({
        jobs: state.get('jobs').delete(toEntityId(action.meta.jobId)),
        users: removeAssociation(state.get('users'), 'jobs', action.meta.jobId),
      });

    case employeeViewActionTypes.EMPLOYEE_FORM_SUCCESS: {
      const { entities } = normalize(action.payload, schemas.userSchema);

      const newState = state.mergeDeep({
        users: markEntitiesForTeamPage(fromJS(entities.users)),
        jobs: markEntitiesForTeamPage(fromJS(entities.jobs)),
      });

      return action.payload.jobs.reduce(
        (memo, job) =>
          memo
            .setIn(
              ['jobs', job.id.toString(), 'role_wages'],
              fromJS(job.role_wages)
            )
            .setIn(
              ['jobs', job.id.toString(), 'auto_scheduling_roles'],
              fromJS(job.auto_scheduling_roles)
            ),
        newState
      );
    }

    case employeeViewActionTypes.UPLOAD_DOCUMENT_SUCCESS: {
      if (action.meta.employeeProfile) {
        return updateUserInfo(state, action);
      }

      return state.updateIn(
        ['users', toEntityId(action.payload.user_id)],
        user =>
          user.update('onboarding_documents', documents =>
            documents.unshift(fromJS(action.payload))
          )
      );
    }

    case employeeViewActionTypes.SIGN_I9_SUCCESS: {
      const signedDocument = action.payload.document;
      return state.updateIn(
        ['users', toEntityId(signedDocument.user_id)],
        user =>
          user &&
          user.update('onboarding_documents', documents => {
            // if no onboarding documents are in the store, we do not need
            // to update anything
            if (documents) {
              const documentIndex = documents.findIndex(
                document => document.get('id') === signedDocument.id
              );
              return documents.set(documentIndex, fromJS(signedDocument));
            }
          })
      );
    }

    case employeeViewActionTypes.REMOVE_USER: {
      return state.merge({
        users: removeEntity(state, 'users', action.payload.userId),
      });
    }

    case employeeViewActionTypes.TERMINATION_FORM_SUCCESS: {
      let newState;
      let activeLocationIds;

      if (action.meta.allLocations) {
        newState = updateEntities(state, 'jobs', {
          jobs: action.payload.archived_jobs,
        });
        activeLocationIds = new List();

        // Update payroll admins
        newState = Object.keys(
          action.payload.payroll_admins_by_location
        ).reduce(
          (memo, locationId) =>
            memo.setIn(
              ['locations', locationId.toString(), 'payroll_admins'],
              fromJS(action.payload.payroll_admins_by_location[locationId])
            ),
          newState
        );
      } else {
        newState = updateEntity(
          state,
          'jobs',
          action.meta.resourceId,
          action.payload.job
        );
        activeLocationIds = userActiveLocationIds(state, action.meta.userId);
        activeLocationIds = activeLocationIds.delete(
          activeLocationIds.indexOf(action.meta.locationId)
        );
      }

      newState = newState.updateIn(
        ['users', toEntityId(action.meta.userId)],
        user => user.merge({ active_location_ids: activeLocationIds })
      );

      const allJobsArchived = newState
        .getIn(['users', toEntityId(action.meta.userId), 'jobs'])
        .every(
          jobId =>
            newState.getIn(['jobs', toEntityId(jobId), 'archived_at']) !== null
        );

      if (allJobsArchived) {
        return newState.updateIn(
          ['users', toEntityId(action.meta.userId)],
          user =>
            user.merge({
              can_terminate: false,
              terminated: true,
              terminated_at: action.payload.archived_at,
            })
        );
      }

      return newState;
    }

    case employeeViewActionTypes.UNARCHIVE_JOB_SUCCESS: {
      if (action.meta.oldProfile) {
        const locationId = state.getIn([
          'jobs',
          toEntityId(action.payload.id),
          'location_id',
        ]);
        const activeLocationIds = userActiveLocationIds(
          state,
          action.meta.userId
        ).push(locationId);
        const newState = state.updateIn(
          ['users', toEntityId(action.meta.userId)],
          user =>
            user.merge({
              can_terminate: true,
              terminated: false,
              active_location_ids: activeLocationIds,
            })
        );

        return updateEntity(
          newState,
          'jobs',
          action.payload.id,
          action.payload
        );
      }

      // merge this event with UPDATE_USER_INFO once we remove the old profile
      const { entities } = normalize(action.payload, schemas.userSchema);

      const users = markEntitiesForTeamPage(fromJS(entities.users));
      const jobs = markEntitiesForTeamPage(fromJS(entities.jobs));

      const newState = state.mergeIn(
        ['users', action.payload.id.toString()],
        users.get(action.payload.id.toString())
      );

      if (entities.jobs) {
        return Object.keys(entities.jobs).reduce(
          (memo, id) =>
            memo.mergeIn(['jobs', id.toString()], jobs.get(id.toString())),
          newState
        );
      }

      return newState;
    }

    case employeeViewActionTypes.CREATE_JOB_NOTE_SUCCESS:
      return updateJobAttr(state, action.meta.jobId, 'notes', notes =>
        (notes || List()).unshift(fromJS(action.payload))
      );

    case employeeViewActionTypes.UPDATE_JOB_NOTE_SUCCESS:
      return updateJobAttr(state, action.meta.jobId, 'notes', notes => {
        const updatedNoteIndex = notes.findIndex(
          note => note.get('id') === action.meta.noteId
        );
        return notes.updateIn([updatedNoteIndex], note =>
          note.merge({ body: action.meta.body })
        );
      });

    case employeeViewActionTypes.DELETE_JOB_NOTE_SUCCESS:
      return updateJobAttr(state, action.meta.jobId, 'notes', notes => {
        const deletedNoteIndex = notes.findIndex(
          note => note.get('id') === action.meta.noteId
        );
        return notes.delete(deletedNoteIndex);
      });

    case employeeViewActionTypes.DELETE_MANAGER_NOTE_SUCCESS: {
      const notes = state.getIn([
        'users',
        toEntityId(action.meta.userId),
        'notes',
      ]);

      const deletedNoteIndex = notes.findIndex(
        note => note.get('id') === action.meta.noteId
      );

      return state.deleteIn([
        'users',
        toEntityId(action.meta.userId),
        'notes',
        deletedNoteIndex,
      ]);
    }

    case employeeViewActionTypes.DELETE_HISTORICAL_WAGES_FOR_JOB_SUCCESS:
      return updateJobAttr(state, action.meta.jobId, 'historical_wages', () =>
        List()
      );

    case settingsActionTypes.SAVE_LOCATION_SETTINGS_SUCCESS:
    case updateLocation.fulfilled.type:
      if (action.meta.arg?.start_of_week >= 0) {
        setTimeout(() =>
          window.Homebase.ScheduleBuilder.handleStartDayOfWeekChanged(
            action.payload.start_of_week
          )
        );
      }
      return state
        .updateIn(['locations', toEntityId(action.payload.id)], location =>
          location.merge(fromJS(action.payload))
        )
        .setIn(
          ['_request', 'locations', 'updateLocation'],
          fromJS({ failed: false, pending: false, error: null })
        );

    case updateLocation.pending.type:
      return state.setIn(
        ['_request', 'locations', 'updateLocation'],
        fromJS({ failed: false, pending: true, error: null })
      );

    case updateLocation.rejected.type:
      return state.setIn(
        ['_request', 'locations', 'updateLocation'],
        fromJS({
          failed: true,
          pending: false,
          error: action.error,
        })
      );

    case hiringActionTypes.FETCH_DASHBOARD_DATA_SUCCESS: {
      const { entities } = normalize(
        {
          locations: action.payload.locations,
          job_requests: action.payload.job_requests,
        },
        {
          locations: [schemas.locationSchema],
          job_requests: [schemas.jobRequestSchema],
        }
      );

      const newState = updateLoaded(state, 'HIRING', ['jobRequests']);

      const jobRequests = entities.job_requests;

      if (jobRequests !== undefined && jobRequests.length) {
        Object.keys(jobRequests).forEach(key => {
          const jr = jobRequests[key];
          jr.isReposting = false;
        });
      }

      return newState.mergeDeep({
        locations: markEntitiesForHiring(fromJS(entities.locations)),
        jobRequests: markEntitiesForHiring(fromJS(jobRequests || {})),
        jobRequestBoosts: markEntitiesForHiring(fromJS(entities.boosts)),
      });
    }

    case settingsActionTypes.FETCH_COMPANY_USERS_SUCCESS: {
      const { entities } = normalize(action.payload, {
        users: [schemas.userSchema],
      });

      const newState = updateLoaded(state, 'SETTINGS', ['users']);
      return newState.mergeDeep({
        users: markEntitiesForSettings(fromJS(entities.users)),
      });
    }

    case hiringActionTypes.ARCHIVE_JOB_REQUEST_SUCCESS: {
      const { entities } = normalize(action.payload, {
        job_request: schemas.jobRequestSchema,
        archived_boosts: [schemas.boostSchema],
      });

      const jobRequestEntityId = toEntityId(action.payload.job_request.id);
      let newState = updateLoaded(state, 'HIRING', [
        'locations',
        'jobRequests',
      ]);
      const { archived_boosts: archivedBoosts } = action.payload;
      const archivedBoostIds = archivedBoosts.map(b => b.id);

      if (archivedBoosts) {
        newState = newState.updateIn(
          ['jobRequests', jobRequestEntityId, 'boosts'],
          boosts =>
            boosts.filterNot(boostId => archivedBoostIds.includes(boostId))
        );
      }

      return newState.mergeDeep({
        jobRequests: markEntitiesForHiring(fromJS(entities.job_requests)),
        jobRequestBoosts: markEntitiesForHiring(fromJS(entities.boosts)),
        locations: markEntitiesForHiring(fromJS(entities.locations)),
      });
    }

    case hiringActionTypes.REPOST_JOB_REQUEST_REQUEST:
      return updateEntity(state, 'jobRequests', action.meta.id, {
        isReposting: true,
      });

    case hiringActionTypes.REPOST_JOB_REQUEST_SUCCESS: {
      const payload = {
        active: true,
        isReposting: false,
        wasReposted: true,
        ...action.payload,
      };
      return updateEntity(state, 'jobRequests', action.payload.id, payload);
    }

    case hiringActionTypes.REPOST_JOB_REQUEST_FAILURE:
      return updateEntity(state, 'jobRequests', action.payload.id, {
        isReposting: false,
      });

    case hiringActionTypes.ARCHIVE_LOCATION_PROFILE_SUCCESS:
    case hiringActionTypes.REPOST_LOCATION_PROFILE_SUCCESS: {
      return updateEntity(
        state,
        'locations',
        action.payload.location_id,
        action.payload
      );
    }

    case hiringActionTypes.UPDATE_JOB_REQUEST_POSTED_AT:
      return updateEntity(state, 'jobRequests', action.payload.id, {
        posted_at: action.payload.posted_at,
      });

    case hiringActionTypes.UPDATE_JOB_POST_BOOST_SUCCESS: {
      const { entities } = normalize(action.payload, schemas.boostSchema);

      const newState = updateLoaded(state, 'HIRING', ['jobRequests', 'boosts']);

      return newState.mergeDeep({
        jobRequestBoosts: markEntitiesForHiring(fromJS(entities.boosts)),
      });
    }

    case `${billerActions.hiringBoostAdded}`:
    case hiringActionTypes.CREATE_JOB_POST_BOOST_SUCCESS: {
      const { entities } = normalize(action.payload, schemas.jobRequestSchema);

      const newState = updateLoaded(state, 'HIRING', [
        'locations',
        'jobRequests',
      ]);

      return newState.mergeDeep({
        jobRequests: markEntitiesForHiring(fromJS(entities.job_requests)),
        jobRequestBoosts: markEntitiesForHiring(fromJS(entities.boosts)),
        locations: markEntitiesForHiring(fromJS(entities.locations)),
      });
    }

    case hiringActionTypes.CREATE_JOB_POST_SUCCESS:
    case hiringActionTypes.UPDATE_JOB_POST_SUCCESS:
      return updateEntity(
        state,
        'jobRequests',
        action.payload.id,
        action.payload
      );

    case hiringActionTypes.UPDATE_COMPANY_PROFILE_SUCCESS:
    case hiringActionTypes.FETCH_COMPANY_PROFILE_SUCCESS: {
      const { entities } = normalize(
        action.payload,
        schemas.companyProfileSchema
      );

      const newState = updateLoaded(state, 'HIRING', [
        'companyProfiles',
        'locationProfiles',
        'jobRequests',
      ]);

      return newState.merge({
        companyProfiles: markEntitiesForHiring(
          fromJS(entities.company_profiles)
        ),
        locationProfiles: markEntitiesForHiring(
          fromJS(entities.location_profiles)
        ),
        jobRequests: markEntitiesForHiring(fromJS(entities.job_requests || {})),
      });
    }

    case hiringActionTypes.FETCH_APPLICANT_PROFILE_SUCCESS: {
      const newState = updateLoaded(state, 'HIRING', ['applicantProfile']);

      const { entities } = normalize(action.payload, schemas.applicantSchema);

      return newState.merge({
        applicants: fromJS(entities.applicant),
        applications: fromJS(entities.applications || {}),
      });
    }

    case hiringActionTypes.SUBMIT_APPLICANT_PROFILE_SUCCESS: {
      const { entities } = normalize(action.payload, schemas.applicantSchema);
      return state.merge({
        applicants: fromJS(entities.applicant),
      });
    }

    // The ceremony around entityKey/entitySchema accommodates the polymorphic
    // relationship between an application and its owner, which can be a location
    // or job request.
    case hiringActionTypes.FETCH_INITIAL_MANAGE_APPLICANTS_SUCCESS: {
      const { type, ...payload } = action.payload;
      const forLocation = type === 'Location';
      const entityKey = forLocation ? 'locations' : 'jobRequests';
      const entitySchema = forLocation
        ? schemas.locationSchema
        : schemas.jobRequestSchema;

      const { entities } = normalize(payload, entitySchema);

      const newStateObject = {
        [entityKey]: markEntitiesForManageApplicants(
          fromJS(entities[snakeCase(entityKey)]).map(entity =>
            entity.set('applications_fetched_at', unixTimestamp())
          )
        ),
        applications: markEntitiesForManageApplicants(
          fromJS(entities.applications)
        ),
        applicationConversations: markEntitiesForManageApplicants(
          (fromJS(entities.application_conversations) || List()).map(
            conversation =>
              conversation.merge({
                // Convert to set to avoid duplicates from websockets
                application_messages: conversation
                  .get('application_messages', List())
                  .toSet(),
              })
          )
        ),
        applicationMessages: markEntitiesForManageApplicants(
          fromJS(entities.application_messages) || List()
        ),
      };

      if (!forLocation) {
        newStateObject.locations = markEntitiesForManageApplicants(
          fromJS(entities.locations)
        );
      }

      // Merge prefill_last_message_conversation into redux state
      if (action.payload.prefill_most_recent_conversation) {
        const { entities: prefill_entities } = normalize(
          action.payload.prefill_most_recent_conversation,
          schemas.applicationConversationSchema
        );
        newStateObject.applicationMessages = markEntitiesForManageApplicants(
          newStateObject.applicationMessages.merge(
            fromJS(prefill_entities.application_messages)
          )
        );

        // Update application_messages array in applicationConversations
        if (
          prefill_entities.application_conversations &&
          prefill_entities.application_conversations.length
        ) {
          const conversation = fromJS(
            prefill_entities.application_conversations
          ).first();
          const newConversations =
            newStateObject.applicationConversations.updateIn(
              [conversation.get('id').toString(), 'application_messages'],
              (messages = Set()) =>
                messages.union(conversation.get('application_messages'))
            );
          newStateObject.applicationConversations = newConversations;
        }
      }

      return state.mergeDeep(newStateObject);
    }

    case addApplicantManuallyActionTypes.SUBMIT_APPLICATION_FORM_SUCCESS:
    case applicantMessengerActionTypes.FETCH_APPLICATION_FOR_MESSENGER_SUCCESS: {
      const { entities } = normalize(action.payload, [
        schemas.applicationSchema,
      ]);

      const applications = markEntitiesForManageApplicants(
        fromJS(entities.applications)
      );

      let newState = state.mergeDeep({
        applications,
        applicationConversations: markEntitiesForManageApplicants(
          fromJS(entities.application_conversations) || List()
        ).map(conversation =>
          conversation.merge({
            // Convert to set to avoid duplicates from websockets
            application_messages: (
              conversation.get('application_messages') || List()
            ).toSet(),
          })
        ),
        applicationMessages: markEntitiesForManageApplicants(
          fromJS(entities.application_messages) || List()
        ),
      });

      applications.forEach(app => {
        const ownerEntityKey =
          app.get('owner_type') === 'locations' ? 'locations' : 'jobRequests';

        newState = newState.updateIn(
          [ownerEntityKey, app.get('owner_id').toString(), 'applications'],
          (apps = List()) => apps.push(app.get('id')).toSet().toList()
        );
      });

      return newState;
    }

    case hiringActionTypes.CREATE_JOB_APPLICATION_NOTE_SUCCESS: {
      const applicationId = toEntityId(action.meta.applicationId);
      return state.updateIn(['applications', applicationId, 'notes'], notes =>
        notes.push(Map(action.payload))
      );
    }

    case hiringActionTypes.DELETE_JOB_APPLICATION_NOTE_SUCCESS: {
      const applicationId = toEntityId(action.meta.applicationId);
      return state.updateIn(['applications', applicationId, 'notes'], notes =>
        notes.filterNot(note => note.get('id') === action.meta.noteId)
      );
    }

    case hiringActionTypes.FETCH_STANDARDIZED_ROLES_SUCCESS: {
      return state.setIn(
        ['standardizedRoles'],
        markEntitiesForHiring(fromJS(action.payload))
      );
    }

    case hiringActionTypes.FETCH_APPLICANT_DATA_SUCCESS: {
      return state.updateIn(
        ['applications', toEntityId(action.meta.id)],
        application =>
          markEntityForApplicantPanel(
            (application || Map()).merge(fromJS(action.payload))
          )
      );
    }

    case hiringActionTypes.TOGGLE_APPLICATION_LIKED_REQUEST: {
      return state.updateIn(
        ['applications', toEntityId(action.meta.id)],
        application => {
          const liked = !action.meta.liked;
          const updates = { liked };

          if (liked && application.get('state') === 'passed') {
            updates.state = 'pending';
          }

          return application.merge(updates);
        }
      );
    }

    case hiringActionTypes.PASS_APPLICATION_REQUEST: {
      return state.mergeIn(['applications', toEntityId(action.meta.id)], {
        state: 'passed',
        liked: false,
      });
    }

    case hiringActionTypes.UNPASS_APPLICATION_REQUEST: {
      return state.mergeIn(['applications', toEntityId(action.meta.id)], {
        state: 'pending',
      });
    }

    case hiringActionTypes.ARCHIVE_APPLICATIONS_SUCCESS:
    case hiringActionTypes.ARCHIVE_APPLICATIONS_REQUEST: {
      const newState = state.mergeIn(
        ['applications', toEntityId(action.meta.ids)],
        {
          state: ARCHIVED_STATE,
        }
      );

      return resetLoaded(newState, ['jobRequests']);
    }

    case hiringActionTypes.TOGGLE_APPLICATION_LIKED_FAILURE:
    case hiringActionTypes.UNPASS_APPLICATION_FAILURE:
    case hiringActionTypes.PASS_APPLICATION_FAILURE: {
      return state.mergeIn(
        ['applications', toEntityId(action.meta.id)],
        pick(action.meta, ['liked', 'state'])
      );
    }

    case hiringActionTypes.HIRE_APPLICATION_SUCCESS: {
      const newState = state.setIn(
        ['applications', toEntityId(action.meta.id), 'state'],
        'hired'
      );

      return resetLoaded(newState, ['users', 'jobs']);
    }

    case hiringActionTypes.BOOK_INTERVIEW_SUCCESS: {
      state = state.mergeIn(
        [
          'applications',
          toEntityId(action.payload.applicant.id),
          'interview_availability',
        ],
        {
          start_at: action.payload.start_at,
          end_at: action.payload.end_at,
          upcoming: action.payload.upcoming,
          id: action.payload.applicant.id,
        }
      );

      state = state.mergeIn(
        ['applications', toEntityId(action.payload.applicant.id)],
        {
          interview_availability_id: action.payload.id,
        }
      );

      return state;
    }

    case hiringActionTypes.SUBMIT_INTERVIEW_DETAILS_SUCCESS: {
      state = state.mergeIn(['applications', toEntityId(action.meta.id)], {
        state: 'interview_requested',
        interview_type: action.meta.interview_type,
      });

      state = state.setIn(
        ['locations', action.meta.location_id.toString(), 'interview_notes'],
        action.meta.interview_notes
      );

      return state;
    }

    case reportsActionTypes.FETCH_REPORTS_DATA_SUCCESS: {
      const { entities } = normalize(action.payload, {
        location: schemas.locationSchema,
      });

      return state.mergeDeep({
        locations: markEntitiesForReports(fromJS(entities.locations)),
      });
    }

    case tiersActionTypes.FETCH_TIERS_DATA_SUCCESS: {
      const { entities } = normalize(action.payload, schemas.locationSchema);

      const newState = updateLoaded(state, 'TIERS', ['locations']);

      return newState.mergeDeep({
        locations: markEntitiesForTiers(fromJS(entities.locations)),
      });
    }

    case departmentsActionTypes.REMOVE_DEPARTMENT_SUCCESS:
    case departmentsActionTypes.FETCH_DEPARTMENTS_DATA_SUCCESS: {
      const { entities } = normalize(action.payload, [
        schemas.departmentSchema,
      ]);

      const newState = updateLoaded(state, 'DEPARTMENTS', ['departments']);

      return newState.mergeDeep({
        departments: markEntitiesForDepartments(fromJS(entities.departments)),
      });
    }

    case departmentsActionTypes.ADD_DEPARTMENT_ROLE_REQUEST: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'roles'],
        roles =>
          roles.push(Map({ id: action.meta.name, name: action.meta.name }))
      );
    }

    case departmentsActionTypes.ADD_DEPARTMENT_ROLE_SUCCESS: {
      let newState = state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'roles'],
        roles => {
          // Remove temp role data
          const newRoles = roles.remove(
            roles.findIndex(role => role.get('id') === action.meta.name)
          );

          if (!roles.find(r => r.get('id') === action.payload.id)) {
            // Insert persisted role data
            return newRoles.push(fromJS(action.payload));
          }

          return newRoles;
        }
      );

      const defaultDepartmentId = toEntityId(
        newState
          .get('departments')
          .find(dep => dep.get('default'))
          .get('id')
      );

      // If added to the default department, take no action.
      if (toEntityId(action.meta.departmentId) === defaultDepartmentId) {
        return newState;
      }

      const roleIndexInDefaultDepartment = newState
        .getIn(['departments', defaultDepartmentId, 'roles'])
        .findIndex(role => role.get('name') === action.meta.name);

      // If role existed in the default department, remove it from the default department.
      if (roleIndexInDefaultDepartment >= 0) {
        newState = newState.removeIn([
          'departments',
          defaultDepartmentId,
          'roles',
          roleIndexInDefaultDepartment,
        ]);
      }

      return newState;
    }

    case departmentsActionTypes.ADD_DEPARTMENT_ROLE_FAILURE: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'roles'],
        // Remove temp role data
        roles =>
          roles.remove(
            roles.findIndex(role => role.get('id') === action.meta.name)
          )
      );
    }

    case departmentsActionTypes.ADD_DEPARTMENT_MANAGER_REQUEST: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'managers'],
        managers =>
          managers.push(
            Map({
              id: action.meta.user.get('id'),
              name: action.meta.user.get('name'),
            })
          )
      );
    }

    case departmentsActionTypes.ADD_DEPARTMENT_MANAGER_FAILURE: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'managers'],
        managers =>
          managers.remove(
            managers.findIndex(
              user => user.get('id') === action.meta.user.get('id')
            )
          )
      );
    }

    case departmentsActionTypes.REMOVE_DEPARTMENT_ROLE_REQUEST: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'roles'],
        roles =>
          roles.remove(
            roles.findIndex(
              role => role.get('id') === action.meta.role.get('id')
            )
          )
      );
    }

    case departmentsActionTypes.REMOVE_DEPARTMENT_MANAGER_REQUEST: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'managers'],
        managers =>
          managers.remove(
            managers.findIndex(
              user => user.get('id') === action.meta.user.get('id')
            )
          )
      );
    }

    case departmentsActionTypes.REMOVE_DEPARTMENT_ROLE_FAILURE: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'roles'],
        // Failed to remove, so add back the role data
        roles => roles.push(action.meta.role)
      );
    }

    case departmentsActionTypes.REMOVE_DEPARTMENT_MANAGER_FAILURE: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.departmentId), 'managers'],
        // Failed to remove, so add back the user data
        users => users.push(action.meta.user)
      );
    }

    case departmentsActionTypes.ADD_DEPARTMENT_SUCCESS: {
      const { entities } = normalize(action.payload, schemas.departmentSchema);

      return state.mergeDeep({
        departments: markEntitiesForDepartments(fromJS(entities.departments)),
      });
    }

    case departmentsActionTypes.REMOVE_DEPARTMENT_REQUEST: {
      return state.removeIn([
        'departments',
        toEntityId(action.meta.department.get('id')),
      ]);
    }

    case departmentsActionTypes.REMOVE_DEPARTMENT_FAILURE: {
      return state.setIn([
        'departments',
        toEntityId(action.meta.department.get('id')),
        action.meta.department,
      ]);
    }

    case departmentsActionTypes.UPDATE_DEPARTMENT_REQUEST: {
      return state.updateIn(
        ['departments', toEntityId(action.meta.department.get('id'))],
        department => department.merge(action.meta.attrs)
      );
    }

    case departmentsActionTypes.UPDATE_DEPARTMENT_FAILURE: {
      return state.setIn([
        'departments',
        toEntityId(action.meta.department.get('id')),
        action.meta.department,
      ]);
    }

    case timeOffActionTypes.UPDATE_TIME_OFF_REQUEST: {
      return state.updateIn(
        ['timeOffs', toEntityId(action.meta.timeOff.get('id'))],
        timeOff => timeOff.merge(action.meta.attrs.time_off)
      );
    }

    case timeOffActionTypes.UPDATE_TIME_OFF_SUCCESS: {
      return state.updateIn(
        ['timeOffs', toEntityId(action.meta.timeOff.get('id'))],
        timeOff => timeOff.merge(action.payload)
      );
    }

    case timeOffActionTypes.UPDATE_TIME_OFF_FAILURE: {
      return state.setIn(
        ['timeOffs', toEntityId(action.meta.timeOff.get('id'))],
        action.meta.timeOff
      );
    }

    case timeOffActionTypes.FETCH_TIME_OFFS_SUCCESS: {
      if (action.payload.jobs) {
        const { entities } = normalize(action.payload, {
          jobs: [schemas.jobSchema],
          timeOffs: [schemas.timeOffSchema],
        });

        const newState = updateLoaded(state, 'TIME_OFF', [
          'jobs',
          'timeOffs',
          'users',
        ]);

        return newState.mergeDeep({
          jobs: markEntitiesForTimeOff(fromJS(entities.jobs || [])),
          users: markEntitiesForTimeOff(fromJS(entities.users || [])),
          timeOffs: markEntitiesForTimeOff(fromJS(entities.time_offs || [])),
        });
      }

      const { entities } = normalize(action.payload, {
        timeOffs: [schemas.timeOffSchema],
      });

      const newState = updateLoaded(state, 'TIME_OFF', ['timeOffs']);

      return newState.set(
        'timeOffs',
        markEntitiesForTimeOff(fromJS(entities.time_offs || []))
      );
    }

    case timeOffActionTypes.DELETE_TIME_OFF_REQUEST: {
      return state.removeIn([
        'timeOffs',
        toEntityId(action.meta.timeOff.get('id')),
      ]);
    }

    case timeOffActionTypes.DELETE_TIME_OFF_FAILURE: {
      return state.setIn(
        ['timeOffs', toEntityId(action.meta.timeOff.get('id'))],
        action.meta.timeOff
      );
    }

    case timeOffActionTypes.APPROVE_TIME_OFF_SUCCESS:
    case timeOffActionTypes.DECLINE_TIME_OFF_SUCCESS: {
      return state.mergeDeepIn(
        ['timeOffs', toEntityId(action.payload.id)],
        fromJS(action.payload)
      );
    }

    case timeOffActionTypes.ADD_TIME_OFF_SUCCESS: {
      return state.setIn(
        ['timeOffs', toEntityId(action.payload.id)],
        markEntityForTimeOff(fromJS(action.payload))
      );
    }

    case timeOffActionTypes.FETCH_PTO_POLICIES_SUCCESS: {
      const { entities } = normalize(action.payload, [schemas.ptoPolicySchema]);

      const newState = updateLoaded(state, 'TIME_OFF', ['ptoPolicies']);

      return newState.merge({
        ptoPolicies: markEntitiesForTimeOff(
          markEntitiesForPTOPolicy(fromJS(entities.pto_policies || {}))
        ),
      });
    }

    case timeOffActionTypes.UPDATE_PTO_POLICY_FOR_USER_SUCCESS: {
      // Remove old policy employee record from former policy if applicable
      if (action.meta.oldPolicyId) {
        state = state.updateIn(
          ['ptoPolicies', toEntityId(action.meta.oldPolicyId), 'users'],
          users =>
            users.remove(
              users.findIndex(
                user => user.get('id') === action.meta.oldPolicyEmployeeId
              )
            )
        );
      }

      // Handle case where user was simply removed from their old
      // policy and not added to another.
      if (action.payload.id === action.meta.oldPolicyId) {
        return state;
      }

      // Add new policy employee record to new policy
      return state.setIn(
        ['ptoPolicies', toEntityId(action.payload.id)],
        markEntityForTimeOff(markEntityForPTOPolicy(fromJS(action.payload)))
      );
    }

    case ptoPolicyActionTypes.FETCH_COMPANY_USERS_SUCCESS: {
      const { entities } = normalize(action.payload, [schemas.userSchema]);

      const newState = updateLoaded(state, 'COMPANY_USERS', ['users']);

      return newState.mergeDeep({
        users: markEntitiesForPTOPolicy(fromJS(entities.users)),
      });
    }

    case ptoPolicyActionTypes.FETCH_PTO_POLICY_SUCCESS:
    case ptoPolicyActionTypes.UPDATE_PTO_POLICY_SUCCESS:
    case ptoPolicyActionTypes.CREATE_PTO_POLICY_SUCCESS: {
      return state.setIn(
        ['ptoPolicies', toEntityId(action.payload.id)],
        markEntityForTimeOff(markEntityForPTOPolicy(fromJS(action.payload)))
      );
    }

    case ptoPolicyActionTypes.DELETE_PTO_POLICY_SUCCESS:
      return state.removeIn(['ptoPolicies', toEntityId(action.meta.id)]);

    case settingsActionTypes.FETCH_COMPANY_CHANNELS_DATA_SUCCESS: {
      const { entities } = normalize(action.payload, [schemas.channelSchema]);
      const newState = updateLoaded(state, 'SETTINGS', ['users', 'channels']);

      return newState.mergeDeep({
        channels: markEntitiesForSettings(fromJS(entities.channels)),
        users: markEntitiesForSettings(fromJS(entities.users)),
      });
    }

    case applicantMessengerActionTypes.RECEIVE_NEW_MESSAGE: {
      const { message, setConversationHasUnread } = action.payload;
      const conversationPath = [
        'applicationConversations',
        toEntityId(message.application_conversation_id),
      ];

      // Disregard message if conversation isn't currently in state - this means
      // the corresponding application isn't in the user's view. If/when it mounts,
      // it will fetch the most up-to-date data.
      if (!state.getIn(conversationPath)) {
        return state;
      }

      const messagesPath = [...conversationPath, 'application_messages'];

      // Associate message with its conversation.
      let newState = state.updateIn(messagesPath, (messages = Set()) =>
        messages.add(message.id)
      );

      // Set conversation's has_unread attr to false if necessary. We determine this in the action
      // creator (async thunk) to prevent the computation from gunking up the redux machinery (sync)
      newState = newState.setIn(
        [...conversationPath, 'has_unread'],
        setConversationHasUnread
      );

      return updateEntity(newState, 'applicationMessages', message.id, message);
    }

    case applicantMessengerActionTypes.FETCH_CONVERSATIONS_SUCCESS: {
      const { entities } = normalize(action.payload, [
        schemas.applicationConversationSchema,
      ]);

      return state.mergeDeep({
        applicationConversations: markEntitiesForApplicationConversations(
          fromJS(entities.application_conversations) || Map()
        ),
      });
    }

    case applicantMessengerActionTypes.FETCH_CONVERSATION_MESSAGES_SUCCESS: {
      const { entities, result } = normalize(action.payload, [
        schemas.applicationMessageSchema,
      ]);

      const conversationMessagesPath = [
        'applicationConversations',
        toEntityId(action.meta.conversationId),
        'application_messages',
      ];

      // Associate messages with their conversation.
      const newState = state.updateIn(
        conversationMessagesPath,
        (messages = Set()) => messages.union(result)
      );

      return newState.mergeDeep({
        applicationMessages: markEntitiesForManageApplicants(
          fromJS(entities.application_messages)
        ),
      });
    }

    case applicantMessengerActionTypes.SEND_MESSAGE_SUCCESS: {
      const { conversation, message } = action.payload;
      const conversationPath = [
        'applicationConversations',
        toEntityId(conversation.id),
      ];
      const conversationMessagesPath = [
        ...conversationPath,
        'application_messages',
      ];

      // Add conversation if it doesn't exist
      let newState = state.updateIn(
        conversationPath,
        (existingConversation = Map()) =>
          existingConversation.merge(conversation)
      );

      // Associate message with its conversation
      newState = newState.updateIn(
        conversationMessagesPath,
        (messages = Set()) => messages.add(message.id)
      );

      return updateEntity(newState, 'applicationMessages', message.id, message);
    }

    case applicantMessengerActionTypes.READ_MESSAGE_REQUEST: {
      return state.setIn(
        [
          'applicationConversations',
          action.meta.conversationId.toString(),
          'has_unread',
        ],
        false
      );
    }

    case dashboardWidgetActionTypes.FETCH_UNREAD_MESSAGES_FROM_APPLICANT_SUCCESS: {
      const { entities } = normalize(action.payload, [
        schemas.applicationMessageSchema,
      ]);
      const newStateObject = {
        applications: markEntitiesForHiringWidget(
          fromJS(entities.applications || {})
        ),
        applicationConversations: markEntitiesForHiringWidget(
          fromJS(entities.application_conversations || {})
        ),
        applicationMessages: markEntitiesForHiringWidget(
          fromJS(entities.application_messages || {})
        ),
      };

      return state.mergeDeep(newStateObject);
    }

    case applicantMessengerActionTypes.READ_MESSAGE_SUCCESS: {
      const conversationId = action.meta.conversationId;
      let applicationMessages = state.get('applicationMessages');
      applicationMessages
        .filter(
          message =>
            message.get('application_conversation_id') === conversationId
        )
        .keySeq()
        .forEach(applicationMessageId => {
          applicationMessages = applicationMessages.setIn(
            [applicationMessageId, 'read'],
            true
          );
        });

      return state.set('applicationMessages', applicationMessages);
    }

    default:
      return state;
  }
};
