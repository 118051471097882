import React from 'react';
const SvgMonoTikTok = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.08 2.667h-4.493V20.83c0 2.164-1.729 3.942-3.88 3.942-2.15 0-3.88-1.778-3.88-3.942 0-2.126 1.69-3.865 3.765-3.942v-4.56c-4.571.077-8.259 3.826-8.259 8.502 0 4.715 3.765 8.502 8.413 8.502 4.647 0 8.411-3.826 8.411-8.502v-9.314a10.407 10.407 0 0 0 5.954 2.01v-4.56c-3.38-.117-6.03-2.9-6.03-6.3Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMonoTikTok;
