import { toI18n } from 'util/i18n';

export const OWNER_ROLE = toI18n('employees_roster.index.roles.owner');
export const EMPLOYEE_ROLE = toI18n('employees_roster.index.roles.employee');
export const MANAGER_ROLE = toI18n('employees_roster.index.roles.manager');
export const GM_ROLE = toI18n('employees_roster.index.roles.gm');

export const FORMATABLE_PHONE_LENGTH = 10;

export const ACCESS_LEVEL_OPTION_VALUES = [
  EMPLOYEE_ROLE,
  MANAGER_ROLE,
  GM_ROLE,
];

export const NAME_OPTIONS = [
  {
    label: toI18n('employees_roster.index.sort_by.first_name'),
    value: 'first_name',
  },
  {
    label: toI18n('employees_roster.index.sort_by.last_name'),
    value: 'last_name',
  },
];

export const ACCESS_LEVEL_OPTIONS = ACCESS_LEVEL_OPTION_VALUES.map(level => ({
  value: level,
  label: level,
}));

export const OWNER_ROLE_OPTIONS = [
  { value: OWNER_ROLE, label: toI18n('employee_edit.owner') },
];

export const WAGE_TYPES = ['hr', 'yr'];

export const STATUS_OPTIONS = [
  {
    label: toI18n('employees_roster.index.status.wage_rate'),
    value: 'missing_wage',
  },
  {
    label: toI18n('employees_roster.index.status.missing_contact_info'),
    value: 'missing_contact_info',
  },
  {
    label: toI18n('employees_roster.index.status.missing_role'),
    value: 'missing_role',
  },
];
export const STATUS_OPTION_VALUES = STATUS_OPTIONS.map(({ value }) => value);

export const MANAGER_COLUMN_HEADERS = {
  team_member: {
    name: 'employees_roster.table.header.team_member',
    sortable: {
      key: 'sortBy',
      trackingKey: 'sort_by',
      current: 'first_name',
      asc: 'first_name',
      desc: 'last_name',
    },
  },
  contact: {
    name: 'employees_roster.table.header.contact',
  },
  access_level: {
    name: 'employees_roster.table.header.access_level',
    employeeView: true,
  },
  location: {
    name: 'employees_roster.table.header.location',
  },
  role_name: {
    name: 'employees_roster.table.header.role_name',
  },
  wage_rate: {
    name: 'employees_roster.table.header.wage_rate',
  },
  account_status: {
    name: 'employees_roster.table.header.account_status',
  },
};

export const EMPLOYEE_COLUMN_HEADERS = {
  team_member: {
    name: 'employees_roster.table.header.team_member',
    sortable: {
      key: 'sortBy',
      trackingKey: 'sort_by',
      current: 'first_name',
      asc: 'first_name',
      desc: 'last_name',
    },
  },
  access_level: {
    name: 'employees_roster.table.header.access_level',
  },
  location: {
    name: 'employees_roster.table.header.location',
  },
};

export const INVITE_TEAM_MODAL_TYPE = 'INVITE_TEAM';
export const BULK_TERMINATE_MODAL_TYPE = 'BULK_TERMINATE_MODAL_TYPE';
export const INVITE_TEAM_VIEWS = {
  FORM: 'form',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const INVITE_TEAM_AUDIENCE_OPTIONS = [
  {
    value: 'have_not_signed_in',
    label: toI18n('employees_roster.invite.audience.have_not_signed_in'),
  },
  { value: 'team', label: toI18n('employees_roster.invite.audience.team') },
];
export const INVITE_TEAM_AUDIENCE_OPTION_VALUES =
  INVITE_TEAM_AUDIENCE_OPTIONS.map(({ value }) => value);
export const INVITE_TEAM_DEFAULT_AUDIENCE_VALUE =
  INVITE_TEAM_AUDIENCE_OPTION_VALUES[0];

const ROW_BUFFER = 20;
const PENDING_TITLE_HEIGHT = 14;

export const TABLE_DIMENSIONS = {
  MIN_ROW_HEIGHT: 50,
  ROW_BUFFER,
  PENDING_TITLE_HEIGHT,
  PENDING_BASE_HEIGHT: PENDING_TITLE_HEIGHT + ROW_BUFFER,
  PENDING_VAR_HEIGHT: 50,
  LOCATIONS_BASE_HEIGHT: ROW_BUFFER,
  LOCATIONS_VAR_HEIGHT: 35,
};
