import React from 'react';
const SvgCopy = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M18 4.5V0h-5.25a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 12.75 18h9A2.25 2.25 0 0 0 24 15.75V6h-4.458C18.675 6 18 5.325 18 4.5ZM19.5 0v4.5H24L19.5 0ZM9 16.5V6H2.25A2.25 2.25 0 0 0 0 8.25v13.5A2.25 2.25 0 0 0 2.25 24h9a2.25 2.25 0 0 0 2.25-2.25V19.5H12c-1.655 0-3-1.345-3-3Z"
    />
  </svg>
);
export default SvgCopy;
