import { createSelector } from 'reselect';

import {
  getCanManageTier,
  getCurrentUserIsManagerial,
} from 'selectors/session';

import {
  selectCurrentLocationTier,
  selectIsPastDueAfterDeadline,
  selectPreSetPastDueDialogInitialViewKey,
} from 'features/biller/selectors';
import { selectShouldShowEnforcementDialogOnNavigation } from 'features/enforcement/EnforcementBanner/selectors';
import {
  selectDisplayDialog,
  selectDisplayMultilocationEnforcementDialog,
} from 'features/enforcement/selectors';

import { PAST_DUE_DIALOG_VIEW_KEY } from './constants';

export const selectInitialPastDueDialogViewKey = createSelector(
  selectDisplayMultilocationEnforcementDialog,
  selectShouldShowEnforcementDialogOnNavigation,
  getCanManageTier,
  selectPreSetPastDueDialogInitialViewKey,
  selectCurrentLocationTier,
  selectIsPastDueAfterDeadline,
  selectDisplayDialog,
  getCurrentUserIsManagerial,
  (
    displayMultilocationEnforcementDialog,
    shouldShowEnforcementDialogOnNavigation,
    canManageTier,
    preSetInitialViewKey,
    currentLocationTier,
    isPastDueAfterDeadline,
    displayDialog,
    isCurrentUserIsManagerial
  ) => {
    if (
      !isCurrentUserIsManagerial ||
      displayDialog ||
      (shouldShowEnforcementDialogOnNavigation &&
        currentLocationTier?.get('name') === 'basic')
    ) {
      return PAST_DUE_DIALOG_VIEW_KEY.hide;
    }

    if (!canManageTier && !isPastDueAfterDeadline) {
      return PAST_DUE_DIALOG_VIEW_KEY.grace_period_non_billing;
    }

    if (!canManageTier && isPastDueAfterDeadline) {
      return PAST_DUE_DIALOG_VIEW_KEY.after_grace_period_non_billing;
    }

    if (preSetInitialViewKey) {
      return preSetInitialViewKey;
    }

    if (
      displayMultilocationEnforcementDialog ||
      shouldShowEnforcementDialogOnNavigation
    ) {
      return PAST_DUE_DIALOG_VIEW_KEY.enforced_location;
    }

    // After grace period
    if (isPastDueAfterDeadline && canManageTier) {
      return PAST_DUE_DIALOG_VIEW_KEY.after_grace_period;
    }

    return PAST_DUE_DIALOG_VIEW_KEY.grace_period;
  }
);
