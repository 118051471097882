import React from 'react';
const SvgLightBulb = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="m2.599 2.553 1.8 1.2A.904.904 0 0 1 4.65 5a.904.904 0 0 1-1.249.252l-1.8-1.2a.904.904 0 0 1-.251-1.25.904.904 0 0 1 1.249-.25Zm19.8 1.5-1.8 1.2A.898.898 0 0 1 19.35 5a.897.897 0 0 1 .251-1.248l1.8-1.2a.898.898 0 0 1 1.249.25.897.897 0 0 1-.251 1.25ZM.9 8.403h2.4c.499 0 .9.4.9.9 0 .498-.401.9-.9.9H.9a.898.898 0 0 1-.9-.9c0-.5.401-.9.9-.9Zm19.8 0h2.4c.499 0 .9.4.9.9 0 .498-.401.9-.9.9h-2.4a.898.898 0 0 1-.9-.9c0-.5.401-.9.9-.9Zm-16.301 6.45-1.8 1.2A.898.898 0 0 1 1.35 15.8a.897.897 0 0 1 .251-1.248l1.8-1.2a.898.898 0 0 1 1.249.25.897.897 0 0 1-.251 1.25Zm16.2-1.497 1.8 1.2a.904.904 0 0 1 .251 1.25.904.904 0 0 1-1.249.25l-1.8-1.2a.905.905 0 0 1-.251-1.248.904.904 0 0 1 1.249-.252Zm-3.754.214c-.742 1.016-1.489 2.04-1.845 3.233H9c-.36-1.197-1.106-2.217-1.845-3.233-.195-.266-.39-.532-.578-.802a6.6 6.6 0 1 1 10.845-.004c-.187.27-.382.536-.577.802v.004ZM15 18.603a3 3 0 1 1-6 0v-.6h6v.6Zm-3-12.6c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6a4.198 4.198 0 0 0-4.2 4.2c0 .33.27.6.6.6.33 0 .6-.27.6-.6a3 3 0 0 1 3-3Z"
    />
  </svg>
);
export default SvgLightBulb;
