import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-components/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { getPacketProps } from 'selectors/drawers';

import {
  I18N_ONBOARDING_AIO_PATH,
  I18N_ONBOARDING_PATH,
  I18N_ONBOARDING_PAYROLL_PATH,
  ONBOARDING_OPTIONS,
  PACKET_TYPE_LABEL,
} from 'features/team/components/AddEmployeeForm/constants';
import { EMPLOYEE_CLASSIFICATIONS } from 'features/team/constants';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import SectionTitle from '../../components/SectionTitle';

import InviteOnlyCheckbox from './components/InviteOnlyCheckbox';
import OnboardingOptionGroup from './components/OnboardingOptionGroup';

const { cx } = cxHelpers('OnboardingSection');

export const OnboardingSection = ({
  contactInfoRequired,
  isPayrollEnrolled,
  onboardingSectionRef,
  packetProps,
  showFormWithSteps,
}) => {
  const { values, setFieldValue } = useFormikContext();

  // default to w2 if packetType is null
  const packetType =
    (values.jobTaxClassification &&
      values.jobTaxClassification.split('-')[0]) ||
    EMPLOYEE_CLASSIFICATIONS.tax_classification; // w2 or 1099

  const documentCount =
    packetType === EMPLOYEE_CLASSIFICATIONS.tax_classification
      ? packetProps.numberOfDocumentsInEmployeeTemplate
      : packetProps.numberOfDocumentsInContractorTemplate;

  const noDocumentsInPacket = documentCount === 0;

  const doNotSendInvite = !values.sendSms;

  const defaultOptionIfNoDocumentsInPacket =
    isPayrollEnrolled || doNotSendInvite
      ? ONBOARDING_OPTIONS.NOTHING
      : ONBOARDING_OPTIONS.INVITE_ONLY;

  useEffect(() => {
    setFieldValue(
      'onboarding',
      !noDocumentsInPacket
        ? ONBOARDING_OPTIONS.INVITE_AND_PACKET
        : defaultOptionIfNoDocumentsInPacket
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptionIfNoDocumentsInPacket, noDocumentsInPacket]);

  const setUpOnboardingPacketSubLabel = (
    <Text
      variant="bodySm"
      i18n={`${I18N_ONBOARDING_PATH}.no_packet_blurb`}
      asHTML
    >
      &nbsp;
      <Link href="/new_hire_onboarding" size="small">
        {toI18n(`${I18N_ONBOARDING_PATH}.nho_page`)}
      </Link>
      .
    </Text>
  );

  const sendOnboardingPacketSubLabel = packetType ? (
    <Text variant="bodySm">
      {toI18n(`${I18N_ONBOARDING_PATH}.invite_and_packet_blurb`, {
        props: {
          packet_type: PACKET_TYPE_LABEL[packetType],
          count: documentCount,
        },
      })}
    </Text>
  ) : (
    <Text variant="bodySm">
      {toI18n(`${I18N_ONBOARDING_PATH}.invite_and_packet_blurb_default`)}
    </Text>
  );

  const manualEntrySubLabel = (
    <>
      <Text
        variant="bodySm"
        i18n={`${I18N_ONBOARDING_PAYROLL_PATH}.manual_entry_blurb`}
      />
      {values.onboarding === ONBOARDING_OPTIONS.NOTHING && (
        <Box mt={24}>
          <InviteOnlyCheckbox />
        </Box>
      )}
    </>
  );

  const includeInvite = useCallback(() => {
    setFieldValue('sendSms', true);
  }, [setFieldValue]);

  const payrollOptions = [
    {
      label: toI18n(`${I18N_ONBOARDING_PAYROLL_PATH}.invite_and_packet`),
      subLabel: noDocumentsInPacket
        ? setUpOnboardingPacketSubLabel
        : sendOnboardingPacketSubLabel,
      value: ONBOARDING_OPTIONS.INVITE_AND_PACKET,
      disabled: noDocumentsInPacket,
      onClick: includeInvite,
    },
    {
      label: toI18n(`${I18N_ONBOARDING_PAYROLL_PATH}.manual_entry`),
      subLabel: manualEntrySubLabel,
      value: ONBOARDING_OPTIONS.NOTHING,
    },
  ];

  if (showFormWithSteps) {
    payrollOptions.push({
      label: toI18n(`${I18N_ONBOARDING_PAYROLL_PATH}.nothing_right_now`),
      value: ONBOARDING_OPTIONS.NOTHING_RIGHT_NOW,
    });
  }

  const aioOptions = [
    {
      label: toI18n(`${I18N_ONBOARDING_AIO_PATH}.invite_and_packet`),
      subLabel: noDocumentsInPacket
        ? setUpOnboardingPacketSubLabel
        : sendOnboardingPacketSubLabel,
      value: ONBOARDING_OPTIONS.INVITE_AND_PACKET,
      disabled: noDocumentsInPacket,
    },
    {
      label: toI18n(`${I18N_ONBOARDING_AIO_PATH}.invite_only`),
      value: ONBOARDING_OPTIONS.INVITE_ONLY,
    },
    {
      label: toI18n(`${I18N_ONBOARDING_AIO_PATH}.no_packet_no_invite`),
      value: ONBOARDING_OPTIONS.NOTHING,
    },
  ];

  const onboardingOptions = isPayrollEnrolled ? payrollOptions : aioOptions;

  return (
    <Box
      className={cx()}
      mv={!showFormWithSteps && 28}
      ref={onboardingSectionRef}
    >
      <SectionTitle
        section={showFormWithSteps ? 'onboarding_with_steps' : 'onboarding'}
        topBorder
      />
      <Box data-testid="onboarding-requirement" mt={8}>
        {contactInfoRequired ? (
          <Box vcenter row>
            <Icon mr={4} size="xsmall" iconName="Critical" color="error300" />
            <Text
              color="error300"
              variant="captionBold"
              i18n={`${I18N_ONBOARDING_PATH}.${
                showFormWithSteps ? 'requirement_with_steps' : 'requirement'
              }`}
            />
          </Box>
        ) : (
          <Text
            variant="captions"
            i18n={`${I18N_ONBOARDING_PATH}.${
              showFormWithSteps ? 'requirement_with_steps' : 'requirement'
            }`}
          />
        )}
      </Box>
      <OnboardingOptionGroup
        options={onboardingOptions}
        showFormWithSteps={showFormWithSteps}
      />
      {showFormWithSteps && (
        <Box vcenter row mt={14}>
          <Icon mr={8} size="small" iconName="Info" color="info300" />
          &nbsp;
          <Text
            color="info300"
            variant="captionBold"
            i18n={`${I18N_ONBOARDING_PATH}.requirement_info`}
          />
        </Box>
      )}
    </Box>
  );
};

OnboardingSection.propTypes = {
  contactInfoRequired: PropTypes.bool.isRequired,
  isPayrollEnrolled: PropTypes.bool,
  onboardingSectionRef: PropTypes.object,
  packetProps: PropTypes.object,
};
export default connect(state => ({
  packetProps: getPacketProps(state),
}))(OnboardingSection);
