import React from 'react';
const SvgComplete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M12 24c6.628 0 12-5.372 12-12S18.628 0 12 0 0 5.372 0 12s5.372 12 12 12Zm5.297-14.203-6 6a1.12 1.12 0 0 1-1.59 0l-3-3a1.125 1.125 0 0 1 1.59-1.59l2.203 2.204 5.203-5.208a1.125 1.125 0 0 1 1.59 1.59l.004.004Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComplete;
