import React, { ReactNode, useCallback } from 'react';
import { FormControl, FormLabel, RadioGroup } from '@mui/material';
import FormHelperText from 'fe-design-base/atoms/FormField/FormHelperText';
import Text from 'fe-design-base/atoms/Text';
import { FormikValues, useFormikContext } from 'formik';

import { cxHelpers } from 'util/className';

const { cx, cxEl } = cxHelpers('FDBRadioGroup');

interface RadioButtonGroupProps {
  label?: string | ReactNode;
  name: string;
  id?: string;
  disabled?: boolean;
  children?: ReactNode;
  helperText?: string;
  readOnly?: boolean;
  hasAsterisk?: boolean;
}

const RadioButtonGroup = ({
  label,
  id,
  name,
  disabled,
  readOnly,
  children,
  helperText,
  hasAsterisk,
}: RadioButtonGroupProps) => {
  const { values, setFieldValue, setFieldTouched, touched, errors } =
    useFormikContext<FormikValues>();
  const validationErrors = !disabled && touched?.[name] && errors?.[name];
  const isRequired = hasAsterisk && !disabled && !readOnly;
  const handleChange = useCallback(
    e => {
      const selectedValue = e.target.value;
      setFieldValue(name, selectedValue);
      setFieldTouched(name, true, false);
    },
    [name, setFieldTouched, setFieldValue]
  );
  return (
    <FormControl sx={{ display: 'block' }}>
      <FormLabel id={id}>
        <Text
          variant="bodyBold"
          color={disabled ? 'mono500' : 'mono900'}
          component="span"
          className={isRequired ? 'required' : undefined}
        >
          {label}
        </Text>
      </FormLabel>
      <FormHelperText
        name={name}
        disabled={disabled}
        readOnly={readOnly}
        hasError={!!validationErrors}
        helperText={helperText}
        className={cxEl('form-helper-wrapper')}
        absolutelyPositioned={false}
      />
      <RadioGroup
        aria-labelledby={id}
        name={name}
        value={values[name] || ''}
        onChange={handleChange}
        className={cx()}
        sx={{
          '&.FDBRadioGroup': {
            marginTop: label ? '20px' : undefined,
            marginLeft: '-6.5px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '15px',

            '& .FDBRadioButton': {
              padding: '9px',
              marginTop: '-3.5px',
            },

            '& .MuiFormControlLabel-root': {
              alignItems: 'self-start',

              '& .FDBBox': {
                boxSizing: 'unset',
              },
            },
          },
        }}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
