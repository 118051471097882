import React, { useCallback } from 'react';
import Button, { ButtonProps } from 'fe-design-base/molecules/Button';

import { publishOnConfirmAndPay } from './events';

const PurchaseMethodButton: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  const handleOnClick = useCallback(
    e => {
      onClick?.(e);
      publishOnConfirmAndPay();
    },
    [onClick]
  );
  return <Button {...props} onClick={handleOnClick} />;
};
export default PurchaseMethodButton;
