import React from 'react';
const SvgDraft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M22.1063 1.01699C21.0797 -0.00957033 19.4203 -0.00957033 18.3937 1.01699L16.9828 2.42324L21.5719 7.01231L22.9828 5.60137C24.0094 4.5748 24.0094 2.91543 22.9828 1.88887L22.1063 1.01699ZM8.08125 11.3295C7.79531 11.6154 7.575 11.967 7.44844 12.3561L6.06094 16.5186C5.925 16.9217 6.03281 17.367 6.33281 17.6717C6.63281 17.9764 7.07812 18.0795 7.48594 17.9436L11.6484 16.5561C12.0328 16.4248 12.3844 16.2092 12.675 15.9232L20.5172 8.07637L15.9234 3.48262L8.08125 11.3295ZM4.5 2.9998C2.01562 2.9998 0 5.01543 0 7.4998V19.4998C0 21.9842 2.01562 23.9998 4.5 23.9998H16.5C18.9844 23.9998 21 21.9842 21 19.4998V14.9998C21 14.1701 20.3297 13.4998 19.5 13.4998C18.6703 13.4998 18 14.1701 18 14.9998V19.4998C18 20.3295 17.3297 20.9998 16.5 20.9998H4.5C3.67031 20.9998 3 20.3295 3 19.4998V7.4998C3 6.67012 3.67031 5.9998 4.5 5.9998H9C9.82969 5.9998 10.5 5.32949 10.5 4.4998C10.5 3.67012 9.82969 2.9998 9 2.9998H4.5Z"
    />
  </svg>
);
export default SvgDraft;
