import React from 'react';
const SvgCollapse = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M13.5 1.5C13.5.67 12.83 0 12 0s-1.5.67-1.5 1.5V3H9a1.502 1.502 0 0 0-1.064 2.564l3 3a1.502 1.502 0 0 0 2.123 0l3-3a1.494 1.494 0 0 0 .324-1.636A1.5 1.5 0 0 0 14.995 3H13.5V1.5Zm-2.56 13.94-3 3a1.494 1.494 0 0 0-.323 1.637 1.5 1.5 0 0 0 1.388.928h1.5v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V21h1.5a1.502 1.502 0 0 0 1.064-2.564l-3-3a1.502 1.502 0 0 0-2.124 0l-.004.005ZM3 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h18c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H3Z"
    />
  </svg>
);
export default SvgCollapse;
