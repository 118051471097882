const DEFAULT_ROUTE = '/clover/sign-up';
const CLOVER_EMBEDDED_DEFAULT_ROUTE = '/clover/embedded';

export const ROUTES = {
  LOADING: `${DEFAULT_ROUTE}/loading`,
  BUSINESS_DETAILS: `${DEFAULT_ROUTE}/business-details`,
  TEAM: `${DEFAULT_ROUTE}/team`,
  INVITE_TEAM: `${DEFAULT_ROUTE}/invite-team`,
  PRIMER: `${DEFAULT_ROUTE}/primer`,
};

export const EMBEDDED_ROUTES = {
  TIMESHEETS: `${CLOVER_EMBEDDED_DEFAULT_ROUTE}/timesheets/landing`,
  TIMESHEETS_STEP1: `${CLOVER_EMBEDDED_DEFAULT_ROUTE}/timesheets/step1`,
  TIMESHEETS_STEP2: `${CLOVER_EMBEDDED_DEFAULT_ROUTE}/timesheets/step2`,
  PAYROLL: `${CLOVER_EMBEDDED_DEFAULT_ROUTE}/payroll/landing`,
};

export const SLICE_NAME = 'cloverSignUp';

export const ENDPOINTS = {
  CREATE_CLOVER_ACCOUNT: '/clover/sign-up/create',
  CREATE_CLOVER_EMBEDDED_ACCOUNT: '/clover/embedded/sign-up/create',
  UPDATE_COMPANY_PROPERTIES: '/onboarding/company_onboarding_properties',
  SYNC_CLOVER_EMPLOYEES: '/employees/sync',
  FETCH_EMPLOYEES: '/employees.json',
  INVITE_EMPLOYEES: '/api/v5/users/invite_all',
  SEND_INSTRUCTIONS: '/clover/sign-up/send_instructions',
};
