import queryString from 'query-string';

const acceptsQuery = route => query =>
  query ? `${route}?${queryString.stringify(query)}` : route;
const acceptsHash = route => hash => hash ? `${route}#${hash}` : route;

export const resetPasswordRoute = () => '/accounts/password';

export const scheduleTemplatesRoute = () => '/templates';

export const scheduleTemplateRoute = templateId => `/templates/${templateId}`;

export const syncShiftsRoute = () => '/schedule_builder/sync_shifts';

export const setExperimentRoute = () => '/user/experiment_enroll';

export const createFromTemplateRoute = templateId =>
  `/templates/${templateId}/create_from_template`;

export const publishScheduleRoute = () => '/schedule_builder/publish';

export const copyScheduleRoute = () => '/schedule_builder/copy';

export const trialPeriodsRoute = () => '/trial_periods';

export const trialPeriodsRetrialMarketingRoute = () =>
  '/trial_periods/retrial_marketing';

export const partnersJobPermissionsRoute = () => '/partners/job_permissions';

export const employeesRoute = () => '/employees';

export const employeesInviteRoute = () => '/employees/invite';

export const employeesBatchCreateRoute = () => '/employees/batch_create';

export const employeesResendInviteRoute = userId =>
  `/employees/${userId}/resend_invite`;

export const employeesBulkAdd = () => '/employees/bulk_add';

export const employeeRoute = userId => `/employees/${userId}`;

export const employeeLastHireDateRoute = userId =>
  `/employees/${userId}/last_hire_date.json`;

export const jobApproveRoute = jobId => `/jobs/${jobId}/approve`;

export const jobRoute = jobId => `/jobs/${jobId}`;

export const jobDestroyWagesRoute = jobId => `/jobs/${jobId}/destroy_wages`;

export const employeeArchiveRoute = userId => `/employees/${userId}/archive`;

export const employeeUnarchiveRoute = userId =>
  `/employees/${userId}/unarchive`;

export const employeeArchiveAllRoute = userId =>
  `/employees/${userId}/archive_all`;

export const jobNotesRoute = () => '/job_notes';

export const jobNoteRoute = noteId => `/job_notes/${noteId}`;

export const importedEmployeesRoute = acceptsQuery('/imported_employees');
export const importedRolesRoute = () => '/imported_roles';

export const workerStatusRoute = acceptsQuery('/workers/status');

export const workersStatusRoute = acceptsQuery('/workers/jobs_status');

export const batchStatusRoute = batchId => `/batch_status/${batchId}.json`;

// SETTINGS
export const userSettingsRoute = () => '/user/settings';

export const settingsRoute = () => '/settings';

export const payrollAdminsSettingsRoute = () =>
  '/payroll/settings/payroll_admins';

export const taxSetupDataSettingsRoute = () =>
  '/payroll/settings/tax_setup_data';

export const locationRoute = locationId => `/locations/${locationId}`;

export const timesheetsSettingsRoute = () => `/location/timesheets_settings`;

export const apiKeysRoute = () => '/api_keys';

export const locationsImportRoute = () => '/locations/import';

export const changeOwnershipEmailConfirmation = () =>
  '/locations/change_ownership_email_confirmation';

export const adpCreditTipsTypeRoute = () => '/adp/update_credit_tips_type';

export const userUpdateRoute = () => '/user';

export const timeZonesRoute = () => '/time_zones';

// HIRING
export const hiringDashboardRoute = () => '/hiring/dashboard.json';

export const jobRequestsRoute = () => '/hiring/job_requests';

export const jobRequestRoute = id => `/hiring/job_requests/${id}`;

export const archiveJobRequestRoute = id =>
  `/hiring/job_requests/${id}/archive`;

export const repostJobRequestRoute = id => `/hiring/job_requests/${id}/repost`;

export const jobRequestSuccessRoute = id => `/hiring/job_post/${id}/success`;

export const jobRequestDownloadQr = id =>
  `/company/text_to_apply_qr_pdf?job_request_id=${id}`;

export const hiringJobRequestBoostsRoute = () => `/hiring/job_request_boosts`;
export const hiringAddBudgetJobRequestBoostsRoute = id =>
  `/hiring/job_request_boosts/${id}`;

export const archiveLocationProfileRoute = id => `/loc/${id}/archive`;

export const repostLocationProfileRoute = id => `/loc/${id}/repost`;

export const hiringJobRequestsPromoteRoute = () =>
  `/hiring/job_requests/promote.json`;

export const companyProfileRoute = id => `/cmp/${id}.json`;

export const updateCompanyProfileRoute = id => `/company_profile/${id}.json`;

export const applicantsCreateRoute = () => '/hiring/applicants';

export const hiringManageApplicantsRoute = (type, id) =>
  `/hiring/${type}/${id}/manage_applicants`;

export const hiringManageApplicants = (type, id) =>
  `${hiringManageApplicantsRoute(type, id)}.json`;

export const applicationToggleLiked = id =>
  `/hiring/job_applications/${id}/toggle_liked`;

export const applicationPass = id => `/hiring/job_applications/${id}/pass`;

export const applicationUnpass = id => `/hiring/job_applications/${id}/unpass`;

export const applicationsArchive = () =>
  `/hiring/job_applications/archive_applications`;

export const applicationHire = id => `/hiring/job_applications/${id}/hire`;

export const applicationApplicant = id =>
  `/hiring/job_applications/${id}/applicant`;

export const applicationNotes = id => `/hiring/job_applications/${id}/notes`;

export const applicationNote = (applicationId, id) =>
  `/hiring/job_applications/${applicationId}/notes/${id}`;

export const applicantProfileRoute = () => '/jobs/profile.json';

export const applicantProfileApplicantRoute = () =>
  '/applicant_profile/applicant';

export const standardizedRoles = () => '/standardized_roles';

export const disableApplicantFTUPopup = () => '/hiring/applicants/disable_ftu';

export const newJobPostRoute = params =>
  acceptsQuery('/hiring/job_post/new.json')(params);

export const editJobPostRoute = id => `/hiring/job_post/${id}/edit`;

export const hiringSettings = () => '/hiring/settings';

export const hiringInterviewAvailabilitiesIndexRoute = params =>
  acceptsQuery('/hiring/interview_availabilities')(params);

export const hiringInterviewAvailabilitiesRoute = id =>
  `/hiring/interview_availabilities/${id}`;

export const hiringInterviewsRoute = acceptsQuery('/hiring/interviews.json');

export const hiringApplicationQuestionsRoute = id =>
  `/hiring/job_requests/${id}/application_questions`;

export const hiringRequestAddApplicantAccess = () =>
  '/hiring/request_add_applicant_access';

export const hiringCheckStatusOfAbilityToAddApplicants = () =>
  '/hiring/check_status_of_ability_to_add_applicants';

// HIRING > APPLICANT MESSENGER

export const applicationConversationsRoute = params =>
  acceptsQuery(`/hiring/messaging/application_conversations`)(params);

export const applicationConversationMessagesRoute = (id, params) =>
  acceptsQuery(
    `/hiring/messaging/application_conversations/${id}/application_messages`
  )(params);

export const applicationConversationCreateMessageRoute = params =>
  acceptsQuery('/hiring/messaging/application_conversations/create_message')(
    params
  );

export const applicationMessagesMarkAsReadRoute = (conversationId, messageId) =>
  `${applicationConversationMessagesRoute(
    conversationId
  )}/${messageId}/mark_as_read`;

export const applicationMessagesUnreadFromApplicants = () =>
  '/hiring/messaging/application_messages/unread_from_applicants.json';

export const applicationConversationApplicationRoute = params =>
  acceptsQuery('/hiring/messaging/application_conversations/application')(
    params
  );

// REPORTS
export const reportsRoute = () => '/reports';

export const scheduledVsActualReportRoute = acceptsQuery(
  '/scheduled_vs_actual'
);

export const biggestExceptionsReportRoute = acceptsQuery(
  '/reports/biggest_exceptions'
);

export const onTimeWidgetRoute = acceptsQuery('/on_time_widget');

export const scheduledHoursByTypeWidgetRoute = acceptsQuery(
  '/reports/scheduled_hours_by_type'
);

export const shiftFeedbackReportRoute = acceptsQuery(
  '/reports/average_shift_feedback'
);

export const scheduledVsActualOverviewReportRoute = acceptsQuery(
  '/reports/scheduled_vs_actual_widget'
);
export const salesSummaryReportRoute = acceptsQuery('/overview');

export const hoursVsSalesReportRoute = acceptsQuery(
  '/reports/hours_vs_sales_widget'
);

// MESSAGING
export const messengerRoute = () => '/messenger';

export const channelsRoute = () => '/messaging/channels';

export const channelRoute = id => `/messaging/channels/${id}`;

export const channelParticipantsRoute = id =>
  `/messaging/channels/${id}/participants`;

export const messageRoute = id => `/messaging/messages/${id}`;

export const channelMessagesRoute = (id, params) =>
  acceptsQuery(`/messaging/channels/${id}/messages`)(params);

export const messageReceivedRoute = id =>
  `/messaging/messages/${id}/received_message`;

export const companyChannelsRoute = () => '/messaging/company_channels';

export const billingBatchEnqueRoute = () => '/billing/batch_enqueue';

export const billingDataRoute = () => '/billing/batch_index';

export const downloadMessageLogsRoute = () => '/messaging/download_logs';

export const clearMessagesRoute = () => '/messaging/clear_messaging';

// DASHBOARD
export const profileRoute = acceptsHash('/profile');

export const dashboardGenerateOvertimeDataRoute = () =>
  '/dashboard/generate_overtime_data';

export const dashboardFetchOvertimeDataRoute = params =>
  acceptsQuery('/dashboard/fetch_overtime_data')(params);

// TIME OFFS
export const timeOffsRoute = acceptsQuery('/time_offs.json');

export const timeOffRoute = id => `/time_offs/${id}`;

export const timeOffsDashboardRoute = () => `/time_offs/dashboard`;

export const timeOffSummaryRoute = acceptsQuery('/time_off_summary');

export const approveTimeOffRoute = id => `/time_offs/${id}/approve`;

export const declineTimeOffRoute = id => `/time_offs/${id}/reject`;

export const policiesRoute = () => `/pto_policies/policies`;

export const ptoPoliciesRoute = () => '/pto_policies.json';

export const ptoPolicyUpdateRoute = id => `/pto_policies/${id}.json`;

export const ptoPoliciesNewRoute = params =>
  acceptsQuery('/pto_policies/new.json')(params);

export const ptoPoliciesCompanyUsersRoute = () => '/pto_policies/company_users';

export const ptoPolicyRoute = id => `/pto_policies/${id}.json`;

// NOTIFICATIONS
export const notificationsRoute = acceptsQuery('/notifications/alerts.json');

export const approveTradeRequestRoute = id => `/trades/${id}/approve`;

export const rejectTradeRequestRoute = id => `/trades/${id}/reject`;

export const acceptTradeRequestRoute = id => `/trades/${id}/accept`;

export const readAlertRoute = id => `/alerts/${id}/read.json`;

export const readHiringJobApplicantRoute = id =>
  `/hiring/job_applications/${id}/confirm_alert`;

export const jobsIgnoreEmptyWageRoute = id =>
  `/jobs/${id}/ignore_empty_wage.json`;

export const dismissClockOutAlertRoute = shiftId =>
  `/shifts/${shiftId}/dismiss_clock_out.json`;

export const approveShiftRoute = id => `/shifts/${id}/approve.json`;

export const rejectShiftRoute = id => `/shifts/${id}/reject.json`;

// TEAM
export const teamEmployeeShowRoute = id => `/team/${id}/personal_information`;
export const teamCheckEmployeeEmailAndPhoneRoute = () =>
  '/team/employees/exists_with_info';
export const teamCreatePayrollCheckEntityRoute = id =>
  `/team/employees/${id}/create_check_entity_sync`;
export const teamCreateNewTeamMemberRoute = () => '/team/employees';
export const teamRehireTeamMemberRoute = id => `/team/employees/${id}/rehire`;
export const teamUpdateTeamMemberRoute = id => `/team/employees/${id}`;
export const fetchRolesWagesRoutes = () => '/employees/roles_wages.json';

// BILLING
export const managersRoute = () => '/employees/managers';
export const downgradeRoute = () => '/billing/downgrade.json';
export const downgradeReasonRoute = () => '/billing/downgrade_reason.json';

// METERING
export const meteringShiftNotesForDate = () => '/metering/shift_notes';
export const meteringEvents = () => '/metering/events';

// MISC
export const tiersRoute = () => '/tiers.json';

export const departmentsRoute = () => '/departments.json';

export const departmentRoute = id => `/departments/${id}`;

export const departmentRolesRoute = id => `/departments/${id}/roles.json`;

export const departmentManagersRoute = (id, userId) =>
  `/departments/${id}/managers/${userId}.json`;

export const departmentsBatchCreateRoute = () => '/departments/batch_create';

export const rolesRoute = () => '/roles';
export const roleRoute = id => `/roles/${id}`;

export const disableFTUPopup = () => '/user/disable_ftu';

export const mapLocationsRoute = acceptsQuery('/admin_map.json');

export const oneTimeEventsRoute = () => '/one_time_events';

export const currentCompanyRoute = () => '/company.json';

export const setAddsEmployeesManuallyRoute = () =>
  '/company/set_adds_employees_manually.json';

export const locationOnboardingPropertiesRoute = () =>
  '/onboarding/location_onboarding_properties';

export const downloadAppSmsRoute = () => '/users/download_app_sms';
export const downloadAppEmailRoute = () => '/users/download_app_email';
export const sendTimeClockAppDownloadRoute = email =>
  acceptsQuery('/send_time_clock_app_download')(email);
export const userPath = () => '/user';

export const companyOnboardingPropertiesRoute = () =>
  '/onboarding/company_onboarding_properties';

export const acceptUserAcceptEULA = () => '/accept_eula';

export const userAcceptEULA = () => '/eula.json';

export const employeeJoinLocationRoute = () =>
  '/onboarding/sign-up/employee/join-location';

export const companyUsersRoute = () => '/company/company_users';

export const changeOwnership = () => '/company/change_ownership';

export const changeOwnershipNonOwnerEmailRoute = () =>
  '/company/change_ownership_non_owner_email';

export const teamEmailsRoute = params => acceptsQuery('/team_emails')(params);

export const blastTeamRoute = () => '/employees/blast_team';

export const userReferralsRoute = () => '/user_referrals';

export const featureFeedback = () => '/user/feature_feedback';

export const disableQBAuthModal = () => '/locations/disable_qb_auth_modal';

export const companySyncRoute = () => '/employees/company_sync';

export const employeesSyncRoute = () => '/employees/sync';

export const monetizationCtaRoute = () => '/monetization/cta';

export const featureThrottling = () => '/monetization/feature_throttling';

export const companySettings = () => '/company_settings';

// HR Pro
export const hrProRoute = () => '/hr_pro.json';

export const hrProSubscribeRoute = () => '/hr_pro/subscribe.json';

export const hrProEnsureHrOnDemandRoute = () => '/hr_pro/ensure_hr_on_demand';

export const timecardRoute = (shiftId, url) =>
  `/timesheets/timecards/${shiftId}${url || ''}`;

// Mobile Interstitial
export const dismissMobileInterstitial = () => '/dismiss_mobile_interstitial';

// Timesheets
export const timesheetsTableRowsRoute = params =>
  acceptsQuery('/timesheets/table_rows')(params);
export const dailyReviewRowsRoute = params =>
  acceptsQuery('/timesheets/daily_review_rows')(params);

export const timesheetsTotalsRoute = params =>
  acceptsQuery('/timesheets/generate_totals')(params);

export const timesheetsPayrollPeriodsRoute = params =>
  acceptsQuery('/timesheets/payroll_periods')(params);

export const timesheetsPayrollTotalsRoute = params =>
  acceptsQuery('/timesheets/generate_payroll_totals')(params);

export const timesheetsPayrollSummaryLaborSalesRoute = params =>
  acceptsQuery('/timesheets/fetch_sales')(params);

export const timesheetsGenerateTipsTotalsRoute = params =>
  acceptsQuery('/tip_pooling/for_location')(params);

export const timesheetsFetchTotalsRoute = params =>
  acceptsQuery('/timesheets/fetch_totals')(params);

export const timesheetsFetchPayrollTotalsRoute = params =>
  acceptsQuery('/timesheets/fetch_payroll_totals')(params);

export const timesheetsTableRowRoute = params =>
  acceptsQuery('/timesheets/table_row')(params);

export const timesheetsFetchRowGroupPhotos = params =>
  acceptsQuery('/timesheets/row_group_photos')(params);

export const timesheetsGuidedBannerDataRoute = () =>
  '/timesheets/guided_banner_data';

// User Web Timeclock
export const userWebTimeclockStatusRoute = () =>
  '/user_web_timeclock/status.json';

export const userWebTimeclockClockInRoute = shiftId =>
  `/user_web_timeclock/${shiftId}/clock_in`;

export const userWebTimeclockClockInUnscheduleRoute = () =>
  `/user_web_timeclock/clock_in_unscheduled`;

export const userWebTimeclockClockOutRoute = shiftId =>
  `/user_web_timeclock/${shiftId}/clock_out`;

export const userWebTimeclockClockInBreakRoute = (shiftId, breakId) =>
  acceptsQuery(`/user_web_timeclock/${shiftId}/clock_in_break`)({
    mb_id: breakId,
  });

export const userWebTimeclockClockOutBreakRoute = shiftId =>
  `/user_web_timeclock/${shiftId}/clock_out_break`;

export const timecardApprovalRoute = () => '/timesheets/update_approvals';
export const timesheetsTurnOffBreakRoute = () => '/timesheets/turn_off_break';
export const timesheetsDismissBreakRoute = () => '/timesheets/dismiss_break';

export const timesheetsHistoryRoute = params =>
  acceptsQuery('/timesheets/history')(params);

export const changeLocationSettingsRoute = params =>
  acceptsQuery('/location/timesheets_settings.json')(params);

export const announcementReadsCreateRoute = () => '/announcement_reads';

export const teamAnnouncementsRoute = () => '/team_announcements';
export const teamAnnouncementsListRoute = () => '/team_announcements/list';
export const teamAnnouncementRoute = id => `/team_announcements/${id}`;
export const teamAcknowledgmentsRoute = id =>
  `/team_announcements/${id}/acknowledgments`;

export const teamAnnouncementsAcknowledgeRoute = id =>
  `/team_announcements/${id}/mark_acknowledged`;

export const teamAnnouncementsReadRoute = id =>
  `/team_announcements/${id}/mark_read`;

export const teamAnnouncementsArchiveRoute = id =>
  `/team_announcements/${id}/archive`;

export const userDocumentsRoute = userId => `/users/${userId}/documents`;

export const userDocumentRoute = (userId, documentId) =>
  `/users/${userId}/documents/${documentId}`;

export const dismissHealthAlertRoute = alertId =>
  `/timeclock/health_alerts/${alertId}/dismiss.json`;

export const healthAnswersRoute = () => '/timeclock/health_answers';

export const updateEmployeeEditAlertRoute = (id, params) =>
  acceptsQuery(`/timesheets/timecard/employee_edits/${id}`)(params);

export const signI9VerificationRoute = () => '/sign_signature_request';

export const certificatesNewRoute = () => `/certificates`;
export const certificatesRoute = id => `/certificates/${id}`;
export const generatePlaidLinkTokenRoute = () =>
  '/employee_onboarding/plaid_link_token';
export const createPlaidItemRoute = () =>
  '/employee_onboarding/create_plaid_item';

export const generatePayrollPlaidLinkTokenRoute = () =>
  '/payroll/plaid_link_token';
export const createPayrollPlaidItemRoute = () => '/payroll/create_plaid_item';

export const acceptUserMergeRequestRoute = id =>
  `/team/user_merge_requests/${id}/accept`;

export const declineUserMergeRequestRoute = id =>
  `/team/user_merge_requests/${id}/decline`;

// Benefit routes
export const createBenefitRoute = id => `/employees/${id}/create_benefit`;
export const updateBenefitRoute = id => `/employees/${id}/update_benefit`;
export const deleteBenefitRoute = id => `/employees/${id}/delete_benefit`;

// Recurring Earning routes
export const recurringEarningsRoute = id =>
  `/employees/${id}/recurring_earnings`;
export const recurringEarningRoute = (id, recurringEarningId) =>
  `/employees/${id}/recurring_earnings/${recurringEarningId}`;

// Recurring Reimbursement routes
export const recurringReimbursementsRoute = id =>
  `/employees/${id}/recurring_reimbursements`;
export const recurringReimbursementRoute = (id, recurringReimbursementId) =>
  `/employees/${id}/recurring_reimbursements/${recurringReimbursementId}`;

// PTD routes
export const createPTDRoute = id =>
  `/employees/${id}/create_post_tax_deduction`;
export const updatePTDRoute = id =>
  `/employees/${id}/update_post_tax_deduction`;
export const deletePTDRoute = id =>
  `/employees/${id}/delete_post_tax_deduction`;

export const dashboardPath = () => '/hiring/dashboard';

// Tax Information
export const submitEmployeeWithholdings = id =>
  `/employees/${id}/submit_employee_withholdings`;

// ShiftPay
export const cashOutPath = () => '/cash_out.json';

// Paystubs
export const filterPaystubs = id => `/employees/${id}/paystubs`;
export const filterPaystubsForCurrentUser = () =>
  `/user_documents/pay_statements.json`;

// Payroll
export const getAccountingIntegrations = () =>
  '/payroll/settings/accounting_integrations';
export const checkOnboardingComponent = () =>
  '/payroll/check_onboarding_component';
export const updatePayrollSetupInfo = () => '/payroll/setup_infos';
export const updatePredictedFailureWireIntentionRoute = () =>
  'payroll/nsf/predicted_failure_wire_intention';
export const updatePredictedFailureChangePaydayRoute = payrollRunId =>
  `/payroll/run_workflows/${payrollRunId}/update_predicted_failure_payday`;

// Iterable
export const iterableEvent = () => '/integrations/iterable/events';

export const fetchTipInsData = ({ tipPolicyId, startDate, endDate }) =>
  `/timesheets/tip_pooling_policies/${tipPolicyId}/tip_ins?` +
  `start_date=${startDate}&end_date=${endDate}`;

export const fetchTipOutsData = ({ tipPolicyId, startDate, endDate }) =>
  `/timesheets/tip_pooling_policies/${tipPolicyId}/tip_outs?` +
  `start_date=${startDate}&end_date=${endDate}`;
