import React from 'react';
const SvgSignOutIn = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M7.5 19.5h-3A1.5 1.5 0 0 1 3 18V6a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 0 0-3h-3A4.5 4.5 0 0 0 0 6v12a4.5 4.5 0 0 0 4.5 4.5h3a1.5 1.5 0 0 0 0-3Zm16.06-8.56-6-6a1.5 1.5 0 1 0-2.122 2.122l3.443 3.438H9c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h9.881l-3.44 3.44a1.5 1.5 0 1 0 2.122 2.12l6-6a1.501 1.501 0 0 0-.004-2.12Z"
    />
  </svg>
);
export default SvgSignOutIn;
