import React from 'react';
const SvgDocumentOutline = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M3 3a3 3 0 0 1 3-3h7.758c.797 0 1.556.316 2.119.879l4.246 4.244A2.99 2.99 0 0 1 21 7.243V21c0 1.655-1.345 3-3 3H6c-1.657 0-3-1.345-3-3V3Zm15.75 18V7.5H15c-.83 0-1.5-.67-1.5-1.5V2.25H6a.75.75 0 0 0-.75.75v18c0 .413.336.75.75.75h12c.413 0 .75-.337.75-.75Z"
    />
  </svg>
);
export default SvgDocumentOutline;
