export const ALTERNATE_RENDER_WIDTH = 500;

export const DASHBOARD_WIDGET_POS_OPTIONS = {
  aloha: 'Aloha',
  micros: 'Micros',
  ncr_silver: 'NCR Silver',
  dinerware: 'Dinerware',
  digital_dining: 'Digital Dining',
  positouch: 'Positouch',
  touch_bistro: 'Touch Bistro',
  shopify: 'Shopify',
  cake: 'Cake',
  lavu: 'Lavu',
  brink: 'Brink',
  vend: 'Lightspeed Retail X-Series (formerly Vend)',
  future_pos: 'Future POS',
  shopkeep: 'Shopkeep',
  harbortouch: 'Harbortouch',
};

export const CUSTOM_POS_NAMES = {
  clover_uk: 'Clover UK',
  mcps: 'Paysafe (MCPS)',
  carbon: 'Carbon: Register by Smart Volution',
  pay_anywhere: 'PayAnywhere',
  nab: 'Payments Hub',
  lightspeed_retail: 'Lightspeed Retail R-Series',
  lightspeed_restaurant: 'Lightspeed Restaurant L-Series',
  lightspeed_k_series: 'Lightspeed Restaurant K-Series',
  poynt: 'GoDaddy (formerly Poynt)',
  upserve: 'Lightspeed Restaurant U-Series (formerly Upserve)',
};

export const TEAM_ANNOUNCEMENT_MODAL = 'DASHBOARD/TEAM_ANNOUNCEMENT_MODAL';

export const DISMISSED_MOBILE_APP_CALLOUT = 'dismissed_mobile_app_callout';

export const CALENDLY_URL =
  'https://calendly.com/hbteam/multi-location/?utm_medium=app&utm_source=dashboard&utm_campaign=ml-trial-help-profile-dropdown';

export const PAYROLL_RUN_PAYDAY_PASSED_OTE =
  'payroll_run_payday_passed_alert_closed';
export const PAYROLL_RUN_DEADLINE_PASSED_OTE =
  'payroll_run_deadline_passed_alert_closed';
