import { useCallback, useState } from 'react';

import { datadogLogInfo } from 'util/datadogLogs';

export type CallbackType = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: any
) => (
  args: Record<string, unknown>,
  context: Record<string, unknown>,
  meta: Record<string, unknown>
) => void;

// ---------------------------------------------------------------
// Hooks
// ---------------------------------------------------------------
export const useRegisterCommandBarCallbackAction = (
  callbackSlug: string,
  callback: CallbackType
) => {
  const [registered, setRegistered] = useState(false);
  const registerCallbackAction = useCallback(() => {
    if (registered) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).CommandBar) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).CommandBar.addCallback(callbackSlug, callback);
      setRegistered(true);
    } else {
      datadogLogInfo({
        message: 'command.bar.callback.not.registered',
        context: { callbackSlug },
      });
    }
  }, [registered, callback, setRegistered, callbackSlug]);

  // If CommandBar is already loaded, register the callback action immediately
  // Otherwise, wait for the 'commandbar-loaded' event, because in case
  // the CustomEvent is fired before the callback action is added to event listeners,
  // the event will be missed, therefore no callback action will be registered
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any).CommandBar) {
    registerCallbackAction();
  } else {
    window.addEventListener('commandbar-loaded', registerCallbackAction);
  }
};
