export const CIRCULAR_INFINITE_VARIANTS = [
  'light',
  'dark',
  'destructive',
] as const;
export const CIRCULAR_INFINITE_SIZES = {
  small: 16,
  medium: 24,
  large: 48,
} as const;

export type Variant = (typeof CIRCULAR_INFINITE_VARIANTS)[number];

export type Size = keyof typeof CIRCULAR_INFINITE_SIZES;
