import React from 'react';
const SvgOvernight = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.718 0C6.967 0 1.5 5.373 1.5 12s5.467 12 12.218 12c3.313 0 6.314-1.296 8.517-3.396a.844.844 0 0 0 .17-1.002.886.886 0 0 0-.93-.456 9.782 9.782 0 0 1-1.645.14c-5.297 0-9.594-4.222-9.594-9.429 0-3.525 1.968-6.594 4.881-8.212a.85.85 0 0 0 .421-.927.873.873 0 0 0-.781-.67 13.352 13.352 0 0 0-1.039-.043V0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgOvernight;
