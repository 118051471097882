import React from 'react';
import {
  chalk_play,
  cukoo_clock,
  hey_plane,
  hooray_banner,
} from 'fe-design-base/assets/gifsDeprecated/imageFiles';
import { GifProps } from 'fe-design-base/assets/types';
import Box from 'fe-design-base/atoms/Box';

const buildComponent = (name: string, args: GifProps) => (
  <Box {...args}>
    <img src={name} style={{ width: '100%' }} />
  </Box>
);

export const ChalkPlay = (args: GifProps) => buildComponent(chalk_play, args);
export const CukooClock = (args: GifProps) => buildComponent(cukoo_clock, args);
export const HeyPlane = (args: GifProps) => buildComponent(hey_plane, args);
export const HoorayBanner = (args: GifProps) =>
  buildComponent(hooray_banner, args);
