import React from 'react';

const SVGSplit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M18.928 1.612a1.502 1.502 0 0 1 1.636.324l3 3a1.498 1.498 0 0 1 0 2.118l-3 3a1.494 1.494 0 0 1-1.636.324A1.5 1.5 0 0 1 18 8.99V7.5h-1.758c-.398 0-.778.159-1.06.44L11.124 12l4.06 4.06c.281.28.66.44 1.06.44H18V15a1.502 1.502 0 0 1 2.564-1.064l3 3a1.498 1.498 0 0 1 0 2.118l-3 3a1.494 1.494 0 0 1-1.636.324A1.5 1.5 0 0 1 18 20.99V19.5h-1.758a4.498 4.498 0 0 1-3.183-1.317L8.377 13.5H1.5C.67 13.5 0 12.83 0 12s.67-1.5 1.5-1.5h6.877l4.682-4.683A4.498 4.498 0 0 1 16.242 4.5H18V3c0-.605.366-1.153.928-1.388Z"
    />
  </svg>
);
export default SVGSplit;
