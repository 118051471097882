import { fromJS, OrderedMap } from 'immutable';

import * as dateTime from 'util/dateTime';

export const BASIC_TIER_MAX_REPORT_RANGE_DAYS = 7;
export const REQUEST_KEYS = {
  reportsData: 'REPORTS_DATA',
  scheduledVsActual: 'SCHEDULED_VS_ACTUAL',
};

export const INITIAL_PAGE_STATE = fromJS({
  startDate: null,
  endDate: null,
  reportPeriod: null,
});

export const DEFAULT_FILTERS = {
  startDate: dateTime.startOfCurrentWeek(),
  endDate: dateTime.endOfCurrentWeek(),
};

const REPORT_NAMES = {
  OVERVIEW: 'REPORTS_OVERVIEW',
  SCHEDULED_VS_ACTUAL_HOURS: 'SCHEDULED_VS_ACTUAL_HOURS_REPORTS',
  SCHEDULED_VS_ACTUAL_CLOCK_INS: 'SCHEDULED_VS_ACTUAL_CLOCK_INS_REPORTS',
  ON_TIME_ARRIVAL: 'ON_TIME_ARRIVAL_REPORTS',
  PAYROLL: 'PAYROLL_REPORTS',
  SHIFT_FEEDBACK: 'SHIFT_FEEDBACK_REPORTS',
  HOURLY_LABOR_COSTS: 'HOURLY_LABOR_COSTS_REPORTS',
  LABOR_BY_TYPE: 'LABOR_BY_TYPE_REPORTS',
  LABOR_COST_SUMMARY: 'LABOR_COST_SUMMARY_REPORTS',
  SALES_SUMMARY: 'SALES_SUMMARY_REPORTS',
  DEPARTMENTS: 'DEPARTMENTS_REPORTS',
  CERTIFICATES: 'CERTIFICATES_REPORTS',
};

export const REPORT_WIDGETS = {
  DEFAULT_SIZE: 240,
  LABEL_X: 120,
  PERCENTAGE_Y: 115,
  ON_TIME_Y: 135,
};

export const TAB_CONFIGS = OrderedMap({
  [REPORT_NAMES.OVERVIEW]: {
    label: 'Overview',
    route: '/reports/overview',
    serverKey: 'overview',
    controls: {
      dateRange: true,
    },
  },

  [REPORT_NAMES.SCHEDULED_VS_ACTUAL_HOURS]: {
    label: 'Scheduled vs Actual Hours',
    route: '/reports/scheduled_vs_actual',
    backboneReport: (router, { period }) => router.scheduledVsActual(period),
    serverKey: 'scheduled-vs-actual',
  },

  [REPORT_NAMES.SCHEDULED_VS_ACTUAL_CLOCK_INS]: {
    label: 'Scheduled vs Actual Clock-Ins',
    route: '/reports/scheduled_vs_actual_clock_ins',
    backboneReport: (router, { period, ...opts }) =>
      router.scheduledVsActualClockIns(period, opts),
    serverKey: 'scheduled-vs-actual-clock-ins',
    featureName: 'performance_reports',
  },

  [REPORT_NAMES.ON_TIME_ARRIVAL]: {
    label: 'On-Time Arrival',
    route: '/reports/on_time',
    backboneReport: (router, { startDate, endDate, ...opts }) =>
      router.onTime(
        startDate || dateTime.startOfMonth(dateTime.today()),
        endDate || dateTime.endOfMonth(dateTime.today()),
        opts
      ),
    serverKey: 'on-time',
    featureName: 'performance_reports',
    focusedFeature: 'retentionAndPerformanceTracking',
  },

  [REPORT_NAMES.PAYROLL]: {
    label: 'Payroll reports',
    route: '/reports/payroll',
    serverKey: 'payroll',
  },

  [REPORT_NAMES.SHIFT_FEEDBACK]: {
    label: 'Shift Feedback',
    route: '/reports/shift_feedback',
    backboneReport: (router, { startDate, endDate, ...opts }) =>
      router.shiftFeedback(
        startDate || dateTime.startOfMonth(dateTime.today()),
        endDate || dateTime.endOfMonth(dateTime.today()),
        opts
      ),
    serverKey: 'shift-feedback',
  },

  [REPORT_NAMES.HOURLY_LABOR_COSTS]: {
    label: 'Hourly Labor Costs',
    route: '/reports/wage_forecast',
    backboneReport: (router, { date, ...opts }) =>
      router.wageForecast(date || dateTime.today(), opts),
    serverKey: 'wage-forecast',
    featureName: 'reports-wage-forecast',
    focusedFeature: 'permissionAndDepartments',
  },

  [REPORT_NAMES.LABOR_BY_TYPE]: {
    label: 'Labor By Role',
    route: '/reports/labor_by_type',
    backboneReport: (router, { date }) =>
      router.laborByType(date || dateTime.today()),
    serverKey: 'labor_by_type',
    featureName: 'reports-labor_by_type',
    focusedFeature: 'laborCostManagement',
  },

  [REPORT_NAMES.LABOR_COST_SUMMARY]: {
    label: 'Labor Cost Summary',
    route: '/reports/labor_cost_summary',
    backboneReport: (router, { period }) =>
      router.laborCostSummary(period || 'hour'),
    serverKey: 'labor-cost-summary',
    featureName: 'reports-labor-cost-summary',
    focusedFeature: 'businessInsights',
  },

  [REPORT_NAMES.SALES_SUMMARY]: {
    label: 'Sales Summary',
    route: '/reports/sales_summary',
    controls: {
      dateRange: true,
      printOption: true,
    },
    serverKey: 'sales-summary',
  },

  [REPORT_NAMES.DEPARTMENTS]: {
    label: 'Departments',
    route: '/reports/departments',
    backboneReport: (router, { date, ...opts }) =>
      router.departmentsView(date || dateTime.today(), opts),
    serverKey: 'departments',
    featureName: 'departments',
  },
  [REPORT_NAMES.CERTIFICATES]: {
    label: 'Certificates',
    route: '/reports/certificates',
    serverKey: 'certificates',
    featureName: 'certificates',
    focusedFeature: 'retentionAndPerformanceTracking',
  },
});

export const ROUTE_TO_TAB_NAME_MAP = TAB_CONFIGS.mapEntries(
  ([formName, { route }]) => [route, formName]
);

const NAV_ITEMS_PROPS = [{ header: true, content: 'REPORTS' }];

TAB_CONFIGS.forEach(
  ({ route, label, serverKey, featureName, focusedFeature }) =>
    NAV_ITEMS_PROPS.push({
      route,
      serverKey,
      content: label,
      featureName,
      focusedFeature,
    })
);

export { NAV_ITEMS_PROPS };
