import React from 'react';
const SvgDocuments = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M16.5 0H9C7.345 0 6 1.345 6 3v13.5c0 1.655 1.345 3 3 3h10.5c1.655 0 3-1.345 3-3V6H18c-.83 0-1.5-.67-1.5-1.5V0Zm6 4.5L21 3l-1.5-1.5L18 0v4.5h4.5ZM3.75 5.625c0-.623-.502-1.125-1.125-1.125S1.5 5.002 1.5 5.625v12A6.376 6.376 0 0 0 7.875 24h9c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125h-9a4.125 4.125 0 0 1-4.125-4.125v-12Z"
    />
  </svg>
);
export default SvgDocuments;
