import React, { useCallback, useMemo } from 'react';
import FDBButton from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

import { MANAGE_PLANS_PATH } from 'features/managePlan/constants';

import Button from 'components/clickables/Button';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('GoToManagePlanPageButton');

export const GoToManagePlanPageButton = ({
  baseComponent,
  loadFullPage,
  focusedFeature,
  planName,
  pageUrl,
  onClick,
  children,
  ...props
}) => {
  const Component = useMemo(
    () => (baseComponent === 'designbase' ? FDBButton : Button),
    [baseComponent]
  );

  const extraProps = useMemo(() => {
    const result = {};

    if (loadFullPage) result.href = pageUrl || MANAGE_PLANS_PATH;

    return result;
  }, [loadFullPage, pageUrl]);

  const handleOnClick = useCallback(() => {
    onClick?.();

    if (extraProps.href) return;

    const paywallLinkUrl =
      Boolean(planName && focusedFeature) &&
      MANAGE_PLANS_PATH +
        `?view=focus&plan=${planName}&origin=paywall&feature_key=${focusedFeature}`;

    const linkUrl = paywallLinkUrl || props.linkTo || MANAGE_PLANS_PATH;

    window.Homebase.RailsReactBridge.navigateToReactView(linkUrl);
  }, [props.linkTo, extraProps.href, onClick, planName, focusedFeature]);

  return (
    <Component
      onClick={handleOnClick}
      {...props}
      className={cx()}
      {...extraProps}
    >
      {children}
    </Component>
  );
};

GoToManagePlanPageButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  pageUrl: PropTypes.string,
  focusedFeature: PropTypes.string,
  planName: PropTypes.string,
  loadFullPage: PropTypes.bool,
  baseComponent: PropTypes.string,
};

GoToManagePlanPageButton.defaultProps = {
  baseComponent: 'clickables',
  loadFullPage: false,
};

export default GoToManagePlanPageButton;
