import React from 'react';
const SvgUnlock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M18 3.966A3.332 3.332 0 0 0 14.667 7.3v2H16a2.67 2.67 0 0 1 2.667 2.666v8A2.67 2.67 0 0 1 16 22.633H2.667A2.67 2.67 0 0 1 0 19.967v-8A2.67 2.67 0 0 1 2.667 9.3H12v-2a6 6 0 0 1 12 0v2c0 .737-.596 1.333-1.333 1.333A1.332 1.332 0 0 1 21.333 9.3v-2A3.332 3.332 0 0 0 18 3.966ZM10.667 17.3c.737 0 1.333-.596 1.333-1.334 0-.737-.596-1.333-1.333-1.333H8c-.737 0-1.333.596-1.333 1.334 0 .737.596 1.333 1.333 1.333h2.667Z"
    />
  </svg>
);
export default SvgUnlock;
