import React from 'react';
const SvgDelete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M7.838.83C8.09.32 8.61 0 9.178 0h5.644c.567 0 1.087.321 1.34.83l.338.67H21a1.5 1.5 0 1 1 0 3H3a1.5 1.5 0 0 1 0-3h4.5l.338-.67ZM2.958 6H21v15c0 1.655-1.345 3-3 3H5.958c-1.615 0-3-1.345-3-3V6Zm3.75 3.75v10.5c0 .413.38.75.75.75.454 0 .75-.337.75-.75V9.75a.727.727 0 0 0-.75-.75c-.37 0-.75.338-.75.75Zm4.5 0v10.5c0 .413.38.75.75.75.454 0 .792-.337.792-.75V9.75c0-.412-.338-.75-.792-.75-.37 0-.75.338-.75.75Zm4.542 0v10.5c0 .413.337.75.75.75s.75-.337.75-.75V9.75A.752.752 0 0 0 16.5 9a.752.752 0 0 0-.75.75Z"
    />
  </svg>
);
export default SvgDelete;
