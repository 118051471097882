import React from 'react';
const SvgArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M1.94 10.94a1.502 1.502 0 0 0 0 2.124l7.5 7.5a1.502 1.502 0 0 0 2.123-2.124L6.618 13.5h14.38c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H6.623l4.936-4.941a1.502 1.502 0 0 0-2.123-2.123l-7.5 7.5.004.004Z"
    />
  </svg>
);
export default SvgArrowLeft;
