import * as React from 'react';
const SvgAutoClockOut = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g>
      <path
        fill="currentColor"
        d="M9.7 0c-.83 0-1.5.67-1.5 1.5S8.87 3 9.7 3h.75v1.612C5.777 5.335 2.2 9.375 2.2 14.25A9.749 9.749 0 0 0 11.95 24a9.749 9.749 0 0 0 9.75-9.75 9.68 9.68 0 0 0-1.57-5.306l1.13-1.13a1.502 1.502 0 0 0-2.124-2.123l-1.012 1.012a9.71 9.71 0 0 0-4.674-2.09V3h.75c.83 0 1.5-.67 1.5-1.5S15.03 0 14.2 0H9.7Zm3.375 9v6c0 .623-.501 1.125-1.125 1.125A1.122 1.122 0 0 1 10.825 15V9c0-.623.502-1.125 1.125-1.125.624 0 1.125.502 1.125 1.125Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAutoClockOut;
