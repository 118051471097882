import React from 'react';
const SvgColorTwitter = () => (
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.056 27.333c10.06 0 15.564-8.337 15.564-15.565 0-.234-.005-.474-.015-.708a11.11 11.11 0 0 0 2.728-2.832c-.997.443-2.056.733-3.14.859A5.5 5.5 0 0 0 28.6 6.06a10.997 10.997 0 0 1-3.473 1.328 5.478 5.478 0 0 0-9.327 4.988A15.538 15.538 0 0 1 4.525 6.666a5.477 5.477 0 0 0 1.693 7.3 5.478 5.478 0 0 1-2.479-.682v.067a5.469 5.469 0 0 0 4.39 5.365c-.805.22-1.65.252-2.468.094a5.482 5.482 0 0 0 5.108 3.802 10.972 10.972 0 0 1-8.102 2.264 15.52 15.52 0 0 0 8.389 2.457Z"
      fill="#1DA1F2"
    />
  </svg>
);
export default SvgColorTwitter;
