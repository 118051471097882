import React from 'react';
const SvgAdd = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M24 12c0 1.02-.827 1.847-1.846 1.847h-8.308v8.307a1.845 1.845 0 1 1-3.692 0v-8.307H1.846a1.846 1.846 0 1 1 0-3.693h8.308V1.847a1.846 1.846 0 1 1 3.692 0v8.307h8.308C23.175 10.154 24 10.98 24 12Z"
    />
  </svg>
);
export default SvgAdd;
