import * as routes from 'api';
import { fetchJSON, postJSON } from 'api/fetch';
import { pollWorkersStatus } from 'api/workerStatus';

import { showModal } from 'actions/modals';

import { shouldRefreshTipOuts } from 'selectors/timesheets';
import { getPageData } from 'selectors/timesheets/payrollSummaries';

import {
  DOWNLOAD_TYPE_MODAL,
  PAYROLL_CODES_MODAL,
} from 'features/timesheets/constants';

import { error as flashError } from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import {
  createAsyncGetAction,
  createAsyncPutAction,
  withAlerts,
} from 'util/redux';
import { addQuery } from 'util/router';
import { showUpgradeModal } from 'util/upgrades';

export const actionTypes = {
  FETCH_PAYROLL_PERIODS_REQUEST:
    'TIMESHEETS/PAYROLL_SUMMARIES/FETCH_PAYROLL_PERIODS_REQUEST',
  FETCH_PAYROLL_PERIODS_SUCCESS:
    'TIMESHEETS/PAYROLL_SUMMARIES/FETCH_PAYROLL_PERIODS_SUCCESS',
  FETCH_PAYROLL_PERIODS_FAILURE:
    'TIMESHEETS/PAYROLL_SUMMARIES/FETCH_PAYROLL_PERIODS_FAILURE',

  FETCH_INIT_DATA_REQUEST:
    'TIMESHEETS/PAYROLL_SUMMARIES/FETCH_INIT_DATA_REQUEST',
  FETCH_INIT_DATA_SUCCESS:
    'TIMESHEETS/PAYROLL_SUMMARIES/FETCH_INIT_DATA_SUCCESS',
  FETCH_INIT_DATA_FAILURE:
    'TIMESHEETS/PAYROLL_SUMMARIES/FETCH_INIT_DATA_FAILURE',

  CLEAR_TOTALS: 'TIMESHEETS/PAYROLL_SUMMARIES/CLEAR_TOTALS',
  UPDATE_TOTALS: 'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_TOTALS',

  UPDATE_PAYROLL_ID_REQUEST:
    'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_PAYROLL_ID_REQUEST',
  UPDATE_PAYROLL_ID_SUCCESS:
    'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_PAYROLL_ID_SUCCESS',
  UPDATE_PAYROLL_ID_FAILURE:
    'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_PAYROLL_ID_FAILURE',

  UPDATE_COLUMNS: 'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_COLUMNS',
  REORDER_COLUMNS: 'TIMESHEETS/PAYROLL_SUMMARIES/REORDER_COLUMNS',
  UPDATE_LABOR_SALES_TOTALS:
    'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_LABOR_SALES_TOTALS',
  UPDATE_LOADING_TOTALS_FOR_PAYROLL_PERIODS:
    'TIMESHEETS/UPDATE_LOADING_TOTALS_FOR_PAYROLL_PERIODS',

  UPDATE_PAYROLL_CODES_REQUEST:
    'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_PAYROLL_CODES_REQUEST',
  UPDATE_PAYROLL_CODES_SUCCESS:
    'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_PAYROLL_CODES_SUCCESS',
  UPDATE_PAYROLL_CODES_FAILURE:
    'TIMESHEETS/PAYROLL_SUMMARIES/UPDATE_PAYROLL_CODES_FAILURE',
};

export const showDownloadTypeModal = (payrollProvider, payrollPeriod) =>
  showModal(DOWNLOAD_TYPE_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    payrollProvider,
    payrollPeriod,
  });

export const showPayrollCodesModal = () =>
  showModal(PAYROLL_CODES_MODAL, {
    noPadding: true,
    auto: true,
  });

export const updatePayrollCodes = payrollCodes =>
  createAsyncPutAction(
    '/location/timesheets_settings',
    [
      { type: actionTypes.UPDATE_PAYROLL_CODES_REQUEST },
      {
        type: actionTypes.UPDATE_PAYROLL_CODES_SUCCESS,
        meta: { payrollCodes },
      },
      { type: actionTypes.UPDATE_PAYROLL_CODES_FAILURE },
    ],
    { body: { timesheets_settings: { payroll_codes: payrollCodes } } }
  );

export const fetchInitData = () =>
  createAsyncGetAction(
    '/timesheets/payroll_summaries',
    [
      { type: actionTypes.FETCH_INIT_DATA_REQUEST },
      { type: actionTypes.FETCH_INIT_DATA_SUCCESS },
      { type: actionTypes.FETCH_INIT_DATA_FAILURE },
    ],
    { bailout: state => !!getPageData(state) }
  );

export const clearTotals = () => ({
  type: actionTypes.CLEAR_TOTALS,
});

const setLoadingTotalsForPayrollPeriods = loading => ({
  type: actionTypes.UPDATE_LOADING_TOTALS_FOR_PAYROLL_PERIODS,
  payload: { loading },
});

const updateTotals = payrollPeriods => ({
  type: actionTypes.UPDATE_TOTALS,
  payload: { payrollPeriods },
});

export const fetchSalesForPayrollPeriods = keys => dispatch =>
  fetchJSON(
    routes.timesheetsPayrollSummaryLaborSalesRoute({ periods: keys.join('|') })
  ).then(payload =>
    pollWorkersStatus(payload.job_ids)
      .then(({ results }) =>
        dispatch(
          updateTotals(
            results.map(r => [r.period, { summary: { sales: r.sales } }])
          )
        )
      )
      .catch(() => {
        flashError(toI18n('errors.generic'));
      })
  );

export const fetchTotalsForPayrollPeriods =
  (keys, job_id = null) =>
  (dispatch, getState) => {
    if (job_id === 'all') job_id = null;

    dispatch(setLoadingTotalsForPayrollPeriods(true));

    const getTotals = () =>
      fetchJSON(
        routes.timesheetsPayrollTotalsRoute({
          keys: keys.join('|'),
          job_id,
        })
      ).then(payload => {
        pollWorkersStatus(payload.job_ids)
          .then(() =>
            fetchJSON(
              routes.timesheetsFetchPayrollTotalsRoute({
                cache_keys: payload.cache_keys.join('|'),
              })
            ).then(({ periods }) => {
              dispatch(updateTotals(periods));
            })
          )
          .catch(() => {
            flashError(toI18n('errors.generic'));
          })
          .finally(() => {
            dispatch(setLoadingTotalsForPayrollPeriods(false));
          });
      });

    const state = getState();

    if (shouldRefreshTipOuts(state)) {
      const [start_date, end_date] = keys[0].split('-');

      postJSON(
        routes.timesheetsGenerateTipsTotalsRoute({ start_date, end_date })
      )
        .then(() => {
          getTotals();
        })
        .catch(() => {
          flashError(toI18n('errors.generic'));
        });
    } else {
      getTotals();
    }
  };

export const fetchPayrollPeriods = (newValue, oldValue) => dispatch =>
  dispatch(
    withAlerts(
      createAsyncGetAction(
        routes.timesheetsPayrollPeriodsRoute({
          start_date: newValue.startOf('month').format('MM/DD/YYYY'),
          end_date: newValue.endOf('month').format('MM/DD/YYYY'),
        }),
        [
          { type: actionTypes.FETCH_PAYROLL_PERIODS_REQUEST },
          {
            type: actionTypes.FETCH_PAYROLL_PERIODS_SUCCESS,
            meta: { month: newValue },
          },
          { type: actionTypes.FETCH_PAYROLL_PERIODS_FAILURE },
        ]
      ),
      {
        onSuccess: ({ payload }) => {
          if (payload.periods.length > 0) {
            const keys = payload.periods.map(period => period[0]);
            dispatch(fetchTotalsForPayrollPeriods(keys));
            dispatch(fetchSalesForPayrollPeriods(keys));
          } else if (payload.min_tier && oldValue) {
            showUpgradeModal(payload.min_tier, 'historical_timesheets');

            addQuery({ month: oldValue.format('MM-YYYY') });
          }
        },
      }
    )
  );

export const updatePayrollId = (payroll_id, job_id) => dispatch =>
  dispatch(
    withAlerts(
      createAsyncPutAction(
        routes.jobRoute(job_id),
        [
          { type: actionTypes.UPDATE_PAYROLL_ID_REQUEST },
          {
            type: actionTypes.UPDATE_PAYROLL_ID_SUCCESS,
            meta: { payroll_id, job_id },
          },
          { type: actionTypes.UPDATE_PAYROLL_ID_FAILURE },
        ],
        { body: { payroll_id } }
      )
    )
  );

export const updateColumns = (key, value) => ({
  type: actionTypes.UPDATE_COLUMNS,
  payload: { key, value },
});

export const reorderColumns = (oldIndex, newIndex) => ({
  type: actionTypes.REORDER_COLUMNS,
  payload: { oldIndex, newIndex },
});
