import React from 'react';
const SvgCaretLeft = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.683 13.695a2.451 2.451 0 0 1 0-3.396l9.33-9.596c.67-.69 1.67-.892 2.544-.517A2.402 2.402 0 0 1 19 2.405v19.192a2.41 2.41 0 0 1-1.443 2.219 2.288 2.288 0 0 1-2.544-.517l-9.33-9.596v-.008Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCaretLeft;
