import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { actionTypes as sessionActionTypes } from 'actions/session';

import {
  fetchAdminBillerLocations,
  fetchAdminProducts,
  fetchAdminTiers,
  fetchBillerLocations,
  tierUpdated,
} from 'features/biller/supportActions';

import { toI18n } from 'util/i18n';

import { showModal } from '../../actions/modals';
import { INVOICED_COMPANY_MODAL } from '../modals/constants';
import * as constants from '../tiers/constants';

import {
  addHiringBoost,
  updatePaymentMethod,
} from './implementations/stripe/thunks';
import { DEFAULT_WORKFLOW_SOURCE, SLICE_NAME } from './constants';
import {
  isBilledViaInvoicing,
  selectCompanyBillerName,
  selectLocationBiller,
  selectTierBasic,
  selectTierEnterprise,
  selectTierEssentials,
  selectTierPlus,
} from './selectors';
import {
  addLocations,
  adminChangeTier,
  adminPutSubscription,
  adminSubmitInitialSalesTaxTotals,
  adminUpdateTier,
  applyDiscount,
  changeTier,
  downgradeLocation,
  downgradeTier,
  newLocations,
  putSubscription,
  requestPaymentMethod,
  submitDecoupledDowngrade,
  submitDowngradeForm,
  submitDowngradeFormNew,
  submitInitialSalesTaxTotals,
  updateTier,
} from './thunks';
import { aggregateErrors, refreshPage } from './util';

export function newLocation() {
  return function newLocationsThunk(dispatch, getState) {
    if (isBilledViaInvoicing(getState())) {
      dispatch(showModal(INVOICED_COMPANY_MODAL, { deprecatedModal: true }));
    } else {
      dispatch(
        newLocations({
          biller: selectCompanyBillerName(getState()),
        })
      );
    }
  };
}

// workflowSource in the methods below is used to denote the trigger for the modal in UX tracking
export function changeToBasicTier(data) {
  return function changeToBasicTierThunk(dispatch, getState) {
    if (isBilledViaInvoicing(getState())) {
      dispatch(
        showModal(INVOICED_COMPANY_MODAL, {
          deprecatedModal: true,
          workflowSource: data?.workflowSource || DEFAULT_WORKFLOW_SOURCE,
        })
      );
    } else {
      dispatch(
        downgradeTier({
          tier: selectTierBasic(getState()),
          biller: selectLocationBiller(getState()),
        })
      );
    }
  };
}

export function changeToEssentialsTier(data) {
  return function changeToEssentialsTierThunk(dispatch, getState) {
    if (isBilledViaInvoicing(getState())) {
      dispatch(
        showModal(INVOICED_COMPANY_MODAL, {
          deprecatedModal: true,
          workflowSource: data.workflowSource || DEFAULT_WORKFLOW_SOURCE,
        })
      );
    } else {
      dispatch(
        changeTier({
          tier: selectTierEssentials(getState()),
          billingCycle: data.billingFrequency,
          biller: selectLocationBiller(getState()),
        })
      );
    }
  };
}

export function changeToPlusTier(data) {
  return function changeToPlusTierThunk(dispatch, getState) {
    if (isBilledViaInvoicing(getState())) {
      dispatch(
        showModal(INVOICED_COMPANY_MODAL, {
          deprecatedModal: true,
          workflowSource: data.workflowSource || DEFAULT_WORKFLOW_SOURCE,
        })
      );
    } else {
      dispatch(
        changeTier({
          tier: selectTierPlus(getState()),
          billingCycle: data.billingFrequency,
          biller: selectLocationBiller(getState()),
        })
      );
    }
  };
}

export function changeToAllInOneTier(data) {
  return function changeToAllInOneTierThunk(dispatch, getState) {
    if (isBilledViaInvoicing(getState())) {
      dispatch(
        showModal(INVOICED_COMPANY_MODAL, {
          deprecatedModal: true,
          workflowSource: data.workflowSource || 'tier_change',
        })
      );
    } else {
      dispatch(
        changeTier({
          tier: selectTierEnterprise(getState()),
          billingCycle: data.billingFrequency,
          biller: selectLocationBiller(getState()),
        })
      );
    }
  };
}

// This should only be used in places where you could invoke a change to any tier,
// but if you know which specific tier you want,
// you should opt for the specific instead e.g changeToPlusTier
export function changeToNewTier(data) {
  return function changeToNewTierThunk(dispatch) {
    const items = {
      billingFrequency: data.billingFrequency,
      workflowSource: data.workflowSource || DEFAULT_WORKFLOW_SOURCE,
    };

    if (data.tierName === constants.TIER_NAMES.BASIC) {
      dispatch(
        changeToBasicTier({
          workflowSource: data.workflowSource || DEFAULT_WORKFLOW_SOURCE,
        })
      );
    } else if (data.tierName === constants.TIER_NAMES.ESSENTIALS) {
      dispatch(changeToEssentialsTier(items));
    } else if (data.tierName === constants.TIER_NAMES.PLUS) {
      dispatch(changeToPlusTier(items));
    } else if (
      data.tierName === constants.TIER_NAMES.ENTERPRISE ||
      constants.TIER_NAMES.ALL_IN_ONE
    ) {
      dispatch(changeToAllInOneTier(items));
    }
  };
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState: fromJS({
    isBillingModalSourceMle: false,
    isBillingModalSourcePastDue: false,
    decidedToStayOnBasic: false,
  }),
  reducers: {
    removeDiscount: state =>
      state.merge({
        appliedDiscount: null,
        appliedDiscountError: null,
      }),
    setIsBillingModalSourceMle: (state, { payload }) =>
      state.set('isBillingModalSourceMle', payload),
    setIsBillingModalSourcePastDue: (state, { payload }) =>
      state.set('isBillingModalSourcePastDue', payload),
    setDecidedToStayOnBasic: (state, { payload }) =>
      state.set('decidedToStayOnBasic', payload),
    closePastDueDialog: state =>
      state.merge({
        shouldShowPastDueSubscriptionDialog: false,
        preSetPastDueDialogInitialViewKey: null,
      }),
    showPastDueDialog: (state, { payload }) =>
      state.merge({
        shouldShowPastDueSubscriptionDialog: true,
        preSetPastDueDialogInitialViewKey: payload.initialViewKey,
      }),
  },
  extraReducers: {
    [updateTier.pending]: state =>
      state.merge({
        updateTierPending: true,
      }),
    [updateTier.fulfilled]: state => {
      // when tier is updated, we pretty much just need to refresh
      // the page rather than sort through all the impacts
      // of the change on the front end.
      state.merge({
        updateTierPending: false,
      });
      return state;
    },
    [updateTier.rejected]: state =>
      state.merge({
        updateTierPending: false,
      }),
    [submitDecoupledDowngrade.pending]: state =>
      state.merge({
        decoupledDowngradePending: true,
      }),
    [submitDecoupledDowngrade.fulfilled]: state =>
      state.merge({
        decoupledDowngradePending: false,
      }),
    [submitDecoupledDowngrade.rejected]: state =>
      state.merge({
        decoupledDowngradePending: false,
      }),
    [downgradeLocation.pending]: state =>
      state.merge({
        downgradeLocationPending: true,
      }),
    [downgradeLocation.fulfilled]: state => {
      // when tier is updated, we pretty much just need to refresh
      // the page rather than sort through all the impacts
      // of the change on the front end.
      state.merge({
        downgradeLocationPending: false,
      });
      return state;
    },
    [downgradeLocation.rejected]: state =>
      state.merge({
        downgradeLocationPending: false,
      }),
    [tierUpdated]: state => {
      refreshPage();
      return state;
    },
    [adminUpdateTier.pending]: state =>
      state.merge({
        updateTierPending: true,
      }),
    [adminUpdateTier.fulfilled]: state => {
      // when tier is updated, we pretty much just need to refresh
      // the page rather than sort through all the impacts
      // of the change on the front end.
      window.location = window.location.pathname;
      return state;
    },
    [adminUpdateTier.rejected]: state =>
      state.merge({
        updateTierPending: false,
      }),
    [submitDowngradeForm.pending]: state =>
      state.set('downgradeToBasicStatus', 'loading'),
    [submitDowngradeForm.fulfilled]: state =>
      state.set('downgradeToBasicStatus', 'fulfilled'),
    [submitDowngradeForm.rejected]: state =>
      state.set(
        'downgradeToBasicStatus',
        aggregateErrors(toI18n('tiers.downgrade.error'))
      ),
    [submitDowngradeFormNew.pending]: state =>
      state.set('downgradeToBasicStatus', 'loading'),
    [submitDowngradeFormNew.fulfilled]: state =>
      state.set('downgradeToBasicStatus', 'fulfilled'),
    [submitDowngradeFormNew.rejected]: state =>
      state.set(
        'downgradeToBasicStatus',
        aggregateErrors(toI18n('tiers.downgrade.error'))
      ),
    [fetchBillerLocations.pending]: state =>
      state.merge({
        billerLocations: fromJS([]),
        billerLocationsError: null,
      }),
    [fetchBillerLocations.fulfilled]: (state, action) =>
      state.set('billerLocations', fromJS(action.payload)),
    [fetchBillerLocations.rejected]: (state, action) =>
      state.set('billerLocationsError', aggregateErrors(action.payload)),
    [fetchAdminBillerLocations.pending]: state =>
      state.merge({
        billerLocations: fromJS([]),
        billerLocationsError: null,
      }),
    [fetchAdminBillerLocations.fulfilled]: (state, action) =>
      state.set('billerLocations', fromJS(action.payload)),
    [fetchAdminBillerLocations.rejected]: (state, action) =>
      state.set('billerLocationsError', aggregateErrors(action.payload)),
    [fetchAdminTiers.pending]: state =>
      state.merge({
        adminTiers: fromJS([]),
        adminTiersErrors: null,
      }),
    [fetchAdminTiers.fulfilled]: (state, action) =>
      state.set('adminTiers', fromJS(action.payload)),
    [fetchAdminTiers.rejected]: (state, action) =>
      state.set('adminTiersErrors', aggregateErrors(action.payload)),
    [fetchAdminProducts.pending]: state =>
      state.merge({
        adminProductsErrors: null,
        adminProducts: { monthlyProducts: [], annualProducts: [] },
      }),
    [fetchAdminProducts.fulfilled]: (state, action) =>
      state.set('adminProducts', {
        monthlyProducts: action.payload.monthly,
        annualProducts: action.payload.annual,
      }),
    [fetchAdminProducts.rejected]: (state, action) =>
      state.set('adminProductsErrors', aggregateErrors(action.payload)),
    [changeTier.pending]: state =>
      state.merge({
        changeTierPending: true,
        appliedDiscount: null,
        applyDiscountError: null,
        appliedDiscountPending: false,
        clientTokenError: null,
        putSubscriptionErrors: null,
        putSubscriptionFailingLocations: null,
      }),
    [changeTier.fulfilled]: state => state.set('changeTierPending', false),
    [changeTier.rejected]: state => state.set('changeTierPending', false),
    [addHiringBoost.pending]: state =>
      state.merge({
        appliedDiscount: null,
        appliedDiscountError: null,
        createHiringBoostErrors: null,
        appliedDiscountPending: false,
      }),
    [updatePaymentMethod.fulfilled]: state =>
      state.merge({
        showSubscriptionPastDueWithoutBillingPermissionBanner: false,
        showSubscriptionPastDueBanner: false,
        showCreditCardExpiryBanner: false,
      }),
    [adminChangeTier.pending]: state =>
      state.merge({
        changeTierPending: true,
        appliedDiscount: null,
        appliedDiscountError: null,
        appliedDiscountPending: false,
      }),
    [adminChangeTier.fulfilled]: state => state.set('changeTierPending', false),
    [adminChangeTier.rejected]: state => state.set('changeTierPending', false),
    [applyDiscount.pending]: state =>
      state.merge({
        appliedDiscount: null,
        appliedDiscountError: null,
        appliedDiscountPending: true,
      }),
    [applyDiscount.fulfilled]: (state, action) =>
      state.merge({
        appliedDiscount: fromJS(action.payload),
        appliedDiscountError: null,
        appliedDiscountPending: false,
      }),
    [applyDiscount.rejected]: (state, action) =>
      state.merge({
        appliedDiscountError: aggregateErrors(action.payload),
        appliedDiscountPending: false,
      }),
    [putSubscription.rejected]: (state, action) =>
      state.merge({
        putSubscriptionErrors: aggregateErrors(action.payload),
        putSubscriptionFailingLocations: fromJS(
          action.payload?.failingLocations
        ),
      }),
    [adminPutSubscription.rejected]: (state, action) =>
      state.set('putSubscriptionErrors', aggregateErrors(action.payload)),
    [requestPaymentMethod.pending]: state =>
      state.set('requestPaymentMethodErrors', null),
    [requestPaymentMethod.rejected]: (state, action) =>
      state.set('requestPaymentMethodErrors', aggregateErrors(action.error)),
    [addLocations.pending]: state => state.set('addLocationsPending', true),
    [addLocations.fulfilled]: state => state.set('addLocationsPending', false),
    [addLocations.rejected]: state => state.set('addLocationsPending', false),
    [sessionActionTypes.UPDATE_SESSION]: (state, action) =>
      state.merge({
        ...action.payload.biller,
        multipleLocations: action.payload.multipleLocations,
      }),
    [sessionActionTypes.UPDATE_CURRENT_COMPANY]: (state, action) =>
      state.set(
        'companyId',
        action.payload.currentCompany && action.payload.currentCompany.id
      ),
    [sessionActionTypes.UPDATE_CURRENT_LOCATION]: (state, action) =>
      state.merge({
        locationId:
          action.payload.currentLocation && action.payload.currentLocation.id,
        locationZip:
          action.payload.currentLocation && action.payload.currentLocation.zip,
        locationBiller:
          action.payload.currentLocation &&
          action.payload.currentLocation.biller,
      }),
    [submitInitialSalesTaxTotals.pending]: state =>
      state.merge({
        salesTaxPending: true,
        salesTax: 0,
        salesTaxErrors: null,
      }),
    [submitInitialSalesTaxTotals.fulfilled]: (state, action) =>
      state.merge({
        salesTaxPending: false,
        salesTax: fromJS(action.payload.sales_tax),
      }),
    [submitInitialSalesTaxTotals.rejected]: (state, action) =>
      state.merge({
        salesTaxPending: false,
        salesTaxErrors: aggregateErrors(action.payload),
      }),
    [adminSubmitInitialSalesTaxTotals.pending]: state =>
      state.merge({
        salesTaxPending: true,
        salesTax: 0,
        salesTaxErrors: null,
      }),
    [adminSubmitInitialSalesTaxTotals.fulfilled]: (state, action) =>
      state.merge({
        salesTaxPending: false,
        salesTax: fromJS(action.payload.sales_tax),
      }),
    [adminSubmitInitialSalesTaxTotals.rejected]: (state, action) =>
      state.merge({
        salesTaxPending: false,
        salesTaxErrors: aggregateErrors(action.payload),
      }),
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
