import React from 'react';
const SvgCritical = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M4.936 2.925A3.43 3.43 0 0 1 7.838 1.27h8.282c1.203 0 2.323.627 2.946 1.655l4.431 7.283a3.442 3.442 0 0 1 0 3.583l-4.43 7.28a3.444 3.444 0 0 1-2.947 1.658H7.838a3.424 3.424 0 0 1-2.902-1.657L.503 13.79a3.442 3.442 0 0 1 0-3.583l4.433-7.283Zm5.872 4.093v5.364c0 .638.556 1.15 1.15 1.15.68 0 1.192-.512 1.192-1.15V7.018c0-.637-.512-1.15-1.192-1.15-.594 0-1.15.513-1.15 1.15Zm1.15 8.047c-.805 0-1.533.685-1.533 1.533 0 .848.728 1.533 1.533 1.533.89 0 1.576-.686 1.576-1.533 0-.848-.685-1.533-1.576-1.533Z"
    />
  </svg>
);
export default SvgCritical;
