import React from 'react';
const SvgCsv = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M13.5 0v6c0 .83.67 1.5 1.5 1.5h6V21c0 1.655-1.345 3-3 3H6c-1.657 0-3-1.345-3-3V3a3 3 0 0 1 3-3h7.5ZM6.75 10.5c-1.035 0-1.875.84-1.875 1.875v3.75c0 1.036.84 1.875 1.875 1.875h.75c1.036 0 1.875-.84 1.875-1.875v-.375a.752.752 0 0 0-.75-.75.752.752 0 0 0-.75.75v.375a.376.376 0 0 1-.375.375h-.75a.376.376 0 0 1-.375-.375v-3.75c0-.206.168-.375.375-.375h.75c.206 0 .375.169.375.375v.375c0 .412.338.75.75.75s.75-.338.75-.75v-.375c0-1.036-.84-1.875-1.875-1.875h-.75Zm4.472 4.06 1.19.68a.687.687 0 0 1 .338.585c0 .375-.3.675-.675.675h-1.2a.752.752 0 0 0-.75.75c0 .413.338.75.75.75h1.2c1.2 0 2.175-.975 2.175-2.175 0-.82-.417-1.5-1.097-1.884l-1.19-.68a.687.687 0 0 1-.338-.586c0-.375.3-.675.675-.675h.825c.412 0 .75-.338.75-.75a.752.752 0 0 0-.75-.75H12.3c-1.2 0-2.175.975-2.175 2.175 0 .778.417 1.5 1.097 1.884Zm4.903-3.31a.752.752 0 0 0-.75-.75.752.752 0 0 0-.75.75v1.481c0 1.627.483 3.22 1.387 4.575l.24.361a.753.753 0 0 0 1.246 0l.24-.36a8.242 8.242 0 0 0 1.387-4.576V11.25a.752.752 0 0 0-.75-.75.752.752 0 0 0-.75.75v1.481a6.723 6.723 0 0 1-.75 3.09 6.723 6.723 0 0 1-.75-3.09V11.25ZM15 0l6 6h-6V0Z"
    />
  </svg>
);
export default SvgCsv;
