import React, { ForwardedRef, forwardRef, useCallback } from 'react';
import TextMask, { Mask } from 'react-text-mask';
import TextInput, { TextInputProps } from 'fe-design-base/atoms/TextInput';
import transformChangeEvent from 'fe-design-base/utils/transformChangeEvent';

export interface MaskedTextInputProps extends TextInputProps {
  mask: Mask;
  guide?: boolean;
  placeholderChar?: string;
  stripChars?: (string | RegExp)[];
  className?: string;
  normalizeOnBlur?: (value: string) => string;
}

const MaskedTextInput = forwardRef(
  (
    {
      mask,
      guide,
      placeholderChar,
      onChange,
      onBlur,
      onFocus,
      name,
      value,
      stripChars,
      className,
      normalizeOnBlur,
      ...inputProps
    }: MaskedTextInputProps,
    ref: ForwardedRef<TextMask>
  ) => {
    const renderInput = useCallback(
      (inputRef, innerProps) => (
        <TextInput
          ref={inputRef}
          name={name}
          {...inputProps}
          {...innerProps}
          className={className}
        />
      ),
      [className, inputProps, name]
    );

    const handleChange = useCallback(
      e => {
        const maskedValue = e.target?.value as string;

        if (maskedValue && stripChars) {
          const strippedValue = stripChars.reduce(
            (memo: string, sc) => memo.replace(sc, ''),
            maskedValue
          ) as string;
          onChange?.(transformChangeEvent(e, name, strippedValue));
          return;
        }
        onChange?.(e);
      },
      [name, onChange, stripChars]
    );

    const handleBlur = useCallback(
      e => {
        const maskedValue = e.target?.value as string;

        if (normalizeOnBlur && maskedValue) {
          const strippedValue: string = stripChars
            ? stripChars.reduce(
                (memo: string, sc) => memo.replace(sc, ''),
                maskedValue
              )
            : maskedValue;

          onChange?.(
            transformChangeEvent(e, name, normalizeOnBlur(strippedValue))
          );
        }
        onBlur?.(e);
      },
      [normalizeOnBlur, onBlur, stripChars, onChange, name]
    );

    return (
      <TextMask
        ref={ref}
        mask={mask}
        guide={guide}
        placeholderChar={placeholderChar}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        value={value}
        render={renderInput}
      />
    );
  }
);

export default MaskedTextInput;
